import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  direction: "rtl",
  fontFamily: ["Assistant"].join(","),
  fontSize: {
    // p: '1.2rem',
    // h1: '3rem',
    // h2: '2.5rem',
    // h3: '2rem',
    // h4: '1.5rem',
    p: '1rem',
    h1: { xs: '2.5rem', md: '3rem' },
    h2: '2rem',
    h3: '1.6rem',
    h4: '1.3rem',
  },
  palette: {
    green: "#4297A0",
    ivory: "#F4EAE6",
    coral: "#D96B0C",
    white: "#FBFBFB",
    turquoise: "#14325C",
    darkBlue: "#A53A3B",
    lightBlue: "#5398D9",
    black: "#151515"
  },
});
