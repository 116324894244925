import React from 'react';

require('../css/styles.css');

const Registered = () => {
    return (
        <div>
             <center>
                <h1>רכישה מוצלחת!</h1>
                <h2>חשבונית נשלחה לאימייל, נא לבדוק את תיבת הספאם</h2>
                <h2>לימודים מהנים והצלחה רבה!</h2>
            </center>
        </div>                     
    );
}

export default Registered;