import React, { useEffect, useState } from "react";
import { MainContainer } from "../Containers";
import { Grid } from "@mui/material";

import { SubHeader } from '../../utils/subHeader';
import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { SignUp } from './SignUp';
import { EmailCheck } from './EmailCheck';

import { useHistory } from "react-router-dom";

import store from '../../redux/store';

const AuthPage = () => {
    const pathname = window.location.pathname;
    const history = useHistory();

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);

    useEffect(() => {
        isLoggedIn && history.goBack();;

        const unsubscribe = store.subscribe(() => {
            setIsLoggedIn(store.getState().isLoggedIn)
        });

        return () => {
            unsubscribe();
        }
    }, []);

    useEffect(() => {
        setTitleAndBody();
    }, [pathname])

    const setTitleAndBody = () => {
        let title = '';
        let body = '';

        if (pathname === '/login') {
            title = 'התחבר'
        } else if (pathname === '/signup') {
            title = 'הרשמה';
            body = 'הירשמו לאתר ללא כל תשלום ותוכלו להינות מדפי נוסחאות, מאמרים וסרטונים'
        } else if (pathname === '/forgotPassword') {
            title = 'שכחתי סיסמה';
        } else if (pathname === '/emailCheck') {
            title = 'אימות הרשמה';
            body = 'נשלח אליך מייל עם סיסמה זמנית. נא לבדוק את תיבת הספאם במידת הצורך';
        } else if (pathname === '/emailSent') {
            title = 'אימות הרשמה';
            body = 'נשלח אליך מייל לאימות. נא לבדוק את תיבת הספאם במידת הצורך';
        } else if (pathname === '/success') {
            title = 'אימות משתמש בוצע בהצלחה';
            body = 'אני מקווה שתהיה לך חוויה נעימה ומלמדת בקורס!';
        }

        setTitle(title);
        setBody(body);
    }

    return (
        <MainContainer>
            <SubHeader title={title} body={body} />
            <Grid container sx={{ margin: '0px auto', direction: 'rtl', height: pathname == '/login' ? '60vh' : '' }} justifyContent='center'>
                <Grid item xs={10} md={8}>
                    {pathname === '/login' &&
                        <Login returnToPrev={isLoggedIn} />
                    }
                    {pathname === '/signup' &&
                        <SignUp />
                    }
                    {pathname === '/forgotPassword' &&
                        <ForgotPassword />
                    }
                    {pathname === '/emailCheck' &&
                        <EmailCheck />
                    }
                    {pathname === '/emailSent' &&
                        <div></div>
                    }
                    {pathname === '/success' &&
                        <div></div>
                    }
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default AuthPage;