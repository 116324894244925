import * as React from "react";
import { MainContainer } from "../Containers";
import { Grid, Typography, useTheme, Link } from "@mui/material";

import { Title } from "../../utils/Title";
import { SubHeader } from '../../utils/subHeader';

import { ContactForm } from './ContactForm';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const Contact = () => {
    const title = `מוזמנים ליצור קשר ואחזור אליכם בהקדם`;
    const theme = useTheme();

    return (
        <MainContainer>
            <SubHeader title={title} />
            <Grid container spacing={{ md: 2 }} justifyContent='center'>
                <Grid item xs={12} md={4} sx={{ padding: '0px 50px', marginTop: '20px' }}>
                    <Title color={theme.palette.coral}>השאירו הודעה</Title>
                    <ContactForm returnToPrev={false} />
                </Grid>
                <Grid item xs={12} md={4} sx={{ padding: '0px 50px', marginTop: '20px' }}>
                    <Title color={theme.palette.coral}>יצירת קשר</Title>
                    <Typography sx={{ fontSize: '18px', fontWeight: theme.typography.fontWeightBold }}>
                        עמית שלמה
                    </Typography>
                    <Link href="tel:+972556811477" sx={{ fontSize: '18px', color: 'black', textDecoration: 'none', fontWeight: 400 }}>
                        <PhoneAndroidIcon fontSize="sm" sx={{ marginLeft: '5px' }} />
                        055-681-1477
                    </Link><br />
                    <Link href="mailto:info@amitphysics.com" sx={{ fontSize: '18px', color: 'black', textDecoration: 'none', fontWeight: 400 }}>
                        <EmailIcon fontSize="sm" sx={{ marginLeft: '5px' }} />
                        info@amitphysics.com
                    </Link><br />
                    <Typography sx={{ fontSize: '18px' }}>
                        <LocationOnIcon fontSize="sm" sx={{ marginLeft: '5px' }} />
                        מרכז חורב, חיפה
                    </Typography>
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default Contact;