import React, { Fragment, useState, useEffect } from "react";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Button, Tooltip, Container, Accordion, AccordionDetails, AccordionSummary, Link, Popper, Fade } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from '@mui/styles';
import { useTheme } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";
import { ScrollTo } from "../../utils/ScrollTo";
import { BASE_URL } from "../../api";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { AlertMessage } from "../../utils/AlertMessage";
import logo from '../../images/amitPhysics_logo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Auth from '../../hooks/Auth';
import store from '../../redux/store';

const pages = [{ title: 'אודות', url: 'About' }, { title: 'חומרים', url: 'Material' }, { title: 'שיעורים פרטיים', url: 'Lesson' }, { title: 'צור קשר', url: 'Contact' }]
const categories = ["בגרות", "אקדמיה", "פק״ל"];

const settings = ["התנתק"];

const useStyles = makeStyles({
  button: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
      fontWeight: 700
    },
  }
})

export const Header = () => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");

  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const theme = useTheme();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElCategory, setAnchorElCategory] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [labelExpanded, setLabelExpanded] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);
  const [isAuthForCourse, setIsAuthForCourse] = useState(store.getState().isAuthForCourse);
  const [courseId, setCourseId] = useState(store.getState().courseId);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);

  const pathname = window.location.pathname;

  //*****
  // in order to check if there is another session open from other device



  // const [intervalStatus, setIntervalStatus] = useState(true);
  // const checkUserSession = () => {
  //   axios
  //     .post(`${BASE_URL}/users/session`).then((res) => {
  //       if (res.data.error) {
  //         if (res.data.message) {
  //           setAlertMessage(res.data.message);
  //           setAlertStatus('error');
  //           setAlertIsOpen(true);
  //         }

  //         if (res.data.action && res.data.action == 'do logout') {
  //           Auth.deleteTokenFromAxiosHeaders();
  //           history.push('/');
  //         }
  //       }
  //     }).catch((err) => {
  //       if(err.message == 'Request failed with status code 401'){
  //         setIntervalStatus(true);
  //         clearInterval(sessionInterval);
  //       }
  //     })
  // }

  // let sessionInterval;

  // useEffect(() => {
  //   let minutes = 1;
  //   let interval = minutes * 60 * 1000;

  //   const token = Auth.getStoredToken();
  //   if (intervalStatus) {
  //     if (token) {
  //       sessionInterval = setInterval(function () {
  //         if (intervalStatus) {
  //           setIntervalStatus(false);
  //           checkUserSession();
  //         }
  //       }, interval);
  //     } else {
  //       setIntervalStatus(true);
  //       clearInterval(sessionInterval);
  //     }
  //   }
  // }, [intervalStatus])


  //*****

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname == '/')
      localStorage.removeItem('period');

  }, [pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserDetails();
    }
  }, [isLoggedIn])

  useEffect(() => {
    const token = Auth.getStoredToken();

    if (token) {
      Auth.setTokenToAxiosHeaders(token);

      getUserDetails();
    }

    if (pathname == '/course') {
      getCourse();
    }

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
      setUserDetails(store.getState().userDetails);
      setIsAuthForCourse(store.getState().isAuthForCourse);
      setCourseId(store.getState().courseId);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  const handleClick = (event) => {
    if (!event) { setAnchorEl(null); return; }
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getUserDetails = () => {
    axios
      .post(`${BASE_URL}/users/profile`).then((res) => {
        if (res.data.error) {
          if (res.data.message) {
            setAlertMessage(res.data.message);
            setAlertStatus('error');
            setAlertIsOpen(true);
          }

          if (res.data.message == 'אירעה שגיאה. מתנתק...')
            setTimeout(() => { Auth.deleteTokenFromAxiosHeaders(); history.push('/') }, 2000);
          return;
        }

        let data = {
          id: res.data._id,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.email,
          username: res.data.username,
          auth: res.data.auth,
          active: res.data.active,
          telephone: res.data.telephone
        }

        setUserDetails(data);

        store.dispatch({ type: 'updateUserDetails', payload: data });
        store.dispatch({ type: 'updateIsLoggedIn', payload: true });
      }).catch((err) => {
        if (err.message == 'Request failed with status code 401')
          Auth.deleteTokenFromAxiosHeaders(); history.push('/');
      })
  };

  const handleOpenNavMenu = (event) => {
    event.preventDefault();
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    event.preventDefault();

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setExpanded(false);
  };
  const handleCloseCategoryMenu = () => {
    setAnchorElCategory(null);
  };

  const handleCloseUserMenu = (setting) => {
    if (setting == 'התנתק') {
      axios.post(`${BASE_URL}/users/logout`, userDetails).then(function (res) {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        if (res.data.success) {
          Auth.deleteTokenFromAxiosHeaders();

          setAnchorElUser(null);

          history.push(res.data.redirectUrl);
        }
      })
    }
    setAnchorElUser(null);
  };

  const handleOpenCategory = (event) => {
    event.preventDefault();
    setAnchorElCategory(event.currentTarget);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleCategoryExpand = (event, label) => {
    event.preventDefault();
    if (label.length == 0) {
      setAnchorElCategory(null);
    } else {
      setAnchorElCategory(event.currentTarget);
    }
    setLabelExpanded(label);
  }

  const renderContent = () => {
    switch (isLoggedIn) {
      case false:
        return <Button onClick={() => history.push('/login')} sx={{ m: 1, color: theme.palette.black, fontSize: theme.fontSize.p }}>
          התחברות
        </Button>
        break;
      case true:
        return (
          <Tooltip title="פרופיל">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, m: 1 }}>
              <Avatar alt={userDetails ? userDetails.firstName : ''} src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
        );
    }
  }

  const getCourse = async () => {
    await axios.post(`${BASE_URL}/lessons/courseIds`, { number: store.getState().courseId }).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      if (store.getState().isLoggedIn && store.getState().userDetails.auth && findEveryElements(res.data[0].ID, store.getState().userDetails.auth)) {
        store.dispatch({ type: 'updateIsAuthForCourse', payload: true });
        setIsAuthForCourse(true);
      }
    })
  }

  const renderPurchaseBtn = () => {
    switch (isAuthForCourse) {
      case false:
        if (courseId != 104 && (courseId <= 501 || courseId >= 504)) {
          return <ButtonComponent
            spColor={theme.palette.coral}
            rounded={"true"}
            text={"לרכישה"}
            onClick={() => { history.push("/purchase?courseid=" + courseId) }}
          />
        }
        break;
    }
  }

  function findEveryElements(arr1, arr2) {
    return arr1.every((item) => arr2.includes(item));
  }

  const classes = useStyles();

  return (
    <Fragment>
      <AppBar sx={{ backgroundColor: theme.palette.white }}>
        <Container maxWidth="xl" disableGutters={true}>
          <Toolbar>
            <Box sx={{ mr: 2, display: { xs: "none" } }} onClick={() => history.push('/')}>
              <img src={logo} alt="Amit Physics logo" width='150px' />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                sx={{ color: theme.palette.coral }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}>
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}>
                {pages.map((page, i) => (
                  <MenuItem key={i} onClick={() => { history.push(page.url) }}>
                    <Typography textAlign="center" sx={{ fontSize: theme.fontSize.p }}>{page.title}</Typography>
                  </MenuItem>
                ))}
                {/* <MenuItem> */}
                <Accordion
                  expanded={expanded === true}
                  onChange={handleExpand}
                  sx={{ boxShadow: "unset" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    id="fade-button"
                    aria-controls={Boolean(anchorElCategory) ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorElCategory) ? 'true' : undefined}
                    onClick={handleOpenCategory}>
                    <Typography sx={{ width: "33%", flexShrink: 0, fontSize: theme.fontSize.p }}>נושאי לימוד</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: 0 }}>
                    {categories.map((category, i) => (
                      <ScrollTo id={category} key={i} handleClose={handleCloseNavMenu}>
                        <Button
                          key={i}
                          sx={{ color: "black", display: "block", fontSize: theme.fontSize.p }}>
                          {category}
                        </Button>
                      </ScrollTo>
                    ))}
                  </AccordionDetails>
                </Accordion>
                {/* </MenuItem> */}

              </Menu>
            </Box>
            <Box style={{ flexGrow: 1, display: { md: "none" }, cursor: 'pointer' }} onClick={() => history.push('/')}>
              <img src={logo} alt="Amit Physics logo" width='100px' />
            </Box>
            <Box sx={{ flexGrow: 10, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, i) => (
                <Button
                  key={i}
                  onClick={() => { history.push(page.url) }}
                  sx={{ m: 1, color: "black", display: "block", textAlign: 'center', color: theme.palette.black, fontSize: theme.fontSize.p }}>
                  {page.title}
                </Button>
              ))}


              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  onClick={handleClick}
                  sx={{ color: "black", fontSize: theme.fontSize.p }}
                  endIcon={<ExpandMoreIcon />}
                >נושאי לימוד
                </Button>

                <Popper id={id} open={open} anchorEl={anchorEl} transition>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <div>
                        {categories.map((category, i) => (
                          <ScrollTo id={category} key={i} handleClose={handleClick}>
                            <Button className={classes.button}
                              sx={{ color: "black", background: 'white', minWidth: '100px', display: "block", fontSize: theme.fontSize.p }}>
                              {category}
                            </Button>
                          </ScrollTo>
                        ))}
                      </div>
                    </Fade>
                  )}
                </Popper>
              </Box>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {pathname == '/course' &&
                renderPurchaseBtn()
              }
              {renderContent()}
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map((setting, i) => (
                  <MenuItem key={i} onClick={() => handleCloseUserMenu(setting)}>
                    <Typography textAlign="center" sx={{ fontSize: theme.fontSize.p }}>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />

      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </Fragment>
  );
};