import React, { useState, useEffect } from "react";
import { CommentCard } from "./CommentCard";
import { CommentInput } from "./CommentInput";
import { Box } from "@mui/material";
import store from '../../redux/store';

export const Comment = ({
  label,
  text,
  replies,
  replyLabel,
  setreplyLabel,
  lessonId,
  commentId,
  userName,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);
  const user = null;

  useEffect(() => {
    if (!userDetails.auth) {
      setUserDetails(store.getState().userDetails);
    }

  }, [store.getState().userDetails])

  return (
    <div>
      <CommentCard
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        label={label}
        text={text}
        replies={replies}
        userName={userName}
      />
      {isVisible &&
        replies.map((reply, key) => {
          if (key === replies.length - 1) {
            return (
              <Box sx={{ margin: "0 1rem 0 0" }} key={key}>
                <CommentCard
                  isreply
                  isVisible={isVisible}
                  text={reply.text}
                  label={reply.label}
                  userName={reply.fullName}
                />

                <CommentInput
                  label={replyLabel}
                  setLabel={setreplyLabel}
                  isreply={true}
                  commentId={commentId}
                  lessonId={lessonId}
                  auth={userDetails.auth}
                />
              </Box>
            );
          }

          return (
            <Box sx={{ margin: "0 1rem 0 0" }}>
              <CommentCard
                isreply
                key={key}
                isVisible={isVisible}
                text={reply.text}
                label={reply.label}
                userName={reply.fullName}
              />
            </Box>
          );
        })}
      {replies.length === 0 && isVisible && (
        <Box sx={{ margin: "0 1rem 0 0" }}>
          <CommentInput
            label={replyLabel}
            setLabel={setreplyLabel}
            isreply={true}
            commentId={commentId}
            lessonId={lessonId}
            auth={userDetails.auth}
          />
        </Box>
      )}
    </div>
  );
};
