import React, { useEffect, useState } from "react";
import { MainContainer } from "../Containers";
import { Typography, styled, Skeleton, Box, Tabs, Tab, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { BASE_URL } from "../../api";
import { useHistory } from "react-router-dom";
import Vplayer from "../Vplayer";
import { Comments } from "../Comments";
import { Chapters } from "./Chapters";
import useModal from "../../hooks/useModal";
import { ModalComponent } from "../../utils/ModalComponent";
import { ButtonComponent } from "../../utils/ButtonComponent";
import axios from "axios";
import { useWindowSize } from "../../hooks/useWindowSize";
import { TabPanel } from "../../utils/TabPanel";
import { CourseCard } from "../../utils/Card";
import { AlertMessage } from "../../utils/AlertMessage";
import { SubHeader } from '../../utils/subHeader';

import Routing from '../../hooks/Routing';
import Auth from '../../hooks/Auth';
import store from '../../redux/store';

export const Course = () => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const [lesson, setLesson] = useState({});
  const [otp, setOtp] = useState("");
  const [play, setPlay] = useState("");
  const [file, setFile] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);
  const [isAuthForCourse, setIsAuthForCourse] = useState(store.getState().isAuthForCourse);

  const [width, high] = useWindowSize();
  const [tabValue, setTabValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [chapters, setChapters] = useState([]);
  const [course, setCourse] = useState([]);
  const [currLessonId, setCurrLessonId] = useState(null);
  const [lessonIDS, setLessonIDS] = useState([]);

  const [price, setPrice] = useState(store.getState().price);

  const [mechanics, setMechanics] = useState([]);

  const { toggle, visible } = useModal();
  const history = useHistory();

  const title = course.courseTitle ? `ברוכים הבאים לקורס ${course.courseTitle}` : `ברוכים הבאים`;
  const body = `כל מה שצריך כדי לעבור את הקורס בהצלחה נמצא בעמוד זה.\nניתן לצפות בשיעורים הראשונים של הקורס\nבהרשמה חינמית ניתן אף להוריד דף נוסחאות, ולצפות בסרטונים נוספים.`

  let query = Routing.useQuery();
  let courseID = parseInt(query.get("courseid"));

  const theme = useTheme();

  useEffect(() => {
    const token = Auth.getStoredToken();

    if (token)
      Auth.setTokenToAxiosHeaders(token);

    store.dispatch({ type: 'setCourseId', payload: courseID });

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
      setUserDetails(store.getState().userDetails);
      setIsAuthForCourse(store.getState().isAuthForCourse);
      setPrice(store.getState().price);
    });

    console.log('isAuthForCourse', store.getState().isAuthForCourse);

    getCourseData();
    return () => {
      store.dispatch({ type: 'updateIsAuthForCourse', payload: false });
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (!store.getState().isLoggedIn && courseID >= 500) {
      history.goBack();
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (width) {
      if (width > 900) {
        setTabValue(1);
      } else {
        setTabValue(0);
      }
    }
  }, [width]);

  const getCourseData = () => {
    axios
      .post(`${BASE_URL}/lessons/chapters`, { courseID: courseID })
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        setChapters(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${BASE_URL}/courses/getCourse/${courseID}`)
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        setCourse(res.data[0]);

        if (courseID === 104) {
          setMechanics([{ courseTitle: 'חודש', bullets: ['צפייה חופשית בכלל הקורס'], price: res.data[0].price[0] }, { courseTitle: '6 חודשים', bullets: ['צפייה חופשית בכלל הקורס'], price: res.data[0].price[1] }, { courseTitle: 'שנה', bullets: ['צפייה חופשית בכלל הקורס'], price: res.data[0].price[2] }])
          return;
        }

        if (res.data[0].price)
          store.dispatch({ type: 'setPrice', payload: res.data[0].price[0] });
      })
      .catch((err) => {
        console.log(err);
      });

    axios.post(`${BASE_URL}/lessons/courseIds`, { number: courseID }).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      setLessonIDS(res.data[0].ID);

      if (store.getState().isLoggedIn && store.getState().userDetails.auth && findEveryElements(res.data[0].ID, store.getState().userDetails.auth)) {
        store.dispatch({ type: 'updateIsAuthForCourse', payload: true });
        setIsAuthForCourse(true);
      }
    })
      .catch((err) => {
        console.log(err);
      });
  }

  function findEveryElements(arr1, arr2) {
    return arr1.every((item) => arr2.includes(item));
  }

  const handleFileClick = (blobName, container) => {
    axios
      .post(
        `${BASE_URL}/files/azureFile`,
        { containerName: container, blobName: blobName, type: "course", username: store.getState().userDetails.username },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch(() => {
        setAlertMessage("יש להתחבר על מנת לצפות בקובץ");
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      });
  };

  const handleVideo = (lesson) => {
    setLesson(lesson.lesson[0]);
    setCurrLessonId(lesson._id);
    setFile("");
    setOtp("");
    setPlay("");
    axios
      .post(
        `${BASE_URL}/video`,
        {
          file: lesson.lesson[0].file,
          courseID: courseID,
          lessonId: lesson._id,
          userInfo: userDetails,
        },
      )
      .then(function (res) {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        setFile(lesson.lesson[0].file);
        setOtp(res.data.otp);
        setPlay(res.data.play);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setErrorMessage(error.response.data);
          toggle();
        }
      });
  };

  const handleTabs = (event, newValue) => {
    setTabValue(newValue);
  };
  const tabs = ["פורום", "אודות השיעור", "קבצים", "קישורים", "זום"];

  return (
    <div>
      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />

      {tabValue !== null ? (
        <MainContainer>
          <ContainerBox>
            <Box sx={{ width: "100%" }}>

              {/* <Box sx={{ width: "100%" }}>
                {otp && play ? (
                  <Vplayer otp={otp} play={play}></Vplayer>
                ) : (
                  <Box sx={{ height: "639px" }}>
                    <SubHeader title={title} body={body} />
                  </Box>
                )}
              </Box> */}

              <Box sx={{ width: "100%" }}>
                {otp && play ? (
                  <Vplayer otp={otp} play={play}></Vplayer>
                ) : (
                  <Box sx={{ height: (courseID === 104 && !store.getState().isAuthForCourse) ? "" : "639px" }}>
                    <SubHeader title={title} body={body} />
                    {/* <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={"639px"}
                      sx={{
                        height: "639px",
                        [theme.breakpoints.down("md")]: {
                          height: "210px",
                        },
                      }}
                    /> */}
                  </Box>
                )}
              </Box>

              {(courseID === 104 && !store.getState().isAuthForCourse) &&
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    justifyContent="center"
                    columns={{ xs: 12, md: 12 }}
                    spacing={{ xs: 2, md: 4 }}>
                    {mechanics.map((item, i) => (
                      <Grid item key={i} xs={10} md={3}>
                        <CourseCard
                          id={'104'}
                          courseTitle={item.courseTitle}
                          popUpMainTitle='צור קשר'
                          popUpSubTitle={'בחרת בקורס מסוג'}
                          isShowForm={true}
                          key={i}
                          color={theme.palette.turquoise}
                          bullets={item.bullets}
                          price={item.price}
                          period={item.courseTitle}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }

              <Box className="mt-5">
                <Tabs
                  variant="scrollable"
                  allowScrollButtonsMobile={true}
                  value={tabValue}
                  onChange={handleTabs}>
                  <Tab
                    sx={{
                      [theme.breakpoints.up("md")]: {
                        display: "none",
                      },
                      fontFamily: theme.fontFamily,
                    }}
                    label="שיעורים"></Tab>
                  {tabs.map((tab, i) => (
                    <Tab
                      label={tab}
                      key={i}
                      sx={{
                        fontFamily: theme.fontFamily,
                      }}
                    />
                  ))}
                </Tabs>

                <Box
                  hidden={tabValue !== 0}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      display: "block",
                    },
                    display: "none",
                  }}
                  value={tabValue}
                  index={0}>
                  <Chapters
                    setChapters={setChapters}
                    chapters={chapters}
                    handleVideo={handleVideo}
                  />
                </Box>

                <TabPanel padding={false} value={tabValue} index={1}>
                  {course.isResponse &&
                    <Comments lessonId={currLessonId} userId={userDetails.id} />
                  }
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  {(!lesson.overview && course.overview) &&
                    <Typography sx={{
                      fontWeight: 400,
                      fontSize: "18px",
                      fontFamily: theme.fontFamily,
                      textAlign: 'right',
                      color: theme.palette.black,
                      marginTop: '10px',
                      marginRight: '20px'
                    }}>
                      {course.overview}
                    </Typography>
                  }

                  {lesson.overview &&
                    <Typography sx={{
                      fontWeight: 400,
                      fontSize: "18px",
                      fontFamily: theme.fontFamily,
                      textAlign: 'right',
                      color: theme.palette.black,
                      marginTop: '10px',
                      marginRight: '20px'
                    }}>
                      {lesson.overview}
                    </Typography>
                  }
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                  {course.resources &&
                    <Box sx={{ padding: '20px', justifyContent: { md: 'center' }, display: 'flex', flexWrap: { xs: 'wrap' } }}>
                      {course.resources.map((resource, i) => {
                        return (
                          <Box sx={{ margin: { md: '0px 15px', xs: '5px auto' } }} key={`file_${i}`}>
                            <ButtonComponent key={i}
                              onClick={() => handleFileClick(resource.blob, resource.container)}
                              text={resource.blob}
                              spColor={theme.palette.turquoise}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  }
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  {course.links &&
                    <Box sx={{ padding: '20px', justifyContent: 'center', display: 'flex', flexWrap: { xs: 'wrap' } }}>
                      {course.links.map((link, i) => {
                        return (
                          <Box sx={{ margin: { md: '0px 15px', xs: '5px auto' } }} key={`name_${i}`}>
                            <ButtonComponent key={i}
                              onClick={() => window.open(link.value)}
                              text={link.name}
                              spColor={theme.palette.turquoise}
                            />
                          </Box>
                        )
                      })}
                    </Box>
                  }
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  {course.zoom &&
                    <Box sx={{ padding: '20px', justifyContent: 'center', display: 'flex', flexWrap: { xs: 'wrap' } }}>
                      {course.zoom.link &&
                        <ButtonComponent
                          onClick={() => window.open(course.zoom.link)}
                          text="לינק"
                          spColor={theme.palette.turquoise}
                        />
                      }

                      {course.zoom.password &&
                        <Typography sx={{
                          fontWeight: 400,
                          fontSize: "18px",
                          fontFamily: theme.fontFamily,
                          textAlign: 'center',
                          color: theme.palette.turquoise,
                          marginRight: '20px'
                        }}>
                          סיסמה: {course.zoom.password}
                        </Typography>
                      }
                    </Box>
                  }
                </TabPanel>
              </Box>
            </Box>
            <Box
              sx={{
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}>
              <Chapters
                chapters={chapters}
                setChapters={setChapters}
                handleVideo={handleVideo}
              />
            </Box>

          </ContainerBox>

          <ModalComponent toggle={toggle} visible={visible} title={"השיעור נעול"}>
            <Box>
              {errorMessage.message}<br /><br />
              <ButtonComponent
                onClick={() => errorMessage.buttonText == 'התחבר' ? history.push("/login") : history.push("/purchase?courseid=" + courseID)}
                text={errorMessage.buttonText}
                spColor={theme.palette.green}
                rounded={"true"}
              />
            </Box>
          </ModalComponent>
        </MainContainer>
      ) : (
        <div>loading</div>
      )
      }
    </div >
  );
};
const ContainerBox = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
