import React, { Component } from "react";
import ReactGA from "react-ga";
var ReactDOM = require("react-dom");
// import '../assets/avo.js'

class Vplayer extends Component {
  constructor(props) {
    super(props);
    // this.state = { video: "" };
    this.state = React.createRef();
    // console.log(111, this.state.video)
    // let id = params.id
  }

  async componentDidMount() {
    // console.log(' this.props.otp',  this.props.otp);
    // console.log(' this.props.play',  this.props.play);

    // ReactGA.event({
    //   category: "Video",
    //   action: "watching video",
    //   label: this.props.file,
    // });

    const video = await new window.VdoPlayer({
      otp: this.props.otp,
      playbackInfo: this.props.play,
      theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
      // the container can be any DOM element on website
      container: document.querySelector("#embedBox"),
    });
    // this.setState({ video, video });
    // console.log("ELAD - A", video)
    // console.log("ELAD - B", this.state.video)
  }

  render() {
    // console.log(this.props.play);
    // console.log(this.props.otp);
    // console.log(window.VdoPlayer);
    return (
      <div>
        <div id="embedBox" ref={this.state}></div>
      </div>
    );
  }
}

export default Vplayer;
