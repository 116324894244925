import React from "react";
import { Box } from "@mui/material";
import store from '../redux/store';
import { useHistory } from "react-router-dom";

export const ScrollTo = ({ children, id, handleClose }) => {
  const history = useHistory();

  const handleClick = (event) => {
    let anchor;

    if (window.location.pathname.length == 1) {
      anchor = (event.target.ownerDocument || document).querySelector(`#${id}`);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      let scrollTimeout;
      document.addEventListener('scroll', function () {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function () {
          handleClose();
        }, 100);
      });
    } else {
      store.dispatch({ type: 'setAnchor', payload: id });
      history.push('/');
    }
  };
  return (
    <Box onClick={(id, e) => handleClick(id, e)} role="presentation">
      {children}
    </Box>
  );
};