import React, { useEffect, useState } from "react";
import { Comment } from "./Comment";
import axios from "axios";
import { BASE_URL } from "../../api";
import { CommentInput } from "./CommentInput";
import Auth from '../../hooks/Auth';
import store from '../../redux/store';
import getCommentsAndReplies from '../../utils/Forum';

export const Comments = ({ lessonId }) => {
  const [label, setLabel] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [comments, setComments] = useState(store.getState().comments);
  const [replyLabel, setreplyLabel] = useState("");
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);

  useEffect(() => {
    getCommentsAndReplies(lessonId);
    setComments(store.getState().comments);
  }, [lessonId]);

  useEffect(() => {
    if (!userDetails.auth) {
      setUserDetails(store.getState().userDetails);
    }

  }, [store.getState().userDetails])

  useEffect(() => {
    const token = Auth.getStoredToken();

    if (token) {
      Auth.setTokenToAxiosHeaders(token);
    }
    const unsubscribe = store.subscribe(() => {
      setUserDetails(store.getState().userDetails);
      setComments(store.getState().comments);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  return (
    <div>
      {comments?.map((comment, key) => {
        return (
          <div key={key}>
            {!comment.parentCommentId && (
              <Comment
                key={key}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                text={comment.text}
                label={comment.label}
                replies={comment.replys}
                replyLabel={replyLabel}
                setreplyLabel={setreplyLabel}
                lessonId={lessonId}
                commentId={comment._id}
                userName={comment.fullName}
              />
            )}
          </div>
        );
      })}

      {lessonId &&
        <CommentInput
          label={label}
          setLabel={setLabel}
          lessonId={lessonId}
          isreply={false}
          auth={userDetails.auth}
        />
      }
    </div>
  );
};
