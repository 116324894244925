import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Payment from "./Payment";
import Sheet from "./Sheet";
import Learning from "./Learning";
import Syllabus from "./Syllabus";
import Media from "react-media";
import ReactGA from "react-ga";

import ScrollToTop from "./ScrollToTop";

import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Vplayer from "./Vplayer";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Title from "./Title";

require("../css/styles.css");

const Mechanics = (props) => {
  const [otp, setOtp] = useState(null);
  const [play, setPlay] = useState(null);
  const [page, setPage] = useState("");
  const [subpage, setSubpage] = useState("openning");
  const [lessons, setLessons] = useState();
  const [title, setTitle] = useState();
  const [chapters, setChapters] = useState();
  const [subtitle1, setSubtitle1] = useState();
  const [subtitle2, setSubtitle2] = useState();
  const [file, setFile] = useState("");

  let count = 0;
  let a = count;

  // retrieve params into a variable
  const params = useParams();

  let id = params.id;
  // console.log("id", id);

  useEffect(() => {
    let isMounted = true;
    // axios.post('./../api/mechanics', {mechanics, id}).then(function (res){
    axios.post("http://localhost:5000/api/lessons/chapters", { courseID: 1 }).then((res) => {
      if (isMounted) {
        console.log(res.data[0]._id.chapterTitle);
        // console.log(res.data.mechanicuniversities);
        // setLessons(res.data.mechanicuniversities);
        setTitle(res.data.title);
        setChapters(res.data.map((chapter) => chapter._id.chapterTitle));
        setSubtitle1(res.data.subtitle1);
        setSubtitle2(res.data.subtitle2);
        setPage("course");
      }
      return () => {
        isMounted = false;
      };
    });
  }, [id]);

  function renderContent() {
    switch (props.auth) {
      case null:
        // in this case the server is still deciding wether or not i'm logged in

        return;
      case false:
        // i'm logged out
        return (
          // <li><a href="/login">login</a></li>
          <div className="openning">
            <h4>מחיר כלל הקורס למשך חצי שנה:</h4>
            <h2> 200 ש"ח בלבד</h2>
            <h4>קורס שלם של עשרות שעות במחיר של כשעה אחת של שיעור פרטי!</h4>
            <br></br>
            <Link to="/login">
              <button className="butn borders">הרשמה</button>
            </Link>
            <br></br>
            <br></br>
          </div>
        );
      default:
        //    console.log(props.authName);
        if (props.auth.auth.indexOf(params.id) > -1) {
          return null;
        } else {
          return (
            <div className="openning">
              <h4>רכשו את הקורס במחיר משתלם של</h4>
              <h2>200 ש"ח בלבד</h2>
              <h4>קורס שלם של עשרות שעות במחיר של כשעה אחת של שיעור פרטי!</h4>
              <br></br>

              <button className="butn borders" onClick={() => setPage("payment")}>
                לרכישת הקורס
              </button>

              <br></br>
              <br></br>
            </div>
          );
        }
      // i'm logged in
    }
  }

  function decide(file) {
    ReactGA.event({
      category: "Clicked on video",
      action: params.id,
      label: file,
    });
    setFile(file);

    setSubpage("wait");
    let send = [file, params.id];

    axios.post("./../api/video", { send }).then(function (res) {
      if (res.data === "no") {
        setSubpage("no");
      } else {
        setOtp(res.data.otp);
        setPlay(res.data.play);
        setSubpage("video");
      }
    });
  }

  const Content = (props) => {
    // console.log(count);

    return (
      <div>
        <li className="link numbering">
          <h4
            className="link"
            onClick={(e) => {
              decide(props.file);
            }}>
            {props.title}
          </h4>
        </li>
      </div>
    );
  };

  const Chapters = (props) => {
    count = count + 1;

    a = count;
    String(a);
    console.log(props, "props");
    return (
      <Card>
        <Card.Header className="color2">
          <Accordion.Toggle className="link" as={Button} variant="link" eventKey={a}>
            <h4 className="link">
              {/* <ChevronLeftIcon className="link"></ChevronLeftIcon> */}
              {props.content}
            </h4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={a}>
          <Card.Body>
            <ol className="link numbering">
              {props.content.map((el) => (
                <Content {...el} key={el._id} />
              ))}
            </ol>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  Screen = () => {
    if (subpage === "syllabus") {
      // console.log(props.chapters)
      return <Syllabus chapters={chapters}></Syllabus>;
    } else if (subpage === "learning") {
      return <Learning></Learning>;
    } else if (subpage === "sheet") {
      return <Sheet page={params.id}></Sheet>;
    } else if (subpage === "wait") {
      return (
        <div className="container2">
          <center>
            <br></br>
            <h4>טוען...</h4>
          </center>
        </div>
      );
    } else if (subpage === "no") {
      return (
        <div className="container2">
          <ScrollToTop></ScrollToTop>
          <center>
            <br></br>
            <h4>צפייה ניתנת לאחר רכישה</h4>
          </center>
        </div>
      );
    } else if (subpage === "openning") {
      switch (props.auth) {
        case null:
          // in this case the server is still deciding wether or not i'm logged in

          return;
        case false:
          // i'm logged out
          return (
            <div className="container2">
              <center>
                <br></br>
                <h2>ברוכים הבאים</h2>
              </center>
              <br></br>

              <h4>כל מה שצריך כדי לעבור את הקורס בהצלחה נמצא בעמוד זה</h4>
              <h4>ניתן לצפות בשיעורים הראשונים של הקורס</h4>
              <h4>בהרשמה חינמית ניתן אף להוריד דף נוסחאות, ולצפות בסרטונים נוספים</h4>
              <br></br>
              <p>הצפייה בשיעורים אינה ניתנת במכשירי IOS של אפל</p>
              <p>יש לאפשר קוקיז ולהשתמש בדפדפן chrome\firefox לצפייה </p>
              <br></br>
            </div>
          );
        default:
          //    console.log(props.authName);
          if (props.auth.auth.indexOf(params.id) > -1) {
            return (
              <div className="container2">
                <center>
                  <br></br>
                  <h4>שלום {props.auth.firstName}</h4>
                  <h4>למידה מהנה!</h4>
                </center>
                <br></br>
                <p>הצפייה בשיעורים אינה ניתנת במכשירי IOS של אפל</p>
                <p>יש לאפשר קוקיז ולהשתמש בדפדפן chrome\firefox לצפייה </p>
                <br></br>
              </div>
            );
          } else {
            return (
              <div className="container2">
                <center>
                  <br></br>
                  <h2>ברוכים הבאים</h2>
                </center>
                <br></br>
                <h4>
                  כעת אתם יכולים להוריד דף נוסחאות ולצפות גם בפרק של מערכות אינרציאליות שיעור 1 ו -
                  2
                </h4>
                <br></br>
                <p>הצפייה בשיעורים אינה ניתנת במכשירי IOS של אפל</p>
                <p>יש לאפשר קוקיז ולהשתמש בדפדפן chrome\firefox לצפייה </p>
                <br></br>
              </div>
            );
          }
        // i'm logged in
      }
    }
  };

  function Screening() {
    if (subpage === "video") {
      return (
        <div>
          <ScrollToTop></ScrollToTop>
          <Vplayer file={file} otp={otp} play={play}></Vplayer>
        </div>
      );
    } else {
      return;
    }
  }

  // console.log(content);

  if (page === "course") {
    let content = chapters;
    // console.log("title", chapters);
    // console.log("title", title);

    if (content && content[0]) {
      return (
        <div>
          <Title title={title} subtitle1={subtitle1} subtitle2={subtitle2}></Title>
          {renderContent()}

          <div className="color2 back">
            <Row>
              <Col xs={12} md={3} lg={3}></Col>
              <Col xs={12} md={3} lg={3}>
                <h4 className="link" onClick={() => setSubpage("syllabus")}>
                  {/* {/* <ChevronLeftIcon className="link"></ChevronLeftIcon>סילבוס */} */}
                </h4>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <h4 className="link" onClick={() => setSubpage("learning")}>
                  {/* {/* <ChevronLeftIcon className="link"></ChevronLeftIcon>דרך הלמידה */} */}
                </h4>
              </Col>
              <Col xs={12} md={3} lg={3}>
                <h4 className="link" onClick={() => setSubpage("sheet")}>
                  {/* {/* <ChevronLeftIcon className="link"></ChevronLeftIcon>דף נוסחאות */} */}
                </h4>
              </Col>
              <Col xs={12} md={0} lg={0}></Col>
            </Row>
          </div>
          <Media queries={{ small: { maxWidth: 767 } }}>
            {(matches) =>
              matches.small ? (
                <div>
                  {Screen()}
                  {Screening()}

                  {chapters.map((num, index) => (
                    <Accordion key={num}>
                      <Chapters content={content} num={num} key={content.id}></Chapters>
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div>
                  <Row>
                    <Col xs={12} md={4} lg={3}>
                      {chapters.map((num, index) => (
                        <Accordion key={num}>
                          <Chapters content={content} num={num} key={content.id}></Chapters>
                        </Accordion>
                      ))}

                      {/* <Accordion>
                                                {content.map(Chapters)}
                                            </Accordion> */}
                    </Col>
                    <Col xs={12} md={8} lg={9}>
                      {Screen()}
                      {Screening()}
                    </Col>
                  </Row>
                </div>
              )
            }
          </Media>
        </div>
      );
    } else {
      return (
        <div>
          <center>
            <h4>טוען...</h4>
          </center>
        </div>
      );
    }
  } else if (page === "payment") {
    return (
      <div>
        <Title title={title} subtitle1={subtitle1} subtitle2={subtitle2}></Title>

        <Payment title={title} authName={params.id}></Payment>
      </div>
    );
  } else if (page === "") {
    return (
      <div>
        <center>
          <h4>טוען...</h4>
        </center>
      </div>
    );
  }
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Mechanics);
