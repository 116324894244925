import React from "react";
import { styled, useTheme, Paper, Typography, Divider, Grid, Box } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircleIcon from '@mui/icons-material/Circle';

export const TermDetails = ({ details, title }) => {
  const theme = useTheme();

  return (
    <Grid container justifyContent="center">
      <Grid item sx={{ marginBottom: '30px' }} xs={10} md={7}>
        <Typography sx={{
          fontSize: theme.typography.h5,
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: theme.fontFamily,
          color: theme.palette.black
        }}>
          {title}
        </Typography>
      </Grid>

      {details.map((detail, i) => {
        return (
          <Grid container spacing={{ xs: 2, md: 2 }} key={i}
            justifyContent="center"
            sx={(i % 2 == 0) ? { backgroundColor: '#F5F5F5', padding: '10px 0px 20px 0px' } : { backgroundColor: '', padding: '10px 0px 20px 0px' }}>
            <Grid item xs={10} md={2}>
              <Typography sx={{
                fontSize: theme.typography.h6,
                fontWeight: theme.typography.fontWeightBold,
                fontFamily: theme.fontFamily,
                color: theme.palette.coral
              }}>
                {detail.title}
              </Typography>
            </Grid>

            <Grid item xs={10} md={5}>
              {detail.text.length > 0 &&
                <Typography sx={{
                  fontSize: theme.fontSize.p,
                  fontFamily: theme.fontFamily
                }}>
                  {detail.text}
                </Typography>}
              <List>
                {detail.bullets.map((bullet, j) => {
                  return (
                    <ListItem key={j} sx={{
                      textAlign: 'right', fontWeight: theme.typography.fontWeightRegular, fontSize: theme.fontSize.p,
                      fontFamily: theme.fontFamily
                    }}><CircleIcon sx={{ fontSize: "8px", color: theme.palette.coral, marginLeft: '8px' }} /> {bullet}</ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        )
      })}

    </Grid>
  );
};

const IframeStyle = styled("iframe")(({ theme }) => ({
  height: "210px",
  margin: "1rem auto",
  border: "none",
  [theme.breakpoints.down("md")]: {
    height: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    height: "210px",
  },
}));