import axios from "axios";
import store from '../redux/store';

export const Auth = {
    setTokenToAxiosHeaders: function (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    },
    getStoredToken: function () {
        const storedData = localStorage.getItem('amitPhysics');

        if (!storedData) {
            return null;
        }

        const data = JSON.parse(storedData);
        // compare the expiry time of the item with the current time
        if ((new Date()).getTime() > data.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem('amitPhysics')
            return null;
        }
        return data.token;
    },
    deleteTokenFromAxiosHeaders: function () {
        store.dispatch({ type: 'updateIsLoggedIn', payload: false });
        store.dispatch({ type: 'updateUserDetails', payload: {} });

        delete axios.defaults.headers.common["Authorization"];

        localStorage.removeItem("amitPhysics");
        localStorage.removeItem("userDetails");
    },
}

export default Auth;