import React from 'react';
import {Link} from 'react-router-dom';



const Help = () => {
    return (
        <div style = {{textAlign: 'center'}} className= "container2">
            <h2>אני יכול לעזור</h2>
            <br></br>
            <p>חסכו כסף על תשלום לסמסטרים נוספים וקורסים חוזרים</p>
             <p>והתחילו ללמוד כבר עכשיו באופן מסודר עם הבנה עמוקה</p>
             <br></br>
             <Link className= "butn " to = {'/contact'}>צרו קשר</Link>
        </div>
    );
}

export default Help;