import React from "react";

import { Header } from "./Header";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import { Footer } from "./Footer";

export const MainContainer = ({ children, props }) => {
  const theme = useTheme();
  return (
    <Box sx={{ backgroundColor: theme.palette.white, height: "100%" }}>
      <Header />
      <Box sx={{ minHeight: "82vh" }}>{children}</Box>
      <Footer/>
    </Box>
  );
};
