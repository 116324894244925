// this is for the Data layer control (Redux)
// import "@babel/polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";

import store from './redux/store';
import { Provider } from "react-redux";

ReactDOM.render(
  <RecoilRoot>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
  </RecoilRoot>,
  document.querySelector("#root")
);
