import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../api/index";

import { useTheme, Paper, Typography, Grid, Link, Box } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

import { AlertMessage } from "../../utils/AlertMessage";

export const MaterialList = ({ files, title, backgroundColor }) => {
    // -- Snackbar start --
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const handleClose = () => {
        setAlertIsOpen(false);
    };

    const [alertMessage, setAlertMessage] = useState("");
    const [alertStatus, setAlertStatus] = useState("");
    // -- Snackbar end  --

    const theme = useTheme();

    const handleClick = (file) => {
        if (file.type === 3 && file.file.link) {
            window.open(file.file.link);
            return;
        }

        axios
            .post(
                `${BASE_URL}/files/azureFile`,
                {
                    containerName: file.file.container,
                    blobName: file.file.blob,
                    type: "files",
                },
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                if (res.data.error) {
                    setAlertMessage(res.data.message);
                    setAlertStatus('error');
                    setAlertIsOpen(true);
                    return;
                }

                const file = new Blob([res.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch(() => {
                setAlertMessage("נדרש להתחבר על מנת לצפות בקובץ");
                setAlertStatus('error');
                setAlertIsOpen(true);
            });
    };

    const styles = theme => ({
        text: {
            '&:hover': {
                background: "#f00",
            },
        },
    });

    return (
        <Grid container sx={{ padding: '10px 0px 20px 0px', backgroundColor: backgroundColor }}>
            <Grid container spacing={{ xs: 2, md: 2 }} justifyContent="center">
                <Grid item xs={10} md={2}>
                    <Typography sx={{
                        fontSize: theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        fontFamily: theme.fontFamily,
                        color: theme.palette.coral
                    }}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={10} md={5}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {files.map((file, i) => {
                            return (
                                <Link key={i} sx={{
                                    textAlign: 'right', fontWeight: theme.typography.fontWeightRegular, fontSize: '18px',
                                    fontFamily: theme.fontFamily, pointer: 'cursor', color: theme.palette.black, cursor: 'pointer'
                                }} underline="none" onClick={() => { handleClick(file) }}>
                                    <CircleIcon key={`icon_${i}`} sx={{ fontSize: "8px", color: theme.palette.coral, marginLeft: '8px' }} /> {file.name}</Link>
                            )
                        })}
                    </Box>
                </Grid>
            </Grid>
            <AlertMessage
                message={alertMessage}
                open={alertIsOpen}
                onClose={handleClose}
                status={alertStatus}
            />
        </Grid>
    )
};