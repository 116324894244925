import React from "react";
import { styled, useTheme, Typography, Grid } from "@mui/material";
import { useHistory } from "react-router";
import { Title } from "../../utils/Title";

import { ButtonComponent } from '../../utils/ButtonComponent';

export const Contact = () => {
    const theme = useTheme();
    const history = useHistory();

    return (
        <Grid container justifyContent="center" sx={{ marginTop: '30px' }}>
            <Grid item sx={{ margin: '30px 0px' }} xs={10} md={7}>
                <Title color={theme.palette.coral}>אני יכול לעזור</Title>
            </Grid>

            <Grid item sx={{ marginBottom: '30px' }} xs={10} md={7}>
                <Typography sx={{
                    fontSize: '18px',
                    fontFamily: theme.fontFamily,
                    color: theme.palette.black,
                    textAlign: 'center'
                }}>
                    חסכו כסף על תשלום לסמסטרים נוספים וקורסים חוזרים<br />
                    והתחילו ללמוד כבר עכשיו באופן מסודר עם הבנה עמוקה
                </Typography>
            </Grid>

            <Grid item xs={10} md={6} sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                <ButtonComponent
                    onClick={() => history.push("/contact")}
                    text='צור קשר'
                    spColor={theme.palette.coral}
                    fontColor={theme.palette.white}
                />
            </Grid>
        </Grid>
    );
};