import React from "react";

import { CardMedia, Container, Grid, styled, useTheme, Typography } from "@mui/material";

export const HomeHeader = ({ title, body, video }) => {
  const theme = useTheme();
  return (
    <Container className="mt-5" sx={{ height: { md: '50vh' }, display: { md: 'flex' }, flexDirection: { md: 'column' }, justifyContent: { md: 'space-evenly' }, marginBottom: { xs: '3rem' } }}>
      <Grid container spacing={{ xs: 2, md: 2 }}>
        <VideoGrid item xs={12} md={6}>
          <CardMedia
            component={"iframe"}
            image={video}></CardMedia>
        </VideoGrid>
        <TextGrid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              margin: "0 0 -1rem 0",
              lineHeight: "1",
              fontWeight: "bold",
              fontSize: { md: theme.fontSize.h2, xs: '30px' },
              fontFamily: theme.fontFamily,
              color: theme.palette.black
            }}>
            {title}
          </Typography>

          <Typography
            sx={{
              fontWeight: 100,
              fontSize: { md: theme.fontSize.h4, xs: '22px' },
              fontFamily: theme.fontFamily,
              margin: "2rem 0 0 0",
            }}>
            {body}
          </Typography>
        </TextGrid>
      </Grid>
    </Container>
  );
};
const TextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "1rem 0 2rem 0",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
}));
const VideoGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
