export const AppState = {
    isLoggedIn: false,
    userDetails: {},
    isAuthForCourse: false,
    courseId: null,
    comments: [],
    secretToken: null,
    price: 200,
    anchor: null
}

export function reduce(state = AppState, action) {
    const newAppState = { ...state }
    switch (action.type) {
        case 'updateIsLoggedIn':
            newAppState.isLoggedIn = action.payload;
            break;
        case 'updateUserDetails':
            newAppState.userDetails = action.payload;
            break;
        case 'updateIsAuthForCourse':
            newAppState.isAuthForCourse = action.payload;
            break;
        case 'setCourseId':
            newAppState.courseId = action.payload;
            break;
        case 'setComments':
            newAppState.comments = action.payload;
            break;
        case 'setSecretToken':
            newAppState.secretToken = action.payload;
            break;
        case 'setPrice':
            newAppState.price = action.payload;
            break;
        case 'setAnchor':
            newAppState.anchor = action.payload;
            break;
    }

    return newAppState;
}

export default reduce;