import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "./Title";
import Help from "./Help";
import Questions from "./Questions";
import List from "./List";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import "bootstrap/dist/css/bootstrap.css";

import Facebook2 from "../images/Facebook(2).jpg";
import Facebook5 from "../images/Facebook(5).jpg";
import Facebook10 from "../images/Facebook(10).jpg";
import axios from "axios";
import { BASE_URL } from "../api";

require("../css/styles.css");

const questions = [
  {
    id: 1,
    title: "שאלות נפוצות",
    subTitle: "1. מהו מבנה השיעורים בקורס?",
    explenation:
      "השיעורים בקורס מתחלקים לשיעורים תיאורטיים ותרגולים. כל השיעורים הם לרוב מאוד קצרים (לרוב 5-20 דקות), המנסים להתמקד בהנחת העקרונות שיעזרו לכם לפתור מגוון רחב של תרגילים. התרגולים מתחלקים לשלושה שלבים: השלב הראשון הינו ניסיון פתירה אישי שלכם, בשלב השני יש רמז קטן שיכול לעזור להכווין אתכם אל עבר התשובה. ובשלב השלישי יש פתרון מלא שבו אני מסביר כיצד צריך לגשת לתרגיל ומראה את הפתרון החישובי",
    bullet: [],
  },
  {
    id: 2,
    title: "",
    subTitle: "2. מה חשוב שאדע לפני רכישת הקורס?",
    explenation:
      "רצוי להירשם לאתר (ההרשמה הינה חינמית) ובכך להינות מדפי נוסחאות וסרטונים לדוגמא בקורס. לאחר מכן, באם תיהיו מרוצים תוכלו לרכוש את הקורס המלא. צפו בתנאי השימוש באתר לפני הרכישה.",
    bullet: [],
  },
  {
    id: 3,
    title: "",
    subTitle:
      "3. לא נכחתי הסמסטר בקורס במוסד הלימודים שלי. האם הקורס שלך יכסה את כל החומר ויכין אותי למבחן?",
    explenation:
      "הרבה סטודנטים שלימדתי היו נוכחים חלקית בשיעורים במוסד לימודים שלהם, והיה להם הצלחה במבחן. למרות זאת, אנני המרצה הרשמי בקורס, ואנני מחבר הבחינה. לכן, יתכנו אי דיוקים וחוסר התאמה לסילבוס ולמבחן. הצעתי היא כן להיות חלק מהקורס הרשמי, ושאוכל אני להיות לכם לעזר בהבנה של הדברים.",
    bullet: [],
  },
  {
    id: 4,
    title: "",
    subTitle: "4. האם הקורס כולל אפשרות של שאילת שאלות ובקשות שונות?",
    explenation:
      "הקורס בנוי כך שהוא מנסה לכלול את כל החומר ולהקיף את כל השאלות האפשריות שיש לסטודנטים. למרות זאת, זה כנראה לעולם לא יספק את כל השאלות האפשריות. אשמח שתשלחו לי שאלות, הערות והארות שונות שיש לכם, ובכך אנסה לרכז אותם בניסיון להוסיף לקורס האינטרנטי עבור כולם. אלו קורסים דינמיים שבהם משפרים אותם בכל רגע. מטרתי היא לנסות שתצאו מרוצים ועם הרגשה שהקורס עזר לכם בכדי להצליח.",
    bullet: [],
  },
];

const facebook = [
  {
    id: 1,
    type: "rec",
    title: "",
    subTitle: "",
    explenation: "",
    img: Facebook2,
    img2: Facebook5,
    style1: "img-fluid TitlePicture1",
    alt: "Recommendation",
    to: "https://www.facebook.com/%D7%94%D7%A2%D7%9E%D7%99%D7%AA-%D7%9C%D7%A4%D7%99%D7%A1%D7%99%D7%A7%D7%94-Physics-Tutor-565138127287363/reviews",

    bullet: [],
  },
  {
    id: 2,
    type: "rec",
    title: "",
    subTitle: "",
    explenation: "",
    img: Facebook10,
    img2: null,
    style1: "img-fluid TitlePicture1",
    alt: "Recommendation",
    to: "https://www.facebook.com/%D7%94%D7%A2%D7%9E%D7%99%D7%AA-%D7%9C%D7%A4%D7%99%D7%A1%D7%99%D7%A7%D7%94-Physics-Tutor-565138127287363/reviews",

    bullet: [],
  },
];

const description = [
  {
    id: 1,
    type: "writing",
    title: "איכות ההוראה חשובה",
    subTitle: "",
    explenation: "לימדו פיסיקה עם פיסיקאי, שמשרתו המלאה הינה לימוד ופיתוח הוראה.",
    bullet: [],
  },
  {
    id: 2,
    type: "writing",
    title: "בנייה של אלגוריתם מסודר לתרגילים",
    subTitle: "",
    explenation:
      "אנחנו נבנה שיטת פתרון מסודרת, שתוכל לפתור כל תרגיל בקורס שלכם. כך שגם אם אתם בבחינה לא יודעים מה לעשות בתרגיל, יהיה לכם דרך מסודרת עם שאלות מנחות שילוו אתכם עד לפתרון המלא של התרגיל.",
    bullet: [],
  },
  {
    id: 3,
    type: "writing",
    title: "התמקדות בהבנה פרקטית של החומר",
    subTitle: "",
    explenation:
      "לאחר כל הקורס, ישנה בחינה, וזו הדרך שבה מודדים את ההבנה שלכם בחומר. לרוב, במבחנים בפיסיקה, המטרה היא לראות אם אתם מבינים מה מטרת הנוסחא וכיצד משתמשים בה, ופחות בלהוכיח למה הנוסחא נכונה. בקורס שלנו, רוב זמן התיאוריה יהיה במטרה להבין: מה מטרת הפרק, מה הנוסחאות מסמלות, וכיצד משתמשים בהם.",
    bullet: [],
  },
  {
    id: 4,
    type: "writing",
    title: "רוב הקורס מתמקד בפתרון תרגילים",
    subTitle: "",
    explenation:
      "גם אם אתם מבינים את החומר התיאורטי, לא תמיד תדעו כיצד לבטא זאת בפרקטיקה. בקורס אנחנו נעבור על הרבה סוגים של שאלות, מהקלות לקשות, שבו נלמד כיצד עלינו לגשת וליישם את התיאוריה למעשה.",
    bullet: [],
  },
  {
    id: 5,
    type: "writing",
    title: "מעבר מלימוד פאסיבי ללימוד אקטיבי",
    subTitle: "",
    explenation:
      "יש הבדל גדול בין לראות מישהו פותר תרגיל והכל נראה מובן, לבין להצליח לבד עם דף ועט לפתור אותו. בכל תרגיל בקורס, יהיה זמן לתרגול עצמי שלכם, ורק כך תוכלו להרגיש את רמתכם. באם לא תצליחו לפתור, אני נותן רמז לפתרון. אם גם זה לא מספיק, תוכלו לעבור איתי על הפתרון המלא ובכך להשלים לבד בדף את הפתרון. רק בתרגול אישי אפשר לדעת אם באמת יודעים כיצד לפתור.",
    bullet: [],
  },

  {
    id: 6,
    type: "writing",
    title: "חיסכון כלכלי",
    subTitle: "",
    explenation:
      "תעלו את סיכויי ההצלחה שלכם בקורסים, ובכך תחסכו הרבה זמן וכסף על מועדים נוספים, קורסים חוזרים, והארכת משך התואר.",
    bullet: [],
  },
];

const Courses = (props) => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/lessons/getCoursesInfo/טכניון`).then((res) => {
      setCourses(res.data);
    });
  }, []);

  return (
    <div>
      <Title
        title="קורסי פיזיקה לאקדמיה"
        subtitle1="בזמן שלכם, בקצב שלכם"
        subtitle2="צפו בסרטון מקדים"
        url="https://www.youtube.com/embed/QUugoDAGGXY"></Title>

      <div className="container2">
        <h2>קורסי מכניקה</h2>
        <hr className="line"></hr>
        <div className="container1">
          {/* <h4>קורסי ריענון</h4>
                        <br></br>
                        <div className = "container6">
                            <Link to = {`/courses/${mechanics[7].authName}`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>{mechanics[7].title}</h4></Link>                              
                        </div> 
                        <hr></hr> */}

          <h4>הטכניון</h4>
          <br></br>
          {courses.map((course, index) => (
            <div className="container6" key={index}>
              <Link
                to={`/courses/${course._id}/ ${course.subTitle1}/ ${course.subTitle2} /${course.courseTitle}`}>
                <h4 className="link">
                  {/* TODO: add icon */}
                  {/* <ChevronLeftIcon></ChevronLeftIcon> */}

                  {course.courseTitle}
                </h4>
              </Link>
            </div>
          ))}
          <hr></hr>

          {/* <h4>אוניברסיטת בן-גוריון</h4>
          <br></br>
          <div className="container6">
            <Link to={`/courses/${mechanics[3].authName}`}>
              <h4 className="link">
                <ChevronLeftIcon></ChevronLeftIcon>
                {mechanics[3].title}
              </h4>
            </Link>
            <Link to={`/courses/${mechanics[4].authName}`}>
              <h4 className="link">
                <ChevronLeftIcon></ChevronLeftIcon>
                {mechanics[4].title}
              </h4>
            </Link>
          </div>
          <hr></hr> */}

          {/* <h4>אוניברסיטת תל אביב</h4>
          <br></br>
          <div className="container6">
            <Link to={`/courses/${mechanics[5].authName}`}>
              <h4 className="link">
                <ChevronLeftIcon></ChevronLeftIcon>
                {mechanics[5].title}
              </h4>
            </Link>
          </div>
          <hr></hr> */}

          {/* <h4>מכללת אפקה</h4>
          <br></br>
          <div className="container6">
            <h4 className = "link" onClick={(e) => {decide(6)}}><ChevronLeftIcon></ChevronLeftIcon>{mechanics[6].title}<p className = "spacing">  {mechanics[6].subTitle}</p> </h4> 
            <Link to={`/courses/${mechanics[6].authName}`}>
              <h4 className="link">
                <ChevronLeftIcon></ChevronLeftIcon>
                {mechanics[6].title}
              </h4>
            </Link>
          </div>
          <hr></hr> */}
        </div>
        <br></br>
        <hr className="dot"></hr>
        <br></br>
        <center>
          <h2>לומדים במוסד לימודים אחר?</h2>
          <h2>קורס אחר?</h2>
          <br></br>
          <p>צרו איתי קשר ואוכל להכווין אתכם</p>
          <p>ואולי לפתוח קורס ייעודי עבורכם!</p>
        </center>
      </div>
      <br></br>

      <hr className="dot"></hr>
      <br></br>

      <div className="container2 ">
        <h2>ייחודיות הקורס</h2>
        <hr className="line"></hr>
        {description.map((el) => (
          <List {...el} key={el.id} />
        ))}
      </div>
      <br></br>

      <div className="faq">
        {questions.map((el) => (
          <Questions {...el} key={el.id} />
        ))}
      </div>
      <br></br>
      <div className="container2 ">
        <h2>בוגרי הקורסים</h2>
        <hr className="line"></hr>
      </div>
      {facebook.map((el) => (
        <List {...el} key={el.id} />
      ))}
      <br></br>
      <br></br>
      <Help></Help>
      <br></br>
    </div>
  );
};

export default Courses;
