import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import Title from "./Title";
import Help from "./Help";
import Questions from "./Questions";
import List from "./List";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import technion1 from "../images/technion1(1).png";
import "bootstrap/dist/css/bootstrap.css";

import { BASE_URL } from "../api";
import axios from "axios";

require("../css/styles.css");

const Pakal = (props) => {
  const [courses, setCourses] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/lessons/getCoursesInfo/פקל`).then((res) => {
      setCourses(res.data);
    });
    console.log(courses);
  }, []);
  return (
    <div>
      <Title title="קורסים קבוצתיים" img={technion1} style1="TitlePicture3"></Title>

      <div className="container2">
        <br></br>
        <br></br>
        <h4>מחקר בהוראה היברידית</h4>
        <h4>הביעו את דעתכם לגבי הדרך בה תרצו ללמוד באקדמיה</h4>
        <hr className="line"></hr>
        <div className="container1">
          <br></br>
          <p>
            במסגרת הלימודים לתארים מתקדמים בפקולטה לחינוך בטכניון, אני מבצע מחקר בכדי לספק מידע לגבי
            העדפות הסטודנטים לדרכי למידה שונות וניסיון להניב המלצות לאסטרטגיות למידה והוראה בעתיד
            עבור מוסדות האקדמיה.
          </p>
          <p>
            יש לכם אפשרות בקורס שלי להגיע באופן פרונטאלי, לצפות בזום ו/או לראות הקלטות. המטרה היא
            לנסות להבין מהם העדפות הלמידה של סטודנטים ע"י מציאת קשרים למאפייני הלמידה העצמית שלהם,
            תכונות האישיות ושביעות הרצון מהקורס.
          </p>
          <br></br>
          <p>מי שלא נוכח/ת או שצופה בזום – מאוד אשמח לקולכם במחקר!</p>
          <br></br>
          <p>
            פרטים נוספים נמצאים בשאלון הראשון, מי שמעוניין/ת בפרטים נוספים לגבי המחקר יכול/ה להרגיש
            חופשי לפנות אלי בפרטי.
          </p>
          <p>(כלל השאלונים נמצאים בעמוד הקורס הייעודי)</p>
        </div>

        <br></br>
        <br></br>
        <h2>פקלי סמסטר חורף 2021-2022</h2>
        <hr className="line"></hr>
        <div className="container1">
          {/* <h4>קורסי ריענון</h4>
                        <br></br>
                        <div className = "container6">
                            // // <Link to = {`/coursesBagrut/${mechanics[7].authName}`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>{mechanics[7].title}</h4></Link>                              
                        </div> 
                        <hr></hr> */}
          <br></br>
          {courses.map((course, index) => (
            <div key={index} className="container6">
              <Link to={`/pakal/${course._id}/${course.subTitle1}/${course.courseTitle}`}>
                <h4 className="link">
                  {/* {/* <ChevronLeftIcon></ChevronLeftIcon> */}
                  {course.courseTitle}
                </h4>
              </Link>
            </div>
          ))}
          <hr></hr>
        </div>
        <br></br>
      </div>
      <br></br>
    </div>
  );
};

export default Pakal;

// import React from 'react';
// import {Link} from 'react-router-dom';
// import Title from './Title';
// import axios from 'axios';
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// import technion1 from '../images/technion1(1).png';
// import {Row, Col, Form, Button} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';

// require('../css/styles.css');

// const Pakal = () => {
//     return (
//         <div>
//             <div>
//                 <Title
//                     title = "קורסים קבוצתיים"
//                     img = {technion1}
//                     style1 = "TitlePicture3"
//                 ></Title>
//             </div>

//             <div className = "container1">
//                         <br></br>
//                         <br></br>
//                         <h4>הטכניון - פק"לי סמסטר חורף 2021</h4>
//                         <br></br>
//                         <div className = "container6">
{
  /* <Link to = {`/summerMarathon2021/Summer1mzoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 1מ</h4></Link> */
}
{
  /* <Link to = {`/summerMarathon2021/Summer2zoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2</h4></Link>
                            <Link to = {`/summerMarathon2021/Summer2mmzoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2ממ</h4></Link> */
}

//                         </div>
//                         {/* <br></br>
//                         <br></br>
//                         <h4>הטכניון - פק"לי סמסטר אביב 2021</h4>
//                         <br></br>
//                         <div className = "container6"> */}
{
  /* <Link to = {`/spring2021/1zoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 1</h4></Link>
                            <Link to = {`/spring2021/1mzoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 1מ</h4></Link> */
}
{
  /* <Link to = {`/spring2021/2zoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2</h4></Link> */
}
{
  /* <Link to = {`/spring2021/2mmzoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2ממ</h4></Link> */
}

//                         {/* </div>  */}
//                         {/* <br></br>
//                         <br></br>
//                         <h4>הטכניון - מרתונים סמסטר אביב 2021</h4>
//                         <br></br>
//                         <div className = "container6">
{
  /* <Link to={`/springMarathon2021/Marathon1mzoom`}>
  <h4 className="link">
    <ChevronLeftIcon></ChevronLeftIcon>פיסיקה 1מ
  </h4>
</Link>; */
}
{
  /* <Link to = {`/springMarathon2021/Marathon2zoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2</h4></Link> */
}
{
  /* <Link to = {`/springMarathon2021/Marathon2mmzoom`}><h4 className = "link"><ChevronLeftIcon></ChevronLeftIcon>פיסיקה 2ממ</h4></Link> */
}

//                         {/* </div>  */}
//                         <br></br>
//                         <br></br>
//                         <br></br>
//                         <br></br>
//                 </div>

//         </div>
//     );
// }

// export default Pakal;
