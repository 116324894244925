import React from "react";
import axios from "axios";
import Title from "./Title";
import { useHistory as history } from "react-router-dom";

import { Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

require("../css/styles.css");

const EmailCheck = () => {

  const onCheckSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    // console.log (formDataObj);

    axios.post("api/check2", formDataObj).then(function (res) {
      // console.log(res.data);
      // setMessage (res.data);
      if (res.data === "NewUser") {
        history.push("/success");
      } else {
        window.alert(res.data);
      }
    });
  };

  axios.post("api/check1").then(function (res) {
    // console.log(res.data);
    // setMessage (res.data);
  });

  return (
    <div>
      <Title
        title="הרשמה"
        subtitle1="נשלח אימייל עם קוד אישי"
        subtitle2="(נא לבדוק את תיבת הספאם)"></Title>

      <div className="container container2">
        <br></br>
        <br></br>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <Form onSubmit={onCheckSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <p>קוד הנשלח לאימייל</p>
                </Form.Label>
                <Form.Control required name="secretToken" placeholder="code" />
              </Form.Group>
              <Button className="butn border" variant="primary" type="submit">
                אישור
              </Button>
            </Form>
          </Col>
          <Col xs={12} md={6} lg={6}></Col>
        </Row>
      </div>
      <br></br>
    </div>
  );
};

export default EmailCheck;
