import React from "react";
import { useEffect, useState } from "react";
import { Folder } from "@mui/icons-material";
import { Box, Fade, Popper, Typography, useTheme, Link } from "@mui/material";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { BASE_URL } from "../../api";
import axios from "axios";
import Auth from '../../hooks/Auth';
import store from '../../redux/store';

export const Lesson = ({ lesson, handleVideo, setAlertMessage, setAlertIsOpen, setAlertStatus }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const [courseId, setCourseId] = useState(store.getState().courseId);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);

  useEffect(() => {
    const token = Auth.getStoredToken();

    if (token) {
      try {
        Auth.setTokenToAxiosHeaders(token);
      }
      catch (error) {
        console.log(error)
      }
    }

    const unsubscribe = store.subscribe(() => {
      setUserDetails(store.getState().userDetails);
      setCourseId(store.getState().courseId);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }; 

  const handleFileClick = (resource) => {
    if (!store.getState().isAuthForCourse) {
      setAlertMessage('יש לרכוש את הקורס על מנת לצפות בקבצים.');
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }
    
    axios
      .post(
        `${BASE_URL}/files/azureFile`,
        { containerName: resource.container, blobName: resource.blob, id: lesson._id, type: "lesson", courseID: courseId, username: store.getState().userDetails.username },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((err) => {
        setAlertMessage(err);
        setAlertStatus('error');
        setAlertIsOpen(true);
      })
      .finally(() => {
        setAnchorEl(null);
      })
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <Box>
      <Typography sx={{ fontFamily: theme.fontFamily, margin: "0 0 1rem 0" }} onClick={() => { handleVideo(lesson) }}>
        {lesson.lesson[0].lessonTitle}
      </Typography>

      {(lesson.lesson[0].resources && lesson.lesson[0].resources[0].blob.length > 0) && (
        <Box>
          <ButtonComponent
            type="button"
            text={"משאבים"}
            fontColor={"black"}
            onClick={handleClick}
            startIcon={<Folder />}
          />

          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <div>
                  {lesson.lesson[0].resources.map((resource, i) => {
                    return (
                      <Box key={i}>
                        <Link underline='none' key={`file_${i}`} sx={{ border: 1, p: 1, lineHeight: '2.3rem', bgcolor: "background.paper", cursor: 'pointer', color: 'black' }} onClick={() => handleFileClick(resource)}>
                          {resource.blob}
                        </Link>
                      </Box>
                    );
                  })}
                </div>
              </Fade>
            )}
          </Popper>
        </Box>
      )}
    </Box>
  );
};
