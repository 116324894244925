import { Button, styled } from "@mui/material";
import React from "react";

export const ButtonComponent = ({
  spColor,
  text,
  margin,
  rounded,
  disabled,
  onClick,
  fontColor,
  disableRipple,
  endIcon,
  startIcon,
  border,
}) => {
  return (
    <ButtonStyle
      border={border}
      disableRipple={disableRipple}
      fontcolor={fontColor}
      rounded={rounded}
      spcolor={spColor}
      margin={margin}
      disabled={disabled}
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}>
      {text}
    </ButtonStyle>
  );
};

const ButtonStyle = styled(Button)((props) => ({
  color: props.fontcolor ? props.fontcolor : "white",
  backgroundColor: props.border ? '#00000000' : props.spcolor,
  // border: "1px solid white",
  fontFamily: "Assistant",
  margin: props.margin,
  disabled: props.disabled,
  border: props.border ? "1px solid " + props.spcolor : "unset",
  // border: "1px solid black ",
  "&:hover": {
    backgroundColor: "unset",
    border: "1px solid " + props.spcolor,
    color: props.spcolor ? props.spcolor : props.fontcolor,
    fontWeight: 'bold'
  },
  "&.Mui-disabled": {
    color: "white",
    backgroundColor: "gray",
  },
  fontSize: '1rem',
  // padding: "0.2rem 3rem",
  padding: "0rem 1rem",

  borderRadius: props.rounded ? "3rem" : "0",

  "& .MuiButton-startIcon": {
    margin: "0px 0px 0px 0.5rem",
  },
}));
