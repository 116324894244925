import * as React from 'react';
import { useState, useEffect } from 'react';

import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme, Divider, Grid, styled } from "@mui/material";

import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

import { MainContainer } from "../Containers";
import { Login } from "../Authentication/Login";
import Routing from '../../hooks/Routing';
import Auth from '../../hooks/Auth';
import store from '../../redux/store';
import { TermDetails } from "../Terms/TermDetails";

import axios from "axios";
import { BASE_URL } from "../../api";

import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";

export const Purchasing = () => {
    // -- Snackbar start --
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const handleClose = () => {
        setAlertIsOpen(false);
    };

    const [alertMessage, setAlertMessage] = useState("");
    const [alertStatus, setAlertStatus] = useState("");
    // -- Snackbar end  --

    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState({ telephone: false });
    const [state, setState] = useState({ telephone: '', VerificationCode: '' });
    const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);
    const [userDetails, setUserDetails] = useState(store.getState().userDetails);
    const [courseTitle, setCourseTitle] = useState("");

    const [price, setPrice] = useState(store.getState().price);

    const [courseId, setCourseId] = useState(store.getState().courseId);

    const [paymentUrl, setPaymentUrl] = useState('');

    const [telephone, setTelephone] = useState('');
    const [request, setRequest] = useState(null);

    const history = useHistory();

    const details = [{ title: 'הרכישה היא עבור משתמש בודד', text: '', bullets: ['רכישת הקורס הינה אישית, אין להעבירו לאף צד שלישי.'] },
    { title: 'אין החזר כספי', text: '', bullets: ['ההרשמה החינמית לאתר היא בכדי שתוכלו לראות שהסרטונים עובדים מהמחשב והדפדפן שאתם משתמשים בו, ובנוסף לכך לראות באם צורת ההוראה של הקורס מתאימה עבורכם. לאחר הרכישה, אין אפשרות של החזר כספי'] },
    { title: 'שאילת שאלות', text: '', bullets: ['כל שינוי הכי קטן בשאלה יכול לגרום לשינוי מאוד גדול בתשובה, ולכן השאלות צריכות להתייחס ספציפית לתוכן השיעור'] },
    { title: 'תנאי השימוש', text: '', bullets: ['להמשך רכישת הקורס הינכם מאשרים כי קראתם והסכמתם לתנאי השימוש באתר'] },]

    useEffect(async () => {
        const token = Auth.getStoredToken();

        if (token)
            Auth.setTokenToAxiosHeaders(token);

        const unsubscribe = store.subscribe(() => {
            setIsLoggedIn(store.getState().isLoggedIn);
            setUserDetails(store.getState().userDetails);
            setCourseId(store.getState().courseId);
            setPrice(store.getState().price);
        });

        if (!courseId) {
            let id = parseInt(query.get("courseid"));
            setCourseId(id);
            store.dispatch({ type: 'setCourseId', payload: id });
        }

        if (!price) {
            await getCourse();
        }

        if (store.getState().userDetails.telephone && store.getState().userDetails.telephone != "0") {
            setState({ telephone: store.getState().userDetails.telephone });
        }

        return () => {
            unsubscribe();
        }
    }, []);

    useEffect(async () => { if (isLoggedIn) await getCourse(); }, [isLoggedIn])

    const getCourse = async () => {
        await axios.post(`${BASE_URL}/lessons/courseIds`, { number: store.getState().courseId })
            .then((res) => {
                if (res.data.error) {
                    setAlertMessage(res.data.message);
                    setAlertStatus('error');
                    setAlertIsOpen(true);
                    return;
                }

                if (store.getState().isLoggedIn && store.getState().userDetails.auth && findEveryElements(res.data[0].ID, store.getState().userDetails.auth)) {
                    store.dispatch({ type: 'updateIsAuthForCourse', payload: true });
                    history.goBack();
                }
            })
            .then(async () => {
                await setCoursePrice();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const setCoursePrice = async () => {
        await axios
            .get(`${BASE_URL}/courses/getCourse/${store.getState().courseId}`)
            .then((res) => {
                if (res.data.error) {
                    setAlertMessage(res.data.message);
                    setAlertStatus('error');
                    setAlertIsOpen(true);
                    return;
                }
                
                setCourseTitle(res.data[0].courseTitle);

                let storedPeriod = localStorage.getItem('period');

                if (store.getState().courseId === 104) {
                    switch (storedPeriod) {
                        case 'חודש':
                            store.dispatch({ type: 'setPrice', payload: res.data[0].price[0] })
                            break;
                        case '6 חודשים':
                            store.dispatch({ type: 'setPrice', payload: res.data[0].price[1] })
                            break;
                        case 'שנה':
                            store.dispatch({ type: 'setPrice', payload: res.data[0].price[2] })
                            break;
                        default:
                            break;
                    }
                    return;
                } else {
                    store.dispatch({ type: 'setPrice', payload: res.data[0].price[0] });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function findEveryElements(arr1, arr2) {
        return arr1.every((item) => arr2.includes(item));
    }

    const onClick = (e) => {
        e.preventDefault();

        axios.post(`${BASE_URL}/payment`, { telephone: state.telephone }).then(function (res) {
            if (res.data.error) {
                setAlertMessage(res.data.message);
                setAlertStatus('error');
                setAlertIsOpen(true);
                return;
            }
            // console.log(res.data);
            // setMessage (res.data);

            ReactGA.event({
                category: "Payment",
                action: "submited phone",
                label: res.data,
            });

            if (res.data === "activeFalse") {
                history.push("/checkEmail");
            } else if (res.data === "have") {
                history.push("/payment");
            } else if (res.data === "err") {
                setAlertMessage("שליחת הודעה נכשלה");
                setAlertStatus('error');
                setAlertIsOpen(true);
            } else if (res.data.request === undefined) {
                setAlertMessage("מספר טלפון לא תקין");
                setAlertStatus('error');
                setAlertIsOpen(true);
            } else {
                setRequest(res.data.request);
                setAlertMessage('נשלח קוד אימות למכשירך.');
                setAlertStatus('success');
                setAlertIsOpen(true);
                setTelephone(res.data.telephone);
            }
        });
    };

    const onVerificateClicked = (e) => {
        e.preventDefault();

        if (!validateEmail(userDetails.username)) {
            setAlertMessage("כתובת מייל לא תקינה.");
            setAlertStatus('error');
            setAlertIsOpen(true);
            return;
        }

        const formData = {
            request: request,
            password2: state.VerificationCode,
            username: userDetails.username,
            telephone: telephone
        }
        axios.post(`${BASE_URL}/payment/verification`, formData).then(function (res) {
            if (res.data.error) {
                if (res.data.message == "A wrong code was provided too many times. Workflow terminated") {
                    res.data.message = "הוזן קוד שגוי יותר מדיי פעמים. נא להתחבר בשנית";
                    history.push("/login");
                    return;
                } else if (res.data.message == "The code provided does not match the expected value")
                    res.data.message = "הקוד שהוזן שגוי. נא נסו שנית";

                setAlertMessage(res.data.message);
                setAlertStatus('error');
                setAlertIsOpen(true);
                return;
            }
            // setMessage (res.data);

            ReactGA.event({
                category: "Payment2",
                action: "phone check",
                label: res.data,
            });

            if (res.data === "NexmoErr") {
                setAlertMessage("שליחת הודעה נכשלה");
                setAlertStatus('error');
                setAlertIsOpen(true);
            } else if (res.data === "no") {
                setAlertMessage("סיסמא לא תואמת, אנא נסה שוב");
                setAlertStatus('error');
                setAlertIsOpen(true);
            } else if (res.data === "MongoErr") {
                setAlertMessage("אירעה תקלה, אנא נסה שוב");
                setAlertStatus('error');
                setAlertIsOpen(true);
            } else if (res.data === "user verified") {
                createWebCheckoutSession();
            }
        });
    }

    const createWebCheckoutSession = async () => {
        if (!price) await setCoursePrice();

        const webCheckoutData = {
            courseId: courseId,
            courseTitle: courseTitle,
            user: userDetails,
            telephone: telephone,
            price: store.getState().price
        }

        await axios.post(`${BASE_URL}/payment/webcheckout`, webCheckoutData).then(function (res) {
            if (res.data.error) {
                setAlertMessage(res.data.message);
                setAlertStatus('error');
                setAlertIsOpen(true);
                return;
            }
            setPaymentUrl(res.data.url);
        }).then(() => {
            setActiveStep(2);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setState({ ...state, [name]: value });
    };

    const steps = [{
        id: 0,
        label: 'התחברות'
    },
    {
        id: 1,
        label: 'אימות מספר טלפון'
    },
    {
        id: 2,
        label: 'תשלום'
    }];
    let query = Routing.useQuery();

    useEffect(() => {
        if (store.getState().isLoggedIn) {
            if (store.getState().userDetails.telephone && store.getState().userDetails.telephone != "0") {
                createWebCheckoutSession();
                setStep(steps[2]);
                setActiveStep(2);
            } else {
                setStep(steps[1]);
                setActiveStep(1);
            }
        } else {
            setStep(steps[0]);
            setActiveStep(0);
        }
    }, [isLoggedIn]);

    const [step, setStep] = useState(isLoggedIn ? steps[1] : steps[0]);

    // const hasErrors = () => {
    //     if (!telephone || telephone.length == 0) {
    //         setError({ ...error, telephone: true });
    //         return true;
    //     }
    // }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        setStep(steps.filter((step) => step.id === activeStep));

        setSkipped(newSkipped);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <MainContainer>
            <Grid container justifyContent='center'>
                <Grid item xs={12} md={8} sx={{ padding: '0px 50px', marginTop: '20px' }}>
                    <Stepper activeStep={activeStep} sx={{ my: '2rem' }}>
                        {steps.map((step, index) => {
                            const stepProps = {};
                            const labelProps = {};

                            return (
                                <Step key={step.label} {...stepProps}>
                                    <StepLabel {...labelProps}>{step.label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 &&
                            <Login returnToPrev={isLoggedIn} />
                        }

                        {(activeStep === 1 && telephone.length === 0) &&
                            <div>
                                <Typography sx={{
                                    fontSize: theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.fontFamily,
                                    color: theme.palette.black,
                                    textAlign: 'center'
                                }}>
                                    דברים שחשוב לדעת לפני ההרשמה
                                </Typography>

                                <TermDetails details={details} />

                                <Divider variant="fullWidth" sx={{ margin: "1rem 0" }} />

                                <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
                                    <Typography sx={{
                                        fontSize: theme.typography.h5,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.fontFamily,
                                        color: theme.palette.black,
                                        textAlign: 'center',
                                        marginBottom: '1rem'
                                    }}>
                                        אימות מספר טלפון
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '18px',
                                        fontFamily: theme.fontFamily,
                                        textAlign: 'center'
                                    }}>
                                        ניהול הכניסה לאתר לאחר הרכישה היא ע"י סיסמא המועברת אליכם בכל פעם ע"י הודעת SMS לטלפון האישי שלכם
                                        לאחר הרכישה לא יהיה ניתן לשנות את מספר הטלפון
                                    </Typography>
                                    <br />
                                    <InputComponent
                                        label={"טלפון"}
                                        onChange={onChange}
                                        value={state.telephone}
                                        name='telephone'
                                        setError={setError}
                                        error={error}
                                        required={true}
                                        type='number'
                                        fullWidth={true}
                                    />
                                    <br />
                                    <ButtonComponent
                                        spColor={theme.palette.green}
                                        rounded={"true"}
                                        text={"שלח"}
                                        onClick={onClick}
                                    />
                                </Grid>
                            </div>
                        }

                        {(activeStep === 1 && telephone.length > 0) &&
                            <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
                                <Typography sx={{
                                    fontSize: theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.fontFamily,
                                    color: theme.palette.black,
                                    textAlign: 'center',
                                    marginBottom: '1rem'
                                }}>
                                    נשלח אליך קוד אימות לנייד
                                </Typography>
                                <br />
                                <InputComponent
                                    label={"קוד אימות"}
                                    onChange={onChange}
                                    value={state.VerificationCode}
                                    name='VerificationCode'
                                    setError={setError}
                                    error={error}
                                    required={true}
                                    type='string'
                                    fullWidth={true}
                                />
                                <br />
                                <ButtonComponent
                                    spColor={theme.palette.green}
                                    rounded={"true"}
                                    text={"שלח"}
                                    onClick={onVerificateClicked}
                                />
                            </Grid>
                        }

                        {activeStep === 2 &&
                            <div>
                                <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
                                    <Typography sx={{
                                        fontSize: theme.typography.h5,
                                        fontWeight: theme.typography.fontWeightBold,
                                        fontFamily: theme.fontFamily,
                                        color: theme.palette.black,
                                        textAlign: 'center',
                                        marginBottom: '1rem'
                                    }}>
                                        התשלום יכול להתבצע באתר עם כל סוגי כרטיסי האשראי מלבד אמריקן-אקספרס, ברכישה מאובטחת עם הסמכת PCI-DSS Level 1
                                        ניתן גם לשלם ע"י יצירת קשר, העברה בנקאית וביט
                                    </Typography>
                                    <br />
                                    {/* TODO: z-credit form here */}
                                    {paymentUrl.length > 0 &&
                                        <IframeStyle
                                            src={paymentUrl}
                                            width="100%"
                                            style={{ border: "none", overflow: "hidden" }} allow="payment"></IframeStyle>
                                    }
                                </Grid>
                            </div>
                        }

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            {activeStep === steps.length &&
                                <Button onClick={handleNext}>
                                    סיום
                                </Button>
                            }
                        </Box>
                    </React.Fragment>
                )}
                <AlertMessage
                    message={alertMessage}
                    open={alertIsOpen}
                    onClose={handleClose}
                    status={alertStatus}
                />
            </Grid>
        </MainContainer >
    );
}

const IframeStyle = styled("iframe")(({ theme }) => ({
    height: "600px",
    margin: "1rem auto",
    border: "none",
    overflowY: 'hidden',
    [theme.breakpoints.down("md")]: {
        height: "600px",
    },
    [theme.breakpoints.down("sm")]: {
        height: "600px",
    },
}));