import React from "react";
import { Box, Input, styled, Typography, useTheme } from "@mui/material";

export const InputComponent = ({
  error,
  onChange,
  value,
  setError,
  placeHolder,
  fullWidth,
  label,
  name,
  required,
  type,
  multiline,
  rows,
  disabled
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography>{label}</Typography>
      <TextInputStyle
        sx={{ border: error[name] && `1px solid ${theme.palette.error.main}` }}
        multiline={multiline}
        rows={rows}
        placeholder={placeHolder}
        disableUnderline
        autoComplete="on"
        fullWidth={fullWidth}
        type={type}
        onBlur={() =>
          value === "" && required
            ? setError({ ...error, [name]: true })
            : setError({ ...error, [name]: false })
        }
        onChange={onChange}
        error={error[name]}
        id={name}
        aria-describedby="my-helper-text"
        name={name}
        value={value}
        disabled={disabled}
      />
    </Box>
  );
};

const TextInputStyle = styled(Input)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  padding: "0 0.3rem",
}));
