// this is for the Rendering layer control (React Router)

import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "./actions";
import Header from "./components/Header";
import Landing from "./components/Landing";
import About from "./components/About/index";
import Pakal from "./components/Pakal";
import Material from "./components/Material/index";
import Contact from "./components/Contact/index";
import Terms from "./components/Terms/index";
// import Spring2021 from "./Spring2021";
// import SpringMarathon2021 from "./SpringMarathon2021";
// import SummerMarathon2021 from "./SummerMarathon2021";
import Footer from "./components/Footer";
import Lessons from "./components/Lessons";
import Courses from "./components/Courses";
import CoursesBagrut from "./components/CoursesBagrut";
import Authentication from './components/Authentication/index';
// import Login from "./Login";
import Success from "./components/Success";
import Registered from "./components/Registered";
// import Mechanics from "./components/Mechanics";
import withTracker from "./components/withTracker";
// import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/Home";
import { Course } from "./components/Course";
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

// import Course from "./components/Course0";
import { PdfView } from "./components/Files/ViewPdf";

import { Purchasing } from "./components/Purchasing";
import CarouselUtil from "./utils/CarouselUtil";

// require("../css/styles.css");

const App = (props) => {
  // useEffect(() => {
  //   const GAConfig = {
  //     trackingId: "UA-181859912-1",
  //     debug: true,
  //     gaOptions: {
  //       cookieDomain: "none",
  //       //   userId: 123456
  //     },
  //   };
  //   ReactGA.initialize(GAConfig);
  //   props.fetchUser();
  // }, []);

  // useEffect(() => {
  //   if (props.auth && props.auth.id) {
  //     // console.log("ELAD userId", props.auth.id);
  //     ReactGA.addTrackers(
  //       [
  //         {
  //           trackingId: "UA-181859912-1",
  //           gaOptions: {
  //             //   name: 'tracker1',
  //             userId: props.auth?.id,
  //           },
  //         },
  //       ],
  //       { debug: true, alwaysSendToDefaultTracker: false }
  //     );
  //     ReactGA.set({ userId: props.auth?.id });
  //   }
  // }, [props.auth]);

  // const { status, data, error, isFetching, isRefetchError, remove } = useCustomQurey({
  //   queryType: "get",
  //   url: "/users/profile",
  //   queryKey: "fetchUser",
  // });

  return (
    // these div is for the css components
    <div>
      <BrowserRouter>
        <Switch>
          {/* <Route path="/" exact component={withTracker(Landing)}></Route> */}
          <Route path="/" exact component={withTracker(Home)} />
          <Route path="/course" exact component={withTracker(Course)} />
          {/* <Route path="/course/:id" exact component={Course} /> */}

          <Route path={"/login"} exact component={withTracker(Authentication)} />
          <Route path="/signup" exact component={withTracker(Authentication)} />
          <Route path="/forgotPassword" exact component={withTracker(Authentication)} />
          <Route path="/emailCheck" exact component={withTracker(Authentication)} />
          <Route path="/emailSent" exact component={withTracker(Authentication)} />
          <Route path="/success" exact component={withTracker(Authentication)} />

          <Route path="/recommendations" exact component={withTracker(CarouselUtil)} />

          <Route path="/purchase" exact component={Purchasing} />
          <Route path="/about" exact component={withTracker(About)}></Route>
          <Route path="/pakal" exact component={withTracker(Pakal)}></Route>
          <Route path="/material" exact component={withTracker(Material)}></Route>
          <Route path="/lesson" exact component={withTracker(Lessons)}></Route>

          <Route path="/contact" exact component={withTracker(Contact)}></Route>
          <Route path="/courses" exact component={withTracker(Courses)}></Route>
          {/* <Route
            path="/courses/:id/:subtitle1/:subtitle2/:courseTitle"
            exact
            component={withTracker(Course)}></Route>
          <Route */}
          {/* path="/coursesBagrut/:id/:subtitle1/:courseTitle"
            exact
            component={withTracker(Course)}></Route>
          <Route */}
          {/* path="/pakal/:id/:subtitle1/:courseTitle"
            exact
            component={withTracker(Course)}></Route> */}
          <Route path="/coursesBagrut" exact component={withTracker(CoursesBagrut)}></Route>

          <Route path="/terms" exact component={withTracker(Terms)}></Route>

          <Route path="/success" exact component={withTracker(Success)}></Route>
          <Route path="/registered" exact component={withTracker(Registered)}></Route>
          <Route path="/pdfView" exact component={withTracker(PdfView)}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, actions)(App);
