import React, { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";

import List from "./List";
import Payment2 from "./Payment2";
import Payment3 from "./Payment3";
import EmailCheck from "./EmailCheck";

import { Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { BASE_URL } from "../api";
require("../css/styles.css");

const description = [
  // {
  //   id: 1,
  //   type: "writing",
  //   title: "דרישות קדם",
  //   subTitle: "",
  //   explenation:
  //     "למרות שבקורס הנני מזכיר צעדים מתמטיים, הדרישה היא כן לדעת פעולות ברמת תיכון של וקטורים וחקירות פונקציה בסיסיות (כולל נגזרות ואינטגרלים)",
  //   bullet: [],
  // },
  {
    id: 1,
    type: "writing",
    title: "הרכישה עבור משתמש בודד",
    subTitle: "",
    explenation: "רכישת הקורס הינה אישית, אין להעבירו לאף צד שלישי.",
    bullet: [],
  },
  // {
  //   id: 3,
  //   type: "writing",
  //   title: "זמן מוקצב",
  //   subTitle: "",
  //   explenation:
  //     "הרכישה היא עבור זמן מוגבל בלבד. לאחר מכן, באם יש צורך, יש לרכוש את הקורס מחדש",
  //   bullet: [],
  // },
  {
    id: 2,
    type: "writing",
    title: "אין החזר כספי",
    subTitle: "",
    explenation:
      "ההרשמה החינמית לאתר היא בכדי שתוכלו לראות שהסרטונים עובדים מהמחשב והדפדפן שאתם משתמשים בו, ובנוסף לכך לראות באם צורת ההוראה של הקורס מתאימה עבורכם. לאחר הרכישה, אין אפשרות של החזר כספי",
    bullet: [],
  },
  {
    id: 3,
    type: "writing",
    title: "שאילת שאלות",
    subTitle: "",
    explenation:
      "כל שינוי הכי קטן בשאלה יכול לגרום לשינוי מאוד גדול בתשובה, ולכן השאלות צריכות להתייחס ספציפית לתוכן השיעור",
    bullet: [],
  },
  {
    id: 4,
    type: "writing",
    title: "תנאי השימוש",
    subTitle: "",
    explenation: "להמשך רכישת הקורס הינכם מאשרים כי קראתם והסכמתם לתנאי השימוש באתר",
    bullet: [],
  },
];

const Payment = (props) => {
  // console.log(props.title);
  // console.log(props.authName);

  const [page, setPage] = useState("telephone");
  const [request, setRequest] = useState(null);
  const [telephone, setTelephone] = useState(null);

  const onCheckSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    // console.log (formDataObj);

    axios.post(`${BASE_URL}/payment`, formDataObj).then(function (res) {
      // console.log(res.data);
      // setMessage (res.data);

      ReactGA.event({
        category: "Payment",
        action: "submited phone",
        label: res.data,
      });

      if (res.data === "activeFalse") {
        setPage("checkEmail");
      } else if (res.data === "have") {
        setPage("payment");
      } else if (res.data === "err") {
        window.alert("שליחת הודעה נכשלה");
      } else if (res.data.request === undefined) {
        window.alert("מספר טלפון לא תקין");
      } else {
        setRequest(res.data.request);
        setTelephone(res.data.telephone);
        setPage("check");
      }
    });
  };

  if (page === "telephone") {
    return (
      <div className="container">
        <br></br>
        <br></br>
        <h2>דברים חשובים לדעת לפני ההרשמה</h2>

        <hr className="line"></hr>
        <br></br>
        {/* {description.map(List)} */}
        {description.map((el) => (
          <List {...el} key={el.id} />
        ))}
        <br></br>
        <br></br>
        <center>
          <hr className="dot"></hr>
          <br></br>
          <h2>אימות מספר טלפון</h2>
          <br></br>
          <p>
            {" "}
            ניהול הכניסה לאתר לאחר הרכישה היא ע"י סיסמא המועברת אליכם בכל פעם ע"י הודעת אס.אם.אס
            לטלפון האישי שלכם
          </p>
          <p>לאחר הרכישה לא יהיה ניתן לשנות את מספר הטלפון</p>
          <br></br>
          <Form onSubmit={onCheckSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label></Form.Label>
              <Form.Control required name="telephone" placeholder="telephone number" />
            </Form.Group>
            <Button className="butn border" variant="primary" type="submit">
              אישור
            </Button>
          </Form>
        </center>
      </div>
    );
  } else if (page === "check" && telephone != null && request != null) {
    return (
      <div>
        <Payment2
          telephone={telephone}
          request={request}
          authName={props.authName}
          title={props.title}></Payment2>
      </div>
    );
  } else if (page === "payment") {
    return (
      <div>
        <Payment3 authName={props.authName} title={props.title}></Payment3>
      </div>
    );
  } else if (page === "checkEmail") {
    return <EmailCheck></EmailCheck>;
  } else {
    return <h4>טוען...</h4>;
  }
};

export default Payment;
