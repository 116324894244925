import React from 'react';
import {Link} from 'react-router-dom';

require('../css/styles.css');




const Footer = (props) => {
    return (
        <div className="contact">

            <a className = "inline" href="https://www.facebook.com/בית-הפיסיקה-והמתמטיקה-של-עמית-שלמה-physics-math-teacher-Amit-Shlomo-565138127287363"><h5>פייסבוק</h5></a>
            <Link className = "inline" to = '/terms'><h5>תנאי השימוש</h5></Link>
            <a className = "inline" href="https://www.youtube.com/channel/UCmG0jfcjJWeiPoC30XdtaiQ/featured"><h5>ערוץ היוטיוב</h5></a>
            <h6>© {props.date} עמית שלמה הוראת פיסיקה ומתמטיקה. כל הזכויות שמורות</h6>
        </div>
    );
}

export default Footer;
