import React from "react";
import { useTheme } from "@mui/material";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot } from '@mui/lab';
import Typography from "@mui/material/Typography";

export const AboutTimeline = () => {
    const theme = useTheme();

    const style = {
        item: {
            height: 'auto',
            display: 'flex',
            alignItems: 'center'
        },
        year: {
            color: theme.palette.common.black,
            fontSize: theme.typography.h5,
            fontFamily: theme.fontFamily,
            fontWeight: theme.typography.fontWeightBold
        },
        connector: {
            height: '110px',
            display: 'flex',
            alignItems: 'center',
            bgcolor: theme.palette.coral
        },
        dot: {
            bgcolor: theme.palette.lightBlue,
            width: '10px',
            height: '10px',
            padding: '0px',
            margin: '0px'
        }
    }

    return (
        <Timeline position="alternate">
            <TimelineItem sx={style.item}>
                <TimelineOppositeContent sx={style.year}>
                    מיזם של לימודי פיזיקה מקוונים
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={style.connector} />
                    <TimelineDot sx={style.dot} />
                    <TimelineConnector sx={style.connector} />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography sx={{ maxWidth: '250px' }}>
                        הקמת אתר מקוון במטרה להעביר את כל החומר הדרוש בפיזיקה, מאיך צריך ללמוד ועד כיצד להתנהל במבחן האמיתי
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem sx={style.item}>
                <TimelineOppositeContent sx={style.year}>
                    מתרגל בטכניון
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={style.connector} />
                    <TimelineDot sx={style.dot} />
                    <TimelineConnector sx={style.connector} />
                </TimelineSeparator>
                <TimelineContent sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Typography sx={{ maxWidth: '250px' }}>
                        מתרגל מטעם הטכניון בנושאים של מכניקה, חשמל ומגנטיות, חדו"א, קורסי הכנה במתמטיקה לסטודנטים חדשים
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem sx={style.item}>
                <TimelineOppositeContent sx={style.year}>
                    מרצה להשלמת בגרויות
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={style.connector} />
                    <TimelineDot sx={style.dot} />
                    <TimelineConnector sx={style.connector} />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography sx={{ maxWidth: '250px' }}>
                        מרצה לפיזיקה בבית ספר אקסטרני להשלמת 5 יחידות פיזיקה (שאלון מכניקה, חשמל, קרינה וחומר, מעבדה)
                    </Typography>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem sx={style.item}>
                <TimelineOppositeContent sx={style.year}>
                    השכלה
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector sx={style.connector} />
                    <TimelineDot sx={style.dot} />
                    <TimelineConnector sx={style.connector} />
                </TimelineSeparator>
                <TimelineContent sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Typography sx={{ maxWidth: '250px' }}>
                        בוגר תואר ראשון לפיזיקה בטכניון, בוגר תעודת הוראה לפיזיקה בטכניון, סטודנט לתארים מתקדמים בפקולטה לחינוך למדע וטכנולוגיה בטכניון
                    </Typography>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
};