import axios from "axios";
import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { BASE_URL } from "../../api";

export const PdfView = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPDF] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/courses/azureFile`,
        { containerName: "courses", blobName: "מפת אזורים גאו-אקלימיים לתקופת החורף.pdf" },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        console.log(file);
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setPDF(file);
      });
  }, []);
  console.log("pdf", pdf);

  return <div></div>;
};
