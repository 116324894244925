import React from "react";

import { useDispatch } from 'react-redux';
import { useTheme, Grid } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../api";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";
import store from '../../redux/store';

import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

export const ForgotPassword = () => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const history = useHistory();
  const theme = useTheme();
  const [state, setState] = useState({
    username: "",
  });

  const [error, setError] = useState({
    username: false,
  });

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) history.push('/');

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) history.push('/');
  }, [isLoggedIn]);

  const dispatch = useDispatch();

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({ ...state, [name]: value });
  };

  const hasErrors = () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value || value.length == 0) {
        setError({ ...error, [key]: true });
        return true;
      }
    }
  }

  const onClick = () => {
    if (hasErrors()) {
      setAlertMessage("נא למלא את כל השדות.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    if (!validateEmail(state.username)) {
      setAlertMessage("כתובת מייל לא תקינה.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    axios.post(`${BASE_URL}/forgotPassword`, state)
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }
        
        setAlertMessage(res.data.message);
        setAlertStatus('success');
        setAlertIsOpen(true);

        store.dispatch({ type: 'setSecretToken', payload: res.data.secretToken });
        store.dispatch({ type: 'updateUserDetails', payload: { username: res.data.username } });

        setTimeout(() => history.push('/emailCheck'), 2000);
      })
      .catch((err) => {
        console.log(err.message)
        // setAlertMessage('מייל לא תקין או לא קיים במערכת. נא נסו שנית');
        // setAlertIsOpen(true);
      });
  };

  return (
    <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
      <InputComponent
        label={"אימייל"}
        onChange={onChange}
        value={state.username}
        name={"username"}
        setError={setError}
        error={error}
        required={true}
        type={"email"}
        fullWidth={true}
      />
      <br />
      <ButtonComponent
        spColor={theme.palette.green}
        rounded={"true"}
        text={"הכנס"}
        onClick={onClick}
      />
      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </Grid>
  );
};
