import React, { useState } from "react";

import { useTheme } from "@mui/material";

import axios from "axios";
import { BASE_URL } from "../../api";
import { useHistory } from "react-router";

import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";
import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

export const ContactForm = ({ returnToPrev, handleClosePopup }) => {
    // -- Snackbar start --
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const handleClose = () => {
        setAlertIsOpen(false);
    };

    const [alertMessage, setAlertMessage] = useState("");
    const [alertStatus, setAlertStatus] = useState("");
    // -- Snackbar end  --

    const theme = useTheme();
    const history = useHistory();

    const [state, setState] = useState({ email: "", message: "" });
    const [error, setError] = useState({ email: false, message: false });

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState({ ...state, [name]: value });
    };

    const onClick = () => {
        if (!state.email || !state.message) {
            setAlertMessage('יש למלא את כל השדות');
            setAlertStatus('error');
            setAlertIsOpen(true);
            return;
        }

        if (!validateEmail(state.email)) {
            setAlertMessage("כתובת מייל לא תקינה.");
            setAlertStatus('error');
            setAlertIsOpen(true);
            return;
        }

        axios
            .post(`${BASE_URL}/sendEmail`, state)
            .then((res) => {
                setAlertMessage(res.data.message);
                setAlertIsOpen(true);

                if (res.data.error) {
                    setAlertStatus('error');
                    return;
                }

                setAlertStatus('success');
                setState({ email: "", message: "" });
                setTimeout(() => { handleClosePopup() }, 2000);
            })
    };
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <InputComponent
                label={"אימייל"}
                onChange={onChange}
                value={state.email}
                name={"email"}
                setError={setError}
                error={error}
                required={true}
                type={"email"}
                fullWidth={true}
            />
            <br />
            <InputComponent
                label={"הודעה"}
                onChange={onChange}
                value={state.message}
                name={"message"}
                setError={setError}
                error={error}
                required={true}
                type={"textarea"}
                fullWidth={true}
                multiline={true}
                rows={'4'}
            />
            <br />
            <ButtonComponent
                type="button"
                spColor={theme.palette.coral}
                rounded={"true"}
                text={"שלח"}
                onClick={onClick}
            />
            <AlertMessage
                message={alertMessage}
                open={alertIsOpen}
                onClose={handleClose}
                status={alertStatus}
            />
        </div>
    );
};
