import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState, useEffect } from "react";
import { axiosInstance, BASE_URL } from "../../api";
import axios from "axios";

import { Box, FormControl } from "@mui/material";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { AlertMessage } from "../../utils/AlertMessage";
import { InputComponent } from "../../utils/InputComponent";
import getCommentsAndReplies from '../../utils/Forum';
import store from '../../redux/store';

export const CommentInput = ({ label, setLabel, lessonId, isreply, commentId, auth }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [error, setError] = useState(false);
  const editorRef = useRef(null);

  const handleClose = () => {
    setAlertIsOpen(false);
  };
  const handelChange = (e) => {
    setLabel(e.target.value);
  };

  const imageUploader = async (blobInfo, success, failure) => {
    const blob = await blobInfo.blob();

    const formData = new FormData();
    formData.append("file", blob);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    await axiosInstance.post(`/comments/uploadFileToAzure`, formData, config).then((res) => {
      try {
        success(res.data);
      } catch (err) {
        failure("Error");
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (auth?.includes(lessonId)) {
      if (editorRef.current) {
        const richText = editorRef.current.getContent();
        if (!label) {
          //requierd
        } else {
          let data = {};
          switch (isreply) {
            case true:
              data = {
                text: richText,
                label: label,
                lessonId: lessonId,
                userId: store.getState().userDetails.id,
                parentCommentId: commentId,
              };

              axios
                .post(`${BASE_URL}/comments/reply`, data)
                .then(() => {
                  setLabel("");
                  editorRef.current.setContent("");
                  getCommentsAndReplies(lessonId);
                }).catch((err) => {
                  console.log('comment error - ', err);
                });
              break;
            case false:
              data = {
                text: richText,
                label: label,
                lessonId: lessonId,
                userId: store.getState().userDetails.id
              };

              axios
                .post(`${BASE_URL}/comments`, data)
                .then(() => {
                  setLabel("");
                  editorRef.current.setContent("");
                  getCommentsAndReplies(lessonId);
                }).catch((err) => {
                  console.log('reply error - ', err);
                });
              break;
            default:
              break;
          }
        }
      }
    } else {
      setAlertIsOpen(true);
    }
  };
  const defaultInit = {
    height: 200,
    block_formats: "פסקה=p; כותרת 3=h3; כותרת 4=h4; כותרת 5=h5; כותרת 6=h6;",
    toolbar:
      "formatselect | bold italic underline | forecolor backcolor | bullist numlist | redo undo | cut copy paste | selectall | charmap | link image |rtl ltr",
    directionality: "rtl",
    menubar: false,
    width: "100%",
    language: "he_IL",
    plugins: ["directionality advlist lists charmap anchor paste image code"],
    forced_root_block: "div",
    file_picker_types: "image file",
    automatic_uploads: true,
  };

  return (
    <div>
      <AlertMessage
        message={store.getState().isLoggedIn ? "ניתן להגיב לאחר רכישת הקורס" : "ניתן להגיב לאחר התחברות ורכישת הקורס"}
        open={alertIsOpen}
        onClose={handleClose}
        status={'error'}
      />

      <Box sx={{ margin: "0 1.5rem 0 0" }}>
        <FormControl fullWidth sx={{ margin: "0.3rem 0 " }}>
          <InputComponent
            error={error}
            onChange={handelChange}
            state={label}
            setError={setError}
            fullWidth={true}
            label={"כותרת"}
          />
        </FormControl>

        <Editor
          init={{
            ...defaultInit,
            images_upload_url: `${BASE_URL}/comments/uploadFileToAzure`,
            images_upload_handler: imageUploader,
          }}
          tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
          textareaName="comment"
          onInit={(evt, editor) => (editorRef.current = editor)}
        />
        <ButtonComponent
          rounded={"false"}
          margin={"1rem 0 0 0"}
          text={" שלח תגובה"}
          disabled={label.length === 0}
          onClick={handleSubmit}
          spColor={"black"}
        />
      </Box>
    </div>
  );
};
