import axios from "axios";
import { FETCH_USER } from "./types";
import { BASE_URL } from "../api/index";
export const fetchUser = () => async (dispatch) => {
  const res = await axios.get(`${BASE_URL}/current_user`);
  // if (process.env.NODE_ENV === 'production')
  console.log('res', res);
  dispatch({ type: FETCH_USER, payload: res.data });
};

// export const submitEmail = () => async dispatch => {
//     console.log('got here');
//     // const res = await axios.post('/api/sendEmail', values);
//     const res = await axios.post('/api/sendEmail');
//     // this is to go to the backend, to the api/surveys file and send there the values from the frontend

//     // history.push('/');
//     // this is where I want to navigate to lesson 178
//     // when the request is completed, we want to update whatever we want with "dispatch"
// };
