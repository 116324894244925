import React, { useEffect } from "react";

import { useTheme, Link, Grid } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../api";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";

import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

import Auth from '../../hooks/Auth';
import store from '../../redux/store';

export const Login = ({ returnToPrev }) => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const pathname = window.location.pathname;

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);

  const theme = useTheme();
  const [state, setState] = useState({ username: "", password: "", code: "" });
  const [error, setError] = useState({ username: false, password: false, code: false });
  const [request, setRequest] = useState("");
  const [isUserValid, setIsUserValid] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const token = Auth.getStoredToken();

    if (token)
      Auth.setTokenToAxiosHeaders(token);

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn)
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (pathname !== '/purchase' && isLoggedIn) history.goBack();
  }, [isLoggedIn]);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setState({ ...state, [name]: value });
  };

  const hasErrors = () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value || value.length == 0) {
        setError({ ...error, [key]: true });
        return true;
      }
    }
  }

  const onValidateEmailClick = () => {
    if (!state.username || state.username.length == 0) {
      setAlertMessage("נא להזין כתובת אימייל.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    if (!validateEmail(state.username)) {
      setAlertMessage("כתובת מייל לא תקינה.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    axios
      .post(`${BASE_URL}/login/validateUser`, { username: state.username })
      .then((res) => {
        if (res.data.error) {
          setAlertMessage(res.data.message);
          setAlertStatus('error');
          setAlertIsOpen(true);
          return;
        }

        setIsUserValid(true);

        if (res.data.request) {
          setRequest(res.data.request);
        }
      }).catch((err) => {
        setAlertMessage(err.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
      });
  };

  const onVerificateClick = () => {
    // if (hasErrors()) {
    //   setAlertMessage("נא למלא את כל השדות.");
    // setAlertStatus('error');
    //   setAlertIsOpen(true);
    //   return;
    // }

    let loginData = {
      email: state.username,
      password: state.password,
      request_id: request,
      code: state.code
    }

    axios.post(`${BASE_URL}/login`, loginData).then(function (res) {
      if (res.data.error) {
        if (res.data.message == "A wrong code was provided too many times. Workflow terminated") {
          res.data.message = "הוזן קוד שגוי יותר מדיי פעמים. נא להתחבר בשנית";
          history.push("/login");
          return;
        } else if (res.data.message == "The code provided does not match the expected value")
          res.data.message = "הקוד שהוזן שגוי. נא נסו שנית";
        else if (res.data.message == 'עקב עדכונים באתר, יש לבצע שחזור סיסמה.')
          setTimeout(() => { history.push(`${res.data.redirectUrl}`) }, 3000);

        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      if (res.data == "activeFalse") {
        setAlertMessage("הקוד שהוזן שגוי. נא נסו שנית");
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }
      
      Auth.setTokenToAxiosHeaders(res.data.token);

      let userData = {
        id: res.data.user._id,
        firstName: res.data.user.firstName,
        lastName: res.data.user.lastName,
        email: res.data.user.email,
        username: res.data.user.email,
        auth: res.data.user.auth,
        active: res.data.user.active,
        telephone: res.data.user.telephone
      }

      store.dispatch({ type: 'updateUserDetails', payload: userData });

      let data = {
        token: res.data.token,
        expiry: (new Date()).getTime() + (1440 * 60 * 1000)
      };

      localStorage.setItem("amitPhysics", JSON.stringify(data));

      store.dispatch({ type: 'updateIsLoggedIn', payload: true });

      // if (store.getState().secretToken) {
      //   store.dispatch({ type: 'setSecretToken', payload: null });
      // }
      if (pathname !== '/purchase')
        history.push('/');
    });
  };

  return (
    <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
      {!isUserValid &&
        <div>
          <InputComponent
            label={"אימייל"}
            onChange={onChange}
            value={state.username}
            name={"username"}
            setError={setError}
            error={error}
            required={true}
            type={"email"}
            fullWidth={true}
          />
          <br />
          <ButtonComponent
            spColor={theme.palette.green}
            rounded={"true"}
            text={"התחבר"}
            onClick={onValidateEmailClick}
          />
        </div>}<br /><br />
      <div>
        {isUserValid &&
          <InputComponent
            label={"סיסמה"}
            onChange={onChange}
            value={state.password}
            name={"password"}
            setError={setError}
            error={error}
            required={true}
            type={"password"}
            fullWidth={true}
          />}
        <br />
        {request &&
          <InputComponent
            label={"קוד אימות"}
            onChange={onChange}
            value={state.code}
            name='code'
            setError={setError}
            error={error}
            required={true}
            type='string'
            fullWidth={true}
          />}
        <br />
        {isUserValid &&
          <ButtonComponent
            spColor={theme.palette.green}
            rounded={"true"}
            text={"שלח"}
            onClick={onVerificateClick}
          />}<br /><br />
      </div>
      <Link
        component="button"
        variant="body2"
        onClick={() => { history.push("/signup"); }}
        sx={{ direction: 'rtl' }}>
        אין לי חשבון, להרשמה.
      </Link>
      <br />
      <Link
        component="button"
        variant="body2"
        onClick={() => { history.push("/forgotPassword"); }}
        sx={{ direction: 'rtl' }}>
        שכחתי סיסמה
      </Link>
      <br />
      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </Grid>
  );
};
