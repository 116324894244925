import React, { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";

import Payment4 from "./Payment4";
import { Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { BASE_URL } from "../api";
import { useParams } from "react-router-dom";
require("../css/styles.css");

const Payment3 = (props) => {
  console.log(props);
  const { id } = useParams();
  const [page, setPage] = useState(null);
  const [url, setUrl] = useState(null);

  const onCheckSubmit = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Payment3",
      action: "subPayment",
    });

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    // console.log (formDataObj);

    axios.post(`${BASE_URL}/payment3`, formDataObj).then(function (res) {
      // console.log("url", res.data);
      // setMessage (res.data);
      if (res.data.message === "ok") {
        setUrl(res.data.url);
        setPage("payment");
      } else {
        window.alert("אירעה תקלה, אנא נסה שוב");
      }
    });
  };

  if (page === null) {
    // console.log(props.authName, "page name");
    if (props.authName != "104") {
      return (
        <div>
          <Row>
            <Col xs={12} md={4} lg={4} className="color2">
              <center>
                <h4>אימות טלפון</h4>
              </center>
            </Col>
            <Col xs={12} md={4} lg={4} className="color1">
              <center>
                <h4>פרטים אישיים</h4>
              </center>
            </Col>
            <Col xs={12} md={4} lg={4} className="color2">
              <center>
                <h4>תשלום</h4>
              </center>
            </Col>
          </Row>

          <div className="container">
            <br></br>
            <br></br>
            <p>
              התשלום יכול להתבצע באתר עם כל סוגי כרטיסי האשראי מלבד אמריקן-אקספרס, ברכישה מאובטחת עם
              הסמכת PCI-DSS Level 1
            </p>
            <p>ניתן גם לשלם ע"י יצירת קשר, העברה בנקאית, ביט ופייבוקס</p>
            <br></br>
            <br></br>

            <Form onSubmit={onCheckSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="hidden" value={props.title} name="product" placeholder="code" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="hidden" value={parseInt(id)} name="num" placeholder="code" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="hidden" value={"200"} name="price" placeholder="code" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <p>שם מלא של בעל כרטיס האשראי</p>
                </Form.Label>
                <Form.Control required name="CreditName" placeholder="שם מלא" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <p>ת.ז. של בעל הכרטיס</p>
                </Form.Label>
                <Form.Control required name="Identity" placeholder="ת.ז." />
              </Form.Group>
              <Button className="butn border" variant="primary" type="submit">
                אישור
              </Button>
            </Form>
          </div>
          <br></br>
          <br></br>
        </div>
      );
    } else {
      return (
        <div>
          <Row>
            <Col xs={12} md={4} lg={4} className="color2">
              <center>
                <h4>אימות טלפון</h4>
              </center>
            </Col>
            <Col xs={12} md={4} lg={4} className="color1">
              <center>
                <h4>פרטים אישיים</h4>
              </center>
            </Col>
            <Col xs={12} md={4} lg={4} className="color2">
              <center>
                <h4>תשלום</h4>
              </center>
            </Col>
          </Row>

          <div className="container">
            <br></br>
            <br></br>
            <p>
              התשלום יכול להתבצע באתר עם כל סוגי כרטיסי האשראי מלבד אמריקן-אקספרס, ברכישה מאובטחת עם
              הסמכת PCI-DSS Level 1
            </p>
            <p>ניתן גם לשלם ע"י יצירת קשר, העברה בנקאית, ביט ופייבוקס</p>
            <br></br>
            <br></br>

            <Form onSubmit={onCheckSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="hidden" value={props.title} name="product" placeholder="code" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label></Form.Label>
                <Form.Control type="hidden" value={props.authName} name="num" placeholder="code" />
              </Form.Group>
              {/* <Form.Group controlId="formBasicEmail">
                                <Form.Label></Form.Label>
                                <Form.Select aria-label="Default select example">
                                    <option>בחרו חבילה</option>
                                    <option value="199" name = "price">חודש (199 ש"ח)</option>
                                    <option value="430" name = "price">6 חודשים (430 ש"ח)</option>
                                    <option value="580" name = "price">שנה (580 ש"ח)</option>
                                </Form.Select>
                            </Form.Group> */}

              <Form.Group controlId="formBasicSelect">
                <Form.Label>בחר חבילה</Form.Label>
                <Form.Control
                  as="select"
                  name="price"
                  // value={type}
                  // onChange={e => {
                  //     console.log("e.target.value", e.target.value);
                  //     setType(e.target.value);
                  // }}
                >
                  <option value="199">חודש (199 ש"ח)</option>
                  <option value="430">6 חודשים (430 ש"ח)</option>
                  <option value="580">שנה (580 ש"ח)</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <p>שם מלא של בעל כרטיס האשראי</p>
                </Form.Label>
                <Form.Control required name="CreditName" placeholder="שם מלא" />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  <p>ת.ז. של בעל הכרטיס</p>
                </Form.Label>
                <Form.Control required name="Identity" placeholder="ת.ז." />
              </Form.Group>
              <Button className="butn border" variant="primary" type="submit">
                אישור
              </Button>
            </Form>
          </div>
          <br></br>
          <br></br>
        </div>
      );
    }
  } else if (page === "payment" && url != null) {
    return (
      <div>
        <Payment4 url={url}></Payment4>
      </div>
    );
  }
};

export default Payment3;
