import React from "react";

import { CardMedia, Container, Grid, styled, useTheme, Typography } from "@mui/material";

export const SubHeader = ({ title, body, video }) => { 
  const theme = useTheme();
  return (
    <Container>
      <Grid container>
        <TextGrid item xs={12} md={10} sx={{ margin: "5rem auto 1rem auto" }}>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              lineHeight: "1",
              fontWeight: "bold",
              fontSize: "30px",
              fontFamily: theme.fontFamily,
              textAlign: 'center',
              color: theme.palette.black,
            }}>
            {title}
          </Typography>

          {body &&
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: "20px",
                fontFamily: theme.fontFamily,
                margin: "2rem 0 0 0",
                textAlign: 'center'
              }}>
              {body}
            </Typography>
          }
        </TextGrid>

        {video &&
        <VideoGrid item xs={12} md={6} sx={{margin: '1rem auto'}}>
        <CardMedia
          component={"iframe"}
          image={video}></CardMedia>
      </VideoGrid>
      }
      </Grid>
    </Container>
  );
};
const TextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: "1rem 0 2rem 0",
    "& .MuiTypography-root": {
      textAlign: "center",
    },
  },
}));
const VideoGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));
