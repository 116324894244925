import React from "react";

import { useTheme, Link, Grid } from "@mui/material";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../api";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";
import store from '../../redux/store';

import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

export const SignUp = () => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const theme = useTheme();
  const history = useHistory();

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) history.goBack();

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) history.goBack();
  }, [isLoggedIn]);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    // passwordVerification: "",
    termsAccepted: false
  });
  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    username: false,
    password: false,
    // passwordVerification: false,
    termsAccepted: false
  });

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({ ...state, [name]: value });
  };

  const onCheck = (e, bool) => {
    const name = e.target.name;
    const value = !bool;

    if (!bool) {
      setError({ ...error, termsAccepted: false });
    }

    setState({ ...state, [name]: value });
  }

  const onClick = () => {
    // if (state.password !== state.passwordVerification) {
    //   setError({ ...state, password: true, passwordVerification: true });
    // } else {
    //   setError({ ...state, password: false, passwordVerification: false });
    // }

    if (hasErrors()) {
      setAlertMessage('נא למלא את כל השדות.');
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    if (!validateEmail(state.username)) {
      setAlertMessage("כתובת מייל לא תקינה.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    if (!state.termsAccepted) {
      setError({ ...error, termsAccepted: true });
      setAlertMessage('יש להסכים לתנאי השימוש.');
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    axios.post(`${BASE_URL}/signup`, state).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      setAlertMessage("נשלח לינק אימות לכתובת המייל שלך");
      setAlertStatus('success');
      setAlertIsOpen(true);

      setTimeout(() => history.push(res.data.redirectUrl), 2000);
    }).catch((err) => {
      setAlertMessage(err.message);
      setAlertStatus('error');
      setAlertIsOpen(true);
    });
  };

  const hasErrors = () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value || value.length == 0) {
        setError({ ...error, [key]: true });
        return true;
      }
    }
  }

  return (
    <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
      <InputComponent
        label={"שם"}
        onChange={onChange}
        value={state.firstName}
        name={"firstName"}
        setError={setError}
        error={error}
        required={true}
        type={"text"}
        fullWidth={true}
      />
      <br />
      <InputComponent
        label={"משפחה"}
        onChange={onChange}
        value={state.lastName}
        name={"lastName"}
        setError={setError}
        error={error}
        required={true}
        type={"text"}
        fullWidth={true}
      />
      <br />
      <InputComponent
        label={"אימייל"}
        onChange={onChange}
        value={state.username}
        name={"username"}
        setError={setError}
        error={error}
        required={true}
        type={"email"}
        fullWidth={true}
      />
      <br />
      <InputComponent
        label={"סיסמה"}
        onChange={onChange}
        value={state.password}
        name={"password"}
        setError={setError}
        error={error}
        required={true}
        type={"password"}
        fullWidth={true}
      />
      <br />
      {/* <InputComponent
        label={"חזור על הסיסמה"}
        onChange={onChange}
        value={state.passwordVerification}
        name={"passwordVerification"}
        setError={setError}
        error={error}
        required={true}
        type={"password"}
      /> */}
      <label className="mb-4">
        <input type="checkbox"
          name="termsAccepted"
          checked={state.termsAccepted}
          onChange={($event) => onCheck($event, state.termsAccepted)}
          required="required"
          style={{ marginLeft: '10px' }} />
        <span style={error.termsAccepted ? { color: 'red' } : { color: 'black' }}>
          קראתי והסכמתי לתנאי השימוש
        </span>
      </label>
      <br />
      <ButtonComponent
        spColor={theme.palette.green}
        rounded={"true"}
        text={"הרשם"}
        onClick={onClick}
      /><br /><br />
      <Link
        component="button"
        variant="body2"
        onClick={() => { history.push("/login"); }}
        sx={{ direction: 'rtl' }}>
        יש לי חשבון, להתחברות.
      </Link>
      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </Grid>
  );
};
