import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from "@mui/material/styles";
import store from '../redux/store';

import ManStudying from '../assets/man-studying.svg';
import Popup from '../utils/Popup';

import { List, ListItem, ListItemText, Modal, Tooltip, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

export const CourseCard = ({ color, courseTitle, popUpMainTitle, popUpSubTitle, isShowForm, price, id, bullets, period }) => {
  const history = useHistory();
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (price)
      store.dispatch({ type: 'setPrice', payload: price });
  }, []);

  const setMechanicsCourse = () => {
    store.dispatch({ type: 'setPrice', payload: price });
    localStorage.setItem('period', period);
    history.push("/purchase?courseid=" + id);
  }

  const setButton = (id) => {
    switch (id) {
      case 'private_lesson':
        return (
          <Tooltip title="צור קשר" placement="bottom">
            <CardButton spcolor={color} onClick={handleClickOpen} sx={{ marginBottom: '1rem' }}>
              צור קשר
            </CardButton>
          </Tooltip>
        )
      case '104':
        return (
          <Tooltip title="רכישת הקורס" placement="bottom">
            <CardButton spcolor={color} onClick={setMechanicsCourse} sx={{ marginBottom: '1rem' }}>
              לרכישה
            </CardButton>
          </Tooltip>
        )
      default:
        return (
          <Tooltip title="צפייה בקורס" placement="bottom">
            <CardButton spcolor={color} onClick={() => history.push("/course?courseid=" + id, { courseTitle })} sx={{ marginBottom: '1rem' }}>
              צפייה
            </CardButton>
          </Tooltip>)
    }
  }

  return (
    <CardContainer>
      <Popup open={open} handleClose={handleClose} svg={ManStudying} mainTitle={popUpMainTitle} title={courseTitle} subtitle={popUpSubTitle} isShowForm={isShowForm} />

      <Card variant="outlined" sx={{ marginTop: { xs: '2rem' } }}>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
            minHeight: '270px'
          }}>
          <Typography sx={{ fontSize: theme.fontSize.h3, fontFamily: theme.fontFamily, lineHeight: '2rem' }} gutterBottom>
            {courseTitle}
          </Typography>

          {(window.location.pathname == '/course' && price) &&
            <CostContainer>
              <Typography sx={{ fontSize: theme.fontSize.h4, fontFamily: theme.fontFamily }} gutterBottom>
                {price}
              </Typography>
              <Typography sx={{ fontSize: theme.fontSize.p, fontFamily: theme.fontFamily }} gutterBottom>
                ₪
              </Typography>
            </CostContainer>
          }

          {(price && price.length == 1) &&
            <CostContainer>
              <Typography sx={{ fontSize: theme.fontSize.h4, fontFamily: theme.fontFamily }} gutterBottom>
                {price}
              </Typography>
              <Typography sx={{ fontSize: theme.fontSize.p, fontFamily: theme.fontFamily }} gutterBottom>
                ₪
              </Typography>
            </CostContainer>
          }
          
          {(price && price.length >= 2) &&
            <CostContainer>
              <Typography sx={{ fontSize: theme.fontSize.h4, fontFamily: theme.fontFamily }} gutterBottom>
                {price[0]} - {price[price.length - 1]}
              </Typography>
              <Typography sx={{ fontSize: theme.fontSize.p, fontFamily: theme.fontFamily }} gutterBottom>
                ₪
              </Typography>
            </CostContainer>
          }
          {bullets &&
            <List>
              {bullets.map((bullet, j) => {
                return (
                  <ListItem key={j} sx={{
                    textAlign: 'right', fontWeight: theme.typography.fontWeightRegular, fontSize: theme.fontSize.p,
                    fontFamily: theme.fontFamily, lineHeight: '16px'
                  }}><CircleIcon sx={{ fontSize: "8px", color: color, marginLeft: '8px' }} /> {bullet}</ListItem>
                )
              })}
            </List>
          }
        </CardContent>
        <CardActionsStyle>
          {setButton(id)}
        </CardActionsStyle>
      </Card>
    </CardContainer>
  );
};

const ListItemStyle = styled(ListItemText)({
  fontFamily: "Assistant",
});
const CostContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row",
  alignItems: "center",
});
const CardContainer = styled(Box)({
  maxWidth: "100%",
  maxHeight: "20rem",
});
const CardActionsStyle = styled(CardActions)({
  display: "flex",
  justifyContent: "center",
});
const CardButton = styled(Button)((props) => ({
  color: "white",
  backgroundColor: props.spcolor,
  border: "1px solid white",
  fontFamily: "Assistant",

  "&:hover": {
    backgroundColor: "unset",
    border: "1px solid " + props.spcolor,
    color: props.spcolor,
  },
  fontSize: '1.2rem',
  padding: "0.2rem 3rem",
  borderRadius: "3rem",
}));
