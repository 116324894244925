import React, { useState } from "react";
import Mechanics from "./Mechanics";

import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
require("../css/styles.css");

const Bullets = (props) => {
  const [page, setPage] = useState("list");

  // const numbers = props.numbers;
  // const listItems = p.map((number) =>
  //     <li>{number}</li>
  // );
  // return (
  //      <ul>{listItems}</ul>
  //  );

  if (props.bullet2 === undefined) {
    return (
      <div>
        <ol className="list">
          <li>
            <p>{props.bullet}</p>
          </li>
        </ol>
      </div>
    );
  } else {
    // const onCheckSubmit = (e) => {
    //     e.preventDefault();
    //     setPage("course");
    // };

    if (page === "list") {
      return (
        <div>
          <Row>
            <Col xs={5} md={6} lg={5}>
              <h4
                onClick={(e) => {
                  setPage("course");
                }}>
                {/* <ChevronLeftIcon></ChevronLeftIcon> */}
                {props.bullet1}
              </h4>
            </Col>
            <Col xs={6} md={6} lg={7}>
              <p>{props.bullet2}</p>
            </Col>
          </Row>

          <br></br>
        </div>
      );
    } else if (page === "course") {
      return (
        <div>
          <Mechanics chapters={props.chapters}></Mechanics>
        </div>
      );
    }
  }
};

export default Bullets;
