import React, { useEffect, useRef } from "react";
// import { MdOutlineComment, MdOutlineAddComment } from "react-icons/md";
import { Box } from "@mui/system";
import { Avatar, useTheme, Container, Typography, styled } from "@mui/material";
import { AddComment, Forum } from "@mui/icons-material";

export const CommentCard = ({
  isVisible,
  setIsVisible,
  isreply,
  label,
  text,
  replies,
  userName,
}) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = text;
    }
  }, [divRef, isVisible]);
  const theme = useTheme();
  return (
    <CommentContainer>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", margin: "0 0 0 1rem" }}>
          <Avatar
            sx={{
              bgcolor: theme.palette.common.black,
              fontSize: theme.typography.fontSize,
              padding: "1.5rem",
            }}
            src="/static/images/avatar/1.jpg">
            {userName?.firstName?.substring(0, 1).toUpperCase() +
              userName?.lastName?.substring(0, 1).toUpperCase()}
          </Avatar>
        </Box>

        <Box>
          <Typography
            sx={{
              fontFamily: theme.fontFamily,
              fontWeight: theme.typography.fontWeightBold,
              fontSize: "18px",
            }}>
            {label}
          </Typography>
          <div>
            <div role="list" ref={divRef}></div>
          </div>
          <Box sx={{ fontWeight: "lighter", fontFamily: theme.fontFamily, margin: "0.3rem 0 0 0" }}>
            {userName?.firstName + " " + userName?.lastName}
          </Box>
        </Box>
      </Box>
      {!isreply && replies && (
        <Box>
          {replies.length > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Box sx={{ margin: "0 0 0 0.2rem" }}>{replies.length}</Box>
              <IconeWarper onClick={() => setIsVisible(!isVisible)}>
                <Forum />
              </IconeWarper>
            </Box>
          ) : (
            <IconeWarper onClick={() => setIsVisible(!isVisible)}>
              <AddComment />
            </IconeWarper>
          )}
        </Box>
      )}
    </CommentContainer>
  );
};

const IconeWarper = styled(Box)(({ theme }) => ({
  color: theme.palette.grey["500"],
  ":hover": { color: theme.palette.common.black },
  padding: "0",
  cursor: "pointer",
}));

const CommentContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: " space-between",
  padding: "1rem",
  ":hover": {
    backgroundColor: theme.palette.grey["100"],
  },
}));
