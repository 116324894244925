import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useTheme, Grid } from "@mui/material";
import axios from "axios";
import store from '../../redux/store';
import { BASE_URL } from "../../api";
import { ButtonComponent } from "../../utils/ButtonComponent";
import { InputComponent } from "../../utils/InputComponent";

import { AlertMessage } from "../../utils/AlertMessage";
import validateEmail from '../../utils/Validators';

import { useHistory } from "react-router-dom";

export const EmailCheck = () => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");

  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) history.push('/');

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn || !userDetails.username) history.push('/');
  }, [isLoggedIn]);

  const history = useHistory();
  const theme = useTheme();
  const [state, setState] = useState({
    secretToken: "",
    password: ""
  });

  const [secretToken, setSecretToken] = useState(store.getState().secretToken);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);

  const [error, setError] = useState({
    secretToken: false,
    password: false
  });

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setSecretToken(store.getState().secretToken);
      setUserDetails(store.getState().userDetails);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({ ...state, [name]: value });
  };

  const hasErrors = () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value || value.length == 0) {
        setError({ ...error, [key]: true });
        return true;
      }
    }
  }

  const onClick = () => {
    if (hasErrors()) {
      setAlertMessage("נא למלא את כל השדות.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    if (!validateEmail(userDetails.username)) {
      setAlertMessage("כתובת מייל לא תקינה.");
      setAlertStatus('error');
      setAlertIsOpen(true);
      return;
    }

    axios.post(`${BASE_URL}/passwordChange/check2`, { secretToken: state.secretToken, user: { secretToken: secretToken, username: userDetails.username } }).then(function (res) {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      if (res.data === "success") {
        const data = {
          username: userDetails.username,
          password: state.password,
          secretToken: state.secretToken
        }

        axios.post(`${BASE_URL}/passwordChange`, data).then(function (res) {
          if (res.data.error) {
            setAlertMessage(res.data.message);
            setAlertStatus('error');
            setAlertIsOpen(true);
            return;
          }

          if (res.data === "success") {
            setAlertMessage("שינוי סיסמא התבצע בהצלחה!");
            setAlertStatus('success');
            setAlertIsOpen(true);

            setTimeout(() => history.push('/login'), 2000);
          } else {
            setAlertMessage(res.data);
            setAlertStatus('error');
            setAlertIsOpen(true);
          }
        });
      } else {
        setAlertMessage(res.data);
        setAlertStatus('error');
        setAlertIsOpen(true);
      }
    });
  };

  return (
    <Grid item xs={12} md={6} sx={{ padding: '0px 50px', margin: '20px auto 0px auto' }}>
      <InputComponent
        label={"קוד הנשלח לאימייל"}
        onChange={onChange}
        value={state.secretToken}
        name={"secretToken"}
        setError={setError}
        error={error}
        required={true}
        type={"text"}
        fullWidth={true}
      />
      <br />
      <InputComponent
        label={"סיסמה חדשה"}
        onChange={onChange}
        value={state.password}
        name={"password"}
        setError={setError}
        error={error}
        required={true}
        type={"password"}
        fullWidth={true}
      />
      <br />
      <ButtonComponent
        spColor={theme.palette.green}
        rounded={"true"}
        text={"אישור"}
        onClick={onClick}
      />

      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </Grid>
  );
};
