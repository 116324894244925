import React from "react";

import { Divider, IconButton, Link, Tooltip } from "@mui/material";
import { FacebookOutlined, YouTube, Gavel } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";

export const Footer = () => {
  const year = new Date().getFullYear();
  const history = useHistory();

  return (
    <Box>
      <Divider variant="fullWidth" sx={{ margin: " 0.5rem 0" }} />
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Link target='blank' href="https://www.facebook.com/%D7%A2%D7%9E%D7%99%D7%AA-%D7%9C%D7%A4%D7%99%D7%A1%D7%99%D7%A7%D7%94-Physics-Tutor-565138127287363/">
          <Tooltip title="פייסבוק" placement="bottom-end">
            <IconButton>
              <FacebookOutlined />
            </IconButton>
          </Tooltip>
        </Link>

        <Link target='blank' href="https://www.youtube.com/channel/UCmG0jfcjJWeiPoC30XdtaiQ">
          <Tooltip title="יוטיוב" placement="bottom-end">
            <IconButton>
              <YouTube />
            </IconButton>
          </Tooltip>
        </Link>

        <Box onClick={() => { history.push('/terms') }}>
          <Tooltip title="תנאים" placement="bottom-end">
            <IconButton>
              <Gavel />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: " 0  0 0.1rem 0",
          fontWeight: "100",
          textAlign: "center",
        }}>
        © {year} כל הזכויות שמורות עמית שלמה הוראת פיסיקה ומתמטיקה.
      </Box>
    </Box>
  );
};
