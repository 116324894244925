import React from 'react';
import List from './List';



const description = [
    {
        id: 1,
        type: "writing",
        title: "למידה אקטיבית",
        subTitle: "",
        explenation: 'הקורס בנוי בפלטפורמה שנותן לכם אפשרות להשתתף בלמידה אקטיבית. למרות שמצד אחד זה מאוד חשוב שתנסו לפתור בעצמכם את התרגילים, זה גם חשוב לא לבזבז זמן. כלומר, צריך לשים לב לא לעבור את ה 20 דקות ניסיון פתירה אישי.  אסביר זאת יותר לעומק, התרגילים שאני נותן יתכווננו כך שהם יחסית לא יהיו קלים, אבל גם לא יהיו קשים מדי עד כדי בלתי פתירים.      אספר עוד משהו, אני מניח לעצמי שאת רוב התרגילים לא תצליחו לפתור עד הסוף, אבל – אם ניסיתם בעצמכם ללא הצלחה, ולאחר מכן התבוננתם בפתרון שלי, והצלחתם להבין אותי ולהבין את הפתרון – אז קיבלתם בדיוק את מה שהייתם צריכים!    זה אולי נשמע כמו סתירה פנימית. מצד אחד אני מדגיש את החשיבות של ניסיון פתירה אישי, מצד שני, אני מניח לעצמי שלא תצליחו לפתור עד הסוף. זכרו, אתם בשלבי למידה. אני לא מצפה שתצליחו לפתור את כל התרגילים, אני רק רוצה שתתנסו, תחוו ותרגישו בעצמכם לפני שאתם רואים אותי פותר, וזה יגרום לכך שההבנה שלכם תיהיה הרבה יותר עמוקה. שיעורי התרגול מחולקים לשלושה שלבים:',
        bullet: [
            {
                id: 1,
                bullet: 'השלב הראשון הוא ניסיון המענה שלכם. זהו הזמן שלכם לנסות לבד לפתור את התרגיל, והוא שלב מאוד קריטי, כדי שתוכלו "להיכנס" לתוך השאלה, לראות מאיזה זווית ראייה תרצו לנסות לפתור אותו, ואף לחוות קשיים בפתרון (מה שהרבה פעמים לא חווים באם רק מתבוננים במתרגלים פותרים). יש לנסות ולהגיע לפתרון הסופי, ויש להקדיש לכך בין 5-20 דקות.',
            },
            {
                id: 2,
                bullet: 'השלב השני הוא רמז שאני נותן כדי לנסות ולהמשיך לפתור, באם נתקעתם. לפעמים הרמזים יהיו מאוד קלים, ולפעמים כבדים. יש להקדיש לשלב השני עוד כ 5-15 דקות לניסיון המשך פתירה.',
            },      
            {
                id: 3,
                bullet: 'השלב השלישי הינו הפתרון המלא שלי, שבו אני אסביר לעומק את צורת החשיבה שצריכה להוביל אתכם לפתרון וכמובן את כל הפתרון החישובי. בשלב זה מאוד חשוב לנסות בעצמכם לבצע את כל החישובים, ובאם צריך, אף לעצור את הוידאו.',
            },     
        ]
    },
    {
        id: 2,
        type: "writing",
        title: "פרקטיקה בזמן הסמסטר",
        subTitle: "",
        explenation: 'אני מציע שתדפיסו את כל התרגולים הרשמיים של סגל הקורס, ותנסו לפתור בעצמכם את התרגילים (כמובן שאתם יכולים להיעזר בפתרונות הרשמיים שלהם, באם יש את הצורך), ובנוסף לכך, פתרו את כל הש.ב.   הסיבה לכך היא פחות בשביל "להשלים" חומר. זה יותר בשביל הפרקטיקה שלכם ולפיתוח ביטחון בפתירת שאלות בפיסיקה.    זה בסדר, ואף מומלץ, להיעזר בחברים ובפתרונות רשמיים בשביל להצליח לפתור תרגילים. העיקר שבסוף אתם רושמים את הפתרון בעצמכם לפי צורת החשיבה והכתיבה שלכם.  זכרו – זהו עדיין שלב למידה והפנמה של החומר.',
        bullet: []
    },
    {
        id: 3,
        type: "writing",
        title: 'לו"ז למידה',
        subTitle: "",
        explenation: 'תבנו לו"ז מסודר לסמסטר, כך שתלמדו את כל החומר אצלי לאורך כל הסמסטר, ולא ברגע האחרון. זאת מכיוון שזמן הוא פקטור להפנמה של החומר, וחוסר לחץ תורם מנטלית להכניס חומר חדש ומורכב לראש.   ככל שמתקדמים בקורס, כך החומר יהיה מורכב יותר, וייקח יותר זמן להשלים את הפרק (בייחוד פרקים כמו תנע זוויתי ויחסות).   כך שעדיף שאת הפרקים הראשונים תסיימו מהר יותר, והשאירו מספיק זמן עבור הפרקים האחרונים.   הכי טוב זה לסיים את כל הקורס אצלי לפחות שבוע לפני סיום הסמסטר, וכך תוכלו להתחיל לבצע חזרה, הטמעה ופתירה של מבחנים.',
        bullet: []
    },
    {
        id: 4,
        type: "writing",
        title: "סדר הלמידה",
        subTitle: "",
        explenation: 'ניתן ללמוד את הקורס בדיוק בסדר המופיע אצלי באתר.  לפעמים, יש מרצים שמעבירים את החומר בסדר קצת שונה משלי',
        bullet: []
    },
    {
        id: 5,
        type: "writing",
        title: "רמת התרגילים",
        subTitle: "",
        explenation: 'שני הפרקים הראשונים (קינמטיקה וכוחות), הם פרקים המקנים בסיס, והשאלות שם הם לא ברמת מבחן. לאחר מכן, רוב השאלות בקורס הם שאלות ברמה של מבחן (חלקם שאלות קלות במבחן, חלקם קשות)  אף תרגיל אצלי בקורס אינו דומה לקודמו, חלק מן התיאוריה מועבר גם בפתרון התרגיל. לכן, מאוד חשוב לעבור על כל החומר, ולא לקפוץ שלבים. זאת מכיוון שככל שמתקדמים בחומר, כך אני רץ מהר יותר על נושאים בסיסיים, ויכולים לאבד אותי.',
        bullet: []
    },
    {
        id: 6,
        type: "writing",
        title: "הכנה למבחן",
        subTitle: "",
        explenation: 'לאחר סיום הלימודים בקורס, עכשיו זה הזמן לבצע חזרה על כל החומר ולהתחיל לפתור מבחנים. זה גם הזמן להתחיל לצפות מעצמכם להצליח לפתור תרגילים לבד ללא עזרה חיצונית. לרוב, ההרגשה של הצלחה אמיתית בפתירת תרגילים מגיעה רק לקראת סוף הקורס ובסמוך מאוד למבחן עצמו. ניתן לצפות בסרטונים האחרונים בקורס, שם אני מציג את הנקודות החשובות לשים לב בתקופה של סוף הסמסטר',
        bullet: []
    },
]


const Learning = (props) => {
    return(
        <div className = "container3">
            <br></br>
             <p>
                 הקורסים המקוונים באתר נבנו כך שאני מניח שאתם לא למדתם את החומר, ושזה הפעם הראשונה שאתם נפגשים בו, או שאינכם מבינים אותו לעומק.
             </p>
             <p>
                 הקורס מוכוון להצלחה במבחן, אני מלמד את כל הטריקים והעקרונות הדרושים לכם כדי לפתור מגוון רחב של תרגילים, ובונה אתכם אלגוריתם פתרון מסודר של תרגילים (שיטת פתרון) שבה נעבוד במהלך כל הקורס.
             </p>
             <p>
                 מכיוון שזה קורס מקוון, ואנני נמצא לידכם כדי לראות ולתת הערות אישיות לכל אחד, יש מן ההכרח שתסגלו לעצמכם למידה נכונה בכדי להוציא את המיטב מן הקורס ולהצלחה שלכם במבחן.
             </p>
             <br></br>
             <p>
                 להלן נקודות מאוד חשובות שתשימו לב אליהם, בבקשה קראו בעיון:
             </p>
             <br></br>
             <br></br>
             {/* {description.map(List)} */}
             {description.map((el)=><List {...el} key={el.id}/>)}
        </div>

    );
}

export default Learning;