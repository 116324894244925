import * as React from "react";
import { MainContainer } from "../Containers";
import { useTheme, Divider, Typography, Box } from "@mui/material";

import { SubHeader } from '../../utils/subHeader';
import { TermDetails } from "./TermDetails";

const Terms = () => {
    const theme = useTheme();

    const headerTitle = `תנאי השימוש`;
    const body = `יש לקרוא לפני השימוש וההרשמה לאתר`;

    const details = [{ title: 'קדימון', text: '', bullets: ['אתר "physicsamitshlomo" (להלן "האתר") הוא אתר המשתמש כאתר (אתר ללימוד אינטרנטי, אישי וקבוצתי של מקצועות הפיסיקה והמתמטיקה) ייצוגי עבור "physicsamitshlomo" והנך מוזמן לקחת בו חלק בכפוף להסכמתך לתנאי השימוש אשר יפורטו להלן', 'בנוסף השימוש באתר זה על כל תכניו והשירותים המוצעים בו, הורדות של קבצים, מדיה כגון תמונות וסרטונים והתכנים השונים המוצעים לקהל המבקרים עשויים להשתנות מעת לעת או בהתאם לסוג התוכן.', 'הנהלת האתר שומרת לעצמה הזכות לעדכן את תנאי השימוש המוצגים להלן מעת לעת וללא התראה או אזכור מיוחד בערוצי האתר השונים.'] },
    { title: 'קניין רוחני', text: '', bullets: ['האתר כמו גם כל המידע שבו לרבות עיצוב האתר, קוד האתר, קבצי מדיה לרבות גרפיקות, סרטונים, תמונות, טקסטים, קבצים המוצעים להורדה וכל חומר אחר אשר מוצג באתר שייכים במלואם לאתר הנ"ל ומהווים קניין רוחני בלעדי של אתר "physicsamitshlomo" ואין לעשות בהם שימוש ללא אישור כתוב מראש מאתר "physicsamitshlomo".', 'בנוסף אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות, סרטונים, סימנים מסחריים או כל מדיה ותוכן אחר מבלי שיש ברשותכם אישור כתוב מראש.', 'לא ניתן ללמוד או לפתור תרגילים מתוך תכני האתר בשיעור רשמי או פרטי ללא אישור מפורש מהאתר.', 'הפרה של כל אחד מהסעיפים בפרק זה, תהווה הפרה יסודית של מקבל השירות. המשתמש יהיה חשוף לתביעה משפטית ע"י האתר בגין הפרה של זכויות יוצרים.', 'בכדי לשמור נגד הפצת התכנים, פרטי המידע של המשתמש יהיו מוצגים בסרטונים באתר, הן סמויים והן גלויים.', 'הימצאות הסרטים או כל תוכן אחר באתר, אצל צד שלישי עם פרטי המשתמש מוטמע בהם יהווה ראייה מספקת להעברת הסרטים לצד השלישי על ידי אותו משתמש ולהפרת חוק זכויות היוצרים.'] },
    { title: 'תוכן האתר', text: '', bullets: ['אנו שואפים לספק לכם את המידע המוצג באתר ללא הפרעות אך יתכנו בשל שיקולים טכניים, תקלות צד ג או אחרים, הפרעות בזמינות האתר. ולכן איננו יכולים להתחייב כי האתר יהיה זמין לכם בכל עת ולא יינתן כל פיצוי כספי או אחר בשל הפסקת השירות / הורדת האתר. רק במקרים בו האתר יהיה מושבת מעל שבוע ימים ברציפות, יהיה ניתן לקבל הארכה של תוקף המנוי מעבר ל 6 חודשים, ככמות הימים שהיה מושבת ברציפות.', 'סרטוני האתר אינם ניתנים לצפייה במכשירי IOS של אפל, וכן בדפדפנים מסויימים. הדפדפנים המומלצים לצפייה הינם כרום ופיירפוקס. יש להירשם חינם לאתר ולראות כי הסרטונים פועלים לכם במחשב לפני רכישת הקורס.', 'קישורים לאתר חיצוניים אינם מהווים ערובה כי מדובר באתרים בטוחים, איכותיים או אמינים וביקור בהם נעשה על דעתכם האישית בלבד ונמצאים בתחום האחריות הבלעדי של המשתמש באתר.', 'התכנים המוצעים באתר הינם בבעלותם הבלעדית של "physicsamitshlomo" ואין לעשות בהם שימוש מעבר לאמור בתקנון זה. למעט במקרים בהם צוין אחרת או במקרים בהם צוין כי זכויות היוצרים שייכים לגוף חיצוני. במקרים אלו יש לבדוק מהם תנאי השימוש בגופים הללו ולפעול על פי המצוין באתר החיצוני לו שייכים התכנים.'] },
    { title: 'ניהול משתמשים ומבקרים באתר', text: '', bullets: ['הנהלת האתר שומרת לעצמה את הזכות לחסום כל משתמש ובין אם על ידי חסימת כתובת הIP של המחשב שלו, כתובת הMACID של המחשב שלו או אפילו בהתאם למדינת המוצא ללא צורך לספק תירוץ אשר מקובל על הגולש.', 'צוות האתר / הנהלת האתר יעשה כל שביכולתו להגן על פרטי המשתמשים הרשומים באתר / מנויים הרשומים באתר. במקרים בהם יעלה בידיו של צד שלישי להשיג גישה למידע מוסכם בזאת כי לגולשים, משתמשים וחברים באתר לא תהה כל תביעה, טענה או דרישה כלפי צוות האתר "physicsamitshlomo".'] },
    { title: 'אחריות', text: '', bullets: ['התכנים המוצגים באתר מוצגים וניתנים לשימוש כמות שהם (“As Is”).', 'הנהלת האתר עושה את כל המאמצים שהתכנים המוצגים יהיו נכונים, רלוונטיים, עדכניים ומהימנים. למרות זאת, בשל הקושי של החומר המוצג, ייתכנו טעויות ואי דיוקים. האתר או מפעיליו לא יישאו בכל אחריות בקשר לטעויות או שגיאות באתר. המשתמש מצהיר בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש שהוא יעשה בתכנים המוצעים, ויישא באחריות בכל תוצאה, נזק או השלכה כתוצאה מכך, הן ישיר והן עקיף.', 'הנהלת האתר עושה את כל המאמצים להתאים את חומר הלימוד למוסד הרלוונטי ולקורס הרלוונטי. למרות זאת, עקב מורכבות התכנים והשינויים שיכולים להיות בין מרצים ובין מוסדות, יתכנו אי דיוקים, וחוסר התאמה לסילבוס ולמבחן. המשתמש מצהיר כי הוא אחראי באופן בלעדי לשימוש בו הוא יעשה בתכני האתר.', 'כל השמות, הדמויות והאנשים שנכללים בתכנים הם לצרכים פדגוגיים בלבד. אין שום קשר בינם לבין ישויות אמיתיות.', 'על האתר לא תחול אחריות בגין איבוד, שיבוש, מחיקה או פגיעה של מידע, בסיסי נתונים או מכל סוג שהוא. על החברה לא תחול אחריות בגין תקלות טכניות באתר ועיכוב השירותים הניתנים על ידו.'] },
    { title: 'מדיניות רכישות', text: '', bullets: ['לאחר הרשמה ראשונית לאתר (ללא תשלום), יהיה ניתן לצפות בחלק קטן מן התכנים ובכמה סרטונים בודדים בכדי להמחיש את המוצר.', 'לאחר רכישת קורס (בתשלום), לא יהיה ניתן לבטל את הרכישה ולא יהיה ניתן לקבל זיכוי.'] },
    { title: 'תנאי השימוש', text: '', bullets: ['ההרשמה לאתר והזכות לעשות שימוש בשירותים המוצעים על ידו מותנים בקבלת חשבון אישי וסיסמא אישית. כל משתמש אחראי באופן אישי ובלעדי על שמירת סודיות פרטי חשבונו והסיסמא שלו.', 'רכישת מוצרי האתר הינה אישית למשתמש, ולא ניתנת לו הזכות להעביר תכנים אלו לצד נוסף.', 'בכדי לשמור נגד הפצת התכנים, הכניסה לאתר לאחר רכישת מנוי אפשרית אך ורק לאחר אימות של סיסמא (הניתנת בכל כניסה באופן חד פעמי) לטלפון הנייד האישי של הרוכש.', 'בכדי לשמור נגד הפצת התכנים, פרטי המידע של המשתמש יהיו מוצגים בסרטונים באתר, הן סמויים והן גלויים.', 'רכישת קורס הינה לתקופה של שש חודשים מרגע אישור הרכישה ע"י האתר. לאחר תקופה זו יפקע תוקף הרכישה ולא יהיה ניתן לצפות בתכנים. צפייה נוספת תיהיה כרוכה בתשלום נוסף.', 'הפרה של כל אחד מהסיעים בפרק זה, תהווה הפרה יסודית של מקבל השירות. המשתמש יהיה חשוף לתביעה משפטית ע"י האתר בגין הפרה של זכויות יוצרים.'] },
    { title: 'מידע אישי', text: '', bullets: ['באתר זה נעשה שימוש בקבצי קוקיז (במיוחד עבור משתמשים רשומים ומנויים) ובממשקי סטטיסטיקה פנימיים בכדי לשמור תיעוד סטטיסטי של גולשים וניתוח תנועת הגולש/ים, הרגלי גלישה באתר וניתוח קליקים וזמן שהייה.', 'האתר מכיל אמצעי אבטחה גלויים וסמויים, וכל הפרה או גניבת מידע יכולה להוביל למשתמש.', 'המידע שנמסר לנו על ידכם נשמר במחשבי מפעילי האתר או מי מטעמה בצורה מאובטחת ומוצפנת.'] },
    { title: 'חוברת הקורס', text: '', bullets: ['חוברת הקורס (באם נרכש) מתעדכן מפעם לפעם לפני השינויים המתווספים לקורס האינטרנטי. ספר הקורס שישלח אליכם הינו הספר המעודכן לאותו תאריך רכישה בלבד.', 'החוברת מודפסת לפי בקשת הרוכש. לאחר רכישת החוברת לא יהיה ניתן לבטלה.', 'חוברת הקורס מגיעה עד 10 ימי עסקים מרגע ביצוע הרכישה.', 'אין להפיץ, להעתיק, לשכפל, לפרסם, לחקות או לעבד פיסות קוד, גרפיקות, סימנים מסחריים או כל מדיה ותוכן אחר מחוברת הקורס מבלי שיש ברשותכם אישור כתוב מראש.', 'החוברות מסומנות באופן גלוי וסמוי, הימצאות תוכן החוברת, אצל צד שלישי עם פרטי המשתמש מוטמע בהם יהווה ראייה מספקת להעברת החוברת לצד השלישי על ידי אותו משתמש ולהפרת חוק זכויות היוצרים.'] },
    { title: 'ייעוץ אקדמי', text: '', bullets: ['הייעוץ האקדמי בא במטרה לעזור לרוכש להצליח בקורס. המשתמש מצהיר בזה, כי הוא אחראי באופן בלעדי ומלא לכל שימוש בייעוץ, ויישא באחריות בכל תוצאה, נזק או השלכה כתוצאה מכך, הן ישיר והן עקיף.'] },
    { title: 'שאילת שאלות', text: '', bullets: ['מתחת לכל שיעור ישנה אפשרות לשאול שאלות ולהוסיף הערות, יש לשים לב, כל שינוי הכי קטן בשאלה יכול לגרום לשינוי מאוד גדול בתשובה, ולכן השאלות צריכות להתייחס ספציפית לתוכן השיעור.', 'יש לרשום שאלות והערות אשר מכבדות את כלל מנהלי האתר ומשתמשי האתר, למנהלי האתר יש אפשרות למחוק / לשנות כל תגובה אשר לא תראה נאותה או לא עניינית.', 'מנהלי האתר ינסו בזמן סביר לענות על השאלות הנשאלות.'] },
    { title: 'אזור שיפוט', text: '', bullets: ['בעת שאתם עושים שימוש באתר ובמקרה בו התגלה כל מחולקת אתם מסכימים להלן כי האמור לעיל נמצא תחת סמכות השיפוט הבלעדי של החוק הישראלי תוך שימוש במערכת בתי המשפט הישראליים בלבד.'] },
    ]

    return (
        <MainContainer>
            <SubHeader title={headerTitle} body={body} />
            <Divider variant="fullWidth" sx={{ margin: " 0.5rem 0" }} />

            <Box sx={{ my: '2rem' }}>
                <Typography sx={{
                    fontSize: theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.fontFamily,
                    color: theme.palette.black,
                    textAlign: 'center'
                }}>
                    ברוכים הבאים לאתר "העמית לפיסיקה"
                </Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontFamily: theme.fontFamily,
                    textAlign: 'center'
                }}>
                    מקווים שתיהיה לכם שהייה נעימה ומעשירה באתר.
                    <br />
                    האתר והשימוש בו הינו לשימוש אישי בלבד
                </Typography>
            </Box>

            <Divider variant="fullWidth" sx={{ margin: " 0.5rem 0" }} />

            <Box sx={{ my: '2rem' }}>
                <Typography sx={{
                    fontSize: theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.fontFamily,
                    color: theme.palette.black,
                    textAlign: 'center'
                }}>
                    תנאי השימוש באתר physicsamitshlomo.com
                </Typography>
                <Typography sx={{
                    fontSize: '18px',
                    fontFamily: theme.fontFamily,
                    textAlign: 'center'
                }}>
                    תקנון השימוש באתר הנ"ל נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.
                    <br />
                    הרישום לאתר מהווה הסכמה מצדכם לתנאים הנכללים בתקנון.
                </Typography>
            </Box>

            <TermDetails details={details} />
        </MainContainer>
    );
};

export default Terms;