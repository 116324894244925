import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../api";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

// import PersonIcon from "@material-ui/icons/Person";

import LogoNavbar from "../images/LogoNavbar.png";
require("../css/styles.css");

const Header = (props) => {
  const [expanded, setExpanded] = useState(false);

  function renderContent() {
    switch (props.auth) {
      case null:
        // in this case the server is still deciding wether or not i'm logged in
        return;
      case false:
        // console.log("this is not header")
        // i'm logged out
        return (
          // <li><a href="/login">login</a></li>
          <Link className="person-color register" to={"/login"} onClick={() => setExpanded(false)}>
            <center>
              asda
              {/* <PersonIcon></PersonIcon> */}
            </center>
          </Link>
        );
    //   default:
    //     setInterval(() => {
    //       // AXIOS
    //       axios.get(`${BASE_URL}/current_user`).then(function (res) {
    //         // console.log(res.data.mechanicuniversities);
    //         // console.log (res.data);
    //         if (res.data.auth === undefined) {
    //           history.push("/login");
    //         }
    //       });
    //     }, 20000);

    //     //  const res = await axios.get(`${baseUrl}/api/current_user`);
    //     // i'm logged in
    //     return (
    //       <button
    //         className="butn register borders"
    //         onClick={() =>
    //           axios.post(`${BASE_URL}/logout`).then(function (res) {
    //             history.push("/");
    //           })
    //         }
    //         // window.location.reload()
    //       >
    //         יציאה
    //       </button>
    //       //  <li><a href="/api/logout">logout</a></li>
    //     );
    }
  }

  return (
    <Navbar className=" taskbar" collapseOnSelect expand="lg" expanded={expanded}>
      <Navbar.Brand>
        <Link to={"/"}>
          <img src={LogoNavbar} alt="Logo" className="logo" />
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(expanded ? false : "expanded")}
      />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Link to={"/about"} onClick={() => setExpanded(false)}>
            <center>
              <h5>אודות</h5>
            </center>
          </Link>
          <Link to={"/courses"} onClick={() => setExpanded(false)}>
            <center>
              <h5>אקדמיה</h5>
            </center>
          </Link>
          <Link to={"/coursesBagrut"} onClick={() => setExpanded(false)}>
            <center>
              <h5>בגרות</h5>
            </center>
          </Link>
          <Link to={"/lesson"} onClick={() => setExpanded(false)}>
            <center>
              <h5>שיעורים</h5>
            </center>
          </Link>
          <Link to={"/pakal"} onClick={() => setExpanded(false)}>
            <center>
              <h5>פק"לים</h5>
            </center>
          </Link>
          <Link to={"/material"} onClick={() => setExpanded(false)}>
            <center>
              <h5>חומרים</h5>
            </center>
          </Link>
          <Link to={"/contact"} onClick={() => setExpanded(false)}>
            <center>
              <h5>צרו קשר</h5>
            </center>
          </Link>
        </Nav>
        <Nav>
          {/* <Link className = "person-color register" to = {'./login'} onClick={() => setExpanded(false)}><center><PersonIcon></PersonIcon></center></Link> */}
          {renderContent()}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps)(Header);
