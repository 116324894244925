import React from "react";
import { styled, useTheme } from "@mui/material";
import { Paper } from '@mui/material'

export const Recommendations = ({ src }) => {
  const theme = useTheme();
  // const [width, setWidth] = useState(window.innerWidth > 1280 ? "750" : "350");

  return (
    <Paper sx={{ minHeight: '250px', width:"100%", margin: '0 auto', display: 'flex', justifyContent: 'center', background: '#ffffff00' }} elevation={0}>
      {/* <IframeStyle
        referrerPolicy="no-referrer"
        src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2${src}&width=750&show_text=true&appId"`}
        width="100%"
        style={{ border: "none", overflow: "hidden" }}
        scrolling="no" frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></IframeStyle> */}
      {src}
    </Paper>
  );
};

const IframeStyle = styled("iframe")(({ theme }) => ({
  margin: "1rem auto",
  border: "none",
}));