import React from "react";

import { Close } from "@mui/icons-material";
import { Divider, IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const ModalComponent = ({ visible, toggle, children, title }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",

    borderRadius: "5px",
    boxShadow: 24,
  };
  return (
    <Modal
      open={visible}
      onClose={toggle}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography
          sx={{
            padding: "0.1rem 0.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          {title}
          <IconButton onClick={toggle}>
            <Close />
          </IconButton>
        </Typography>

        <Divider variant="fullWidth" />
        <Box sx={{ padding: 4 }}>{children}</Box>
      </Box>
    </Modal>
  );
};
