import React  from 'react';
import List from './List';

import 'bootstrap/dist/css/bootstrap.css';


require('../css/styles.css');

const description = [
    {
        id: 1,
        type: "writing",
        chapter: 1,
        title: 'קינמטיקה',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "מושגי תאוצה, מהירות , מיקום, גרוויטציה",
            },
            {
                id: 2,
                bullet: "הקשרים המתמטיים והגרפיים ביניהם",
            }, {
                id: 3,
                bullet: "תנועה בתאוצה קבועה ותאוצה משתנה",
            }, {
                id: 4,
                bullet: "זריקה מאונכת ומשופעת",
            },
        ]
    },
    {
        id: 2,
        type: "writing",
        chapter: 2,
        title: 'כוחות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "חוקי ניוטון",
            },
            {
                id: 2,
                bullet: "כוחות נפוצים: כבידה, נורמל, קפיץ, חוט, חיכוך סטטי וקינטי ",
            }, 
        ]
    },
    {
        id: 3,
        type: "writing",
        chapter: 3,
        title: 'מערכות אינרציאליות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת מערכת אינרציאלית",
            },
            {
                id: 2,
                bullet: "טרנספורמציית גליליי",
            }, 
        ]
    },
    {
        id: 4,
        type: "writing",
        chapter: 4,
        title: 'מערכות לא אינרציאליות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת מערכת לא אינרציאלית",
            },
            {
                id: 2,
                bullet: "כוח דלמבר",
            },
        ]
    },
    {
        id: 5,
        type: "writing",
        chapter: 5,
        title: 'המשך לא אינרציאליות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "גרוויטציה אפקטיבית",
            },
            
        ]
    },
    {
        id: 6,
        type: "writing",
        chapter: 6,
        title: 'אנרגיה',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת אנרגיה",
            },
            {
                id: 2,
                bullet: "חוק שימור האנרגיה",
            }, {
                id: 3,
                bullet: "משפט עבודה אנרגיה",
            }, {
                id: 4,
                bullet: "כוחות משמרים ולא משמרים",
            },
            {
                id: 5,
                bullet: "מושג העבודה",
            },
            {
                id: 6,
                bullet: "פוטנציאל",
            },
        ]
    },
    {
        id: 7,
        type: "writing",
        chapter: 8,
        title: 'תנועה מעגלית',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "מערכת קואורדינטות פולארית",
            },
            {
                id: 2,
                bullet: "כוח צנטריפטלי ומשיקי",
            }, {
                id: 3,
                bullet: "מהירות משיקית וזוויתית",
            }, {
                id: 4,
                bullet: "זמן מחזור ותדירות",
            },
            {
                id: 5,
                bullet: "מהירות קריטית",
            },
        ]
    },
    {
        id: 8,
        type: "writing",
        chapter: 10,
        title: 'מערכות מסתובבות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת מערכת מסתובבת",
            },
            {
                id: 2,
                bullet: "כוח צנטריפוגלי וקוריוליס",
            }, {
                id: 3,
                bullet: 'כדה"א כמערכת מסתובבת',
            }, 
        ]
    },
    {
        id: 9,
        type: "writing",
        chapter: 11,
        title: 'מרכז מסה ותנע קווי',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת מיקום, מהירות ותאוצת מרכז המסה",
            },
            {
                id: 2,
                bullet: "הגדרת תנע קווי",
            }, {
                id: 3,
                bullet: "חוק שימור תנע קווי",
            }, {
                id: 4,
                bullet: "מושג המתקף",
            },
        ]
    },
    {
        id: 10,
        type: "writing",
        chapter: 13,
        title: 'משוואות דיפרנציאליות',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת משוואה דיפרנציאלית",
            },
            {
                id: 2,
                bullet: "משוואות דיפרנציאליות נפוצות במכניקה",
            },
        ]
    },
    {
        id: 11,
        type: "writing",
        chapter: 15,
        title: 'תנועה הרמונית',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "הגדרת תנועה הרמונית",
            },
            {
                id: 2,
                bullet: "מושגי אמפליטודה, פאזה, זמן מחזור, תדירות",
            }, {
                id: 3,
                bullet: "מושגי הקינמטיקה של תנועה הרמונית",
            }, {
                id: 4,
                bullet: "תנועות הרמוניות ידועות: קפיץ ומטוטלת מתמטית",
            },
            {
                id: 5,
                bullet: "מציאת קבוע k בתנועות הרמוניות לא ידועות",
            },
        ]
    },
    {
        id: 12,
        type: "writing",
        chapter: 17,
        title: 'המשך תנועה הרמונית',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "תנועה הרמונית מרוסנת ומאולצת (איכותית)",
            },
           
        ]
    },
    {
        id: 13,
        type: "writing",
        chapter: 18,
        title: '',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "חוק שני של ניוטון בתנאים שבה המסה משתנה",
            },
        ]
    },
    {
        id: 14,
        type: "writing",
        chapter: 19,
        title: 'תנועה סיבובית',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "קינמטיקה פולארית",
            },
            {
                id: 2,
                bullet: "מומנט אינרציה ומומנט כוח",
            }, {
                id: 3,
                bullet: "אנרגיה סיבובית",
            }, {
                id: 4,
                bullet: "הגדרת תנע זוויתי",
            },
            {
                id: 5,
                bullet: "חוק שימור תנע זוויתי",
            },
        ]
    },
    {
        id: 15,
        type: "writing",
        chapter: 20,
        title: 'המשך תנע זוויתי',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "חוק שימור תנע זוויתי",
            },
            {
                id: 2,
                bullet: "משפט שטיינר",
            }, {
                id: 3,
                bullet: "גוף צפיד",
            }, {
                id: 4,
                bullet: "תנועה הרמונית זוויתית",
            },
        ]
    },
    {
        id: 16,
        type: "writing",
        chapter: 21,
        title: 'גלגול ללא החלקה',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "גלגול מלא (גלגול ללא החלקה)",
            },
           
        ]
    },
    {
        id: 17,
        type: "writing",
        chapter: 22,
        title: 'מסה משתנה סיבובית',
        subTitle: "",
        explenation: '',
        bullet: []
    },
    {
        id: 18,
        type: "writing",
        chapter: 23,
        title: 'חישוב מומנט אינרציה',
        subTitle: "",
        explenation: '',
        bullet: []
    },
    {
        id: 19,
        type: "writing",
        chapter: 24,
        title: 'כבידה וחוקי קפלר',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "מהירות בריחה",
            },
            {
                id: 2,
                bullet: "שלושת חוקי קפלר",
            }, 
        ]
    },
    {
        id: 20,
        type: "writing",
        chapter: 26,
        title: 'פוטנציאל אפקטיבי',
        subTitle: "",
        explenation: '',
        bullet: []
    },
    {
        id: 21,
        type: "writing",
        chapter: 27,
        title: 'יחסות פרטית',
        subTitle: "",
        explenation: '',
        bullet: [
            {
                id: 1,
                bullet: "פיסיקה קלאסית מול פיסיקה מודרנית",
            },
            {
                id: 2,
                bullet: "הנחות יחסות פרטית",
            }, {
                id: 3,
                bullet: "התקצרות האורך",
            }, {
                id: 4,
                bullet: "התארכות הזמן",
            },
            {
                id: 5,
                bullet: "טרנספורמציות לורנץ",
            },
            {
                id: 6,
                bullet: "אפקט דופלר אורכי",
            },
            {
                id: 7,
                bullet: "אנרגיה ותנע יחסותיים",
            },
            {
                id: 8,
                bullet: "פוטונים",
            },
            {
                id: 9,
                bullet: "אינווריאנטים: אור, מסה, שמורת לורנץ",
            },
        ]
    },
    {
        id: 22,
        type: "writing",
        chapter: 29,
        title: 'מומנט כוח',
        subTitle: "",
        explenation: '',
        bullet: []
    },
   
]



const Syllabus = (props) => {

    var rows = [];

    for (let i=0; i<22; i++){
        if (props.chapters.includes(description[i].chapter)){
            rows.push(description[i]);
        }
       
    }
    // console.log(rows);

    return(
        <div className = "container2">
            <br></br>
            {rows.map((el)=><List {...el} key={el.id}/>)}
        </div>
        
     );    
    
}

export default Syllabus;