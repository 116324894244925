import React from 'react';


import {Accordion, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

require('../css/styles.css');



const Questions = (props) => {
    return (
       
            <div className = "container2">
                <center>
                    <h2>{props.title}</h2>
                </center>
                <Accordion >
                    <Card className = "borders ">        
                            <Accordion.Toggle className = "card-color" as={Card.Header} eventKey="0">
                            <h4>{props.subTitle}</h4>
                            </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <p>{props.explenation}</p>
                        </Card.Body>
                        </Accordion.Collapse> 
                    </Card>   
                </Accordion>
            </div>  
    );      
}

export default Questions;