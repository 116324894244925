import React, { useState } from "react";
import axios from "axios";
import ReactGA from "react-ga";

import Payment3 from "./Payment3";
import { Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { BASE_URL } from "../api";

require("../css/styles.css");

const Payment2 = (props) => {
  // console.log(props);

  const [page, setPage] = useState("telephone");

  const onCheckSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    // console.log (formDataObj);

    axios.post(`${BASE_URL}/payment2`, formDataObj).then(function (res) {
      // console.log(res.data);
      // setMessage (res.data);

      ReactGA.event({
        category: "Payment2",
        action: "phone check",
        label: res.data,
      });

      if (res.data === "NexmoErr") {
        window.alert("שליחת הודעה נכשלה");
      } else if (res.data === "no") {
        window.alert("סיסמא לא תואמת, אנא נסה שוב");
      } else if (res.data === "MongoErr") {
        window.alert("אירעה תקלה, אנא נסה שוב");
      } else if (res.data === "saved") {
        setPage("continue");
      }
    });
  };

  if (page === "telephone") {
    return (
      <div>
        <Row>
          <Col xs={12} md={4} lg={4} className="color1">
            <center>
              <h4>אימות טלפון</h4>
            </center>
          </Col>
          <Col xs={12} md={4} lg={4} className="color2">
            <center>
              <h4>פרטים אישיים</h4>
            </center>
          </Col>
          <Col xs={12} md={4} lg={4} className="color2">
            <center>
              <h4>תשלום</h4>
            </center>
          </Col>
        </Row>

        <div className="container">
          <Form onSubmit={onCheckSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label></Form.Label>
              <Form.Control type="hidden" value={props.telephone} name="telephone" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label></Form.Label>
              <Form.Control type="hidden" value={props.request} name="request" />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                <p>סיסמא נשלחה אליך כעת לטלפון</p>
              </Form.Label>
              <Form.Control required name="password2" placeholder="password" />
            </Form.Group>
            <Button className="butn border" variant="primary" type="submit">
              אישור
            </Button>
          </Form>
        </div>
      </div>
    );
  } else if (page === "continue") {
    return (
      <div>
        <Payment3 authName={props.authName} title={props.title}></Payment3>
      </div>
    );
  }
};

export default Payment2;
