import React from "react";
import Carousel from 'react-material-ui-carousel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import PostUrls from "./PostUrls";
import { Recommendations } from "../components/Home/Recommendations";

const CarouselUtil = () => {
    const width = window.innerWidth >= 700 ? "650" : "350";

    const PostUrls = [<div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/igal.v1/posts/4800132556767492" data-show-text="true"><blockquote cite="https://www.facebook.com/igal.v1/posts/4800132556767492" className="fb-xfbml-parse-ignore"><p>אחלה עמית.
        עברתי את המרתון פיזיקה1, כשהגעתי היו לא מעט נושאים שפשוט לא נכנסו לי לראש.
        עמית נותן נקודת מבט אחרת על החומר,...</p>Posted by <a href="https://www.facebook.com/igal.v1">Igal Viaduct</a> on&nbsp;<a href="https://www.facebook.com/igal.v1/posts/4800132556767492">Tuesday, February 8, 2022</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/guy.naor.1/posts/5245826112134272" data-show-text="true"><blockquote cite="https://www.facebook.com/guy.naor.1/posts/5245826112134272" className="fb-xfbml-parse-ignore"><p>למדתי עם עמית לקורס פיזיקה 1 בשנקר. עמית התאים את עצמו במקצועיות לחומר שאני למדתי בשנקר והביא אותי לרמה שקיבלתי 100 בבחינה הסופית ובכל הקורס. ממליץ בחום, סבלני מקצועי ומלך!</p>Posted by <a href="https://www.facebook.com/guy.naor.1">Guy Naor</a> on&nbsp;<a href="https://www.facebook.com/guy.naor.1/posts/5245826112134272">Tuesday, February 1, 2022</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/oren.goldberg.9/posts/5160626993950689" data-show-text="true"><blockquote cite="https://www.facebook.com/oren.goldberg.9/posts/5160626993950689" className="fb-xfbml-parse-ignore"><p>אם אתם במצוקה בחומר ומתלבטים לגבי אם זה שווה את הכסף או לא.. אז התשובה היא שחד משמעית כן ויפה שעה אחת קודם!
        מסביר את...</p>Posted by <a href="https://www.facebook.com/oren.goldberg.9">Oren Goldberg</a> on&nbsp;<a href="https://www.facebook.com/oren.goldberg.9/posts/5160626993950689">Friday, October 29, 2021</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/alon.spector/posts/10224124449511712" data-show-text="true"><blockquote cite="https://www.facebook.com/alon.spector/posts/10224124449511712" className="fb-xfbml-parse-ignore"><p>צפיתי בקורס ההכנה המקוון של עמית לקראת בחינת הסיווג במכניקה של הטכניון. הקורס סופר פרקטי ולא מתעכב על עניינים זניחים...</p>Posted by <a href="https://www.facebook.com/alon.spector">Alon Spector</a> on&nbsp;<a href="https://www.facebook.com/alon.spector/posts/10224124449511712">Wednesday, October 6, 2021</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/permalink.php?story_fbid=3856160907812886&amp;id=100002570439651" data-show-text="true"><blockquote cite="https://www.facebook.com/permalink.php?story_fbid=3856160907812886&amp;id=100002570439651" className="fb-xfbml-parse-ignore"><p>מורה מעולה, העביר את החומר ביסודיות וברמה גבוהה שתואמת את רמת המבחן, בא לי בול בזמן!</p>Posted by <a href="#" role="button">Noy Cohen</a> on&nbsp;<a href="https://www.facebook.com/permalink.php?story_fbid=3856160907812886&amp;id=100002570439651">Monday, February 1, 2021</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/permalink.php?story_fbid=1092766201153738&amp;id=100012611056888" data-show-text="true"><blockquote cite="https://www.facebook.com/permalink.php?story_fbid=1092766201153738&amp;id=100012611056888" className="fb-xfbml-parse-ignore"><p>אני ממליצה בחום את השיעורים עם עמית ! הוא מעביר את השיעור בצורה מאוד ברורה ומתרכז למה שתכלס נצטרך במבחן. למרות הקשיים...</p>Posted by <a href="https://www.facebook.com/people/Rebecca-Azoulay/100012611056888/">Rebecca Azoulay</a> on&nbsp;<a href="https://www.facebook.com/permalink.php?story_fbid=1092766201153738&amp;id=100012611056888">Monday, October 26, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/yuval.ganot/posts/3376119525816902" data-show-text="true"><blockquote cite="https://www.facebook.com/yuval.ganot/posts/3376119525816902" className="fb-xfbml-parse-ignore"><p>למדתי עם עמית לקורס פיזיקה 2, למועד א לא ניגשתי כי לא הרגשתי מספיק מוכן.
        בתקופה בין מועד א למועד ב נפגשתי עם עמית והוא...</p>Posted by <a href="#" role="button">Yuval Ganot</a> on&nbsp;<a href="https://www.facebook.com/yuval.ganot/posts/3376119525816902">Tuesday, October 20, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/netta.berger.5/posts/2738186769730496" data-show-text="true"><blockquote cite="https://www.facebook.com/netta.berger.5/posts/2738186769730496" className="fb-xfbml-parse-ignore"><p>עמית מורה מדהים!
        לימד אותי מאפס ובפרק זמן מאוד קצר (ותוך כדי שעבדתי במשרה מלאה) לבחינות הסיווג במכניקה ובחשמל בטכניון....</p>Posted by <a href="https://www.facebook.com/netta.berger.5">Netta Berger</a> on&nbsp;<a href="https://www.facebook.com/netta.berger.5/posts/2738186769730496">Monday, October 12, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/boriss846/posts/10220837513440322" data-show-text="true"><blockquote cite="https://www.facebook.com/boriss846/posts/10220837513440322" className="fb-xfbml-parse-ignore"><p>פיזיקה 1 בטכניון.
        עמית מורה מעולה. יחס אישי וענה וניסה לעזור בכל שאלה שהייתה לי בדרך בכל נושא.
        ההסברים היו ממש טובים...</p>Posted by <a href="#" role="button">Boris Shrager</a> on&nbsp;<a href="https://www.facebook.com/boriss846/posts/10220837513440322">Saturday, October 10, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/permalink.php?story_fbid=3335332013247052&amp;id=100003108582862" data-show-text="true"><blockquote cite="https://www.facebook.com/permalink.php?story_fbid=3335332013247052&amp;id=100003108582862" className="fb-xfbml-parse-ignore"><p>מורה מצוין, הכין אותי תוך קצת יותר משבוע לבחינה בפיזיקה 1 ממצב שבקושי הצלחתי לעשות תרגילים ולהבין דברים למצב שבו קיבלתי...</p>Posted by ‎<a href="#" role="button">אופיר חיים</a>‎ on&nbsp;<a href="https://www.facebook.com/permalink.php?story_fbid=3335332013247052&amp;id=100003108582862">Thursday, October 8, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/josef.moti.3/posts/2824579691108755" data-show-text="true"><blockquote cite="https://www.facebook.com/josef.moti.3/posts/2824579691108755" className="fb-xfbml-parse-ignore"><p>פיזיקה 1 מ בטכניון, מצליח ללמד את החומר בצורה מובנת, מסביר איך לדמיין את התרחשות התרגיל, מה שבדכ לא מסבירים באקדמיה.
        הופך נושאים שנראים מסובכים כמו פיזיקה מודרנית למשחק ילדים.
        ציון 93</p>Posted by <a href="#" role="button">Josef Moti</a> on&nbsp;<a href="https://www.facebook.com/josef.moti.3/posts/2824579691108755">Tuesday, September 29, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/may.buzaglo.3/posts/10214842946181108" data-show-text="true"><blockquote cite="https://www.facebook.com/may.buzaglo.3/posts/10214842946181108" className="fb-xfbml-parse-ignore"><p>ממליצה בחום להגיע לשיעורים עם עמית !
        עברתי עם עמית קורסים בפיסיקה 1מ ופיסיקה 2 בטכניון, והגעתי להישגים גבוהים  -  הכל...</p>Posted by <a href="#" role="button">May Buzaglo</a> on&nbsp;<a href="https://www.facebook.com/may.buzaglo.3/posts/10214842946181108">Sunday, September 27, 2020</a></blockquote></div>,

    <div className="fb-post" data-width={width} data-lazy="true" data-href="https://www.facebook.com/litalii/posts/4195933133754893" data-show-text="true"><blockquote cite="https://www.facebook.com/litalii/posts/4195933133754893" className="fb-xfbml-parse-ignore"><p>ממליצה בחום על עמית :)
        הוא עזר לי בקורס פיסיקה 1 להנדסת חשמל,
        הוא מעביר את החומר בצורה מאוד ברורה ומעניינת, בעל ניסיון רב וזה בהחלט מורגש בשיעורים.
        יש יחס אישי ואווירה נעימה מאוד!</p>Posted by <a href="#" role="button">Lital Ben Hamo</a> on&nbsp;<a href="https://www.facebook.com/litalii/posts/4195933133754893">Saturday, September 12, 2020</a></blockquote></div>

    ]

    return (
        <Carousel sx={{ marginTop: '1rem' }}
            NextIcon={<ChevronRightIcon />}
            PrevIcon={<ChevronLeftIcon />}>
            {PostUrls.map((post, i) => {
                return <Recommendations src={post} key={i} />
            })}
        </Carousel>
    )
}

export default CarouselUtil;