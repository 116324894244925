import React from "react";
import { MainContainer } from "../Containers";
import { Grid } from "@mui/material";

import { SubHeader } from '../../utils/subHeader';

import { AboutTimeline } from './AboutTimeline';

const About = () => {
    const title = `שלום, אני עמית`
    const body = `בוגר הפקולטה לפיזיקה בטכניון, סטודנט לתארים מתקדמים בפקולטה לחינוך למדע וטכנולוגיה בטכניון, עוסק בהוראה משנת 2009`;

    return (
        <MainContainer>
            <SubHeader title={title} body={body} />
            <Grid container sx={{ margin: '0px auto', direction: 'ltr', width: { xs: '90%' }, maxWidth: { xs: '600px' } }}>
                <AboutTimeline />
            </Grid>
        </MainContainer>
    );
};

export default About;