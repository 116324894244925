import React, { useEffect } from "react";
import { MainContainer } from "../Containers";
import { CourseCard } from "../../utils/Card";
import { Box, Divider, Grid, useTheme, Typography, styled } from "@mui/material";

// import Carousel from 'react-material-ui-carousel';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// import { CarouselUtil } from '../../utils/CarouselUtil';

import { Title } from "../../utils/Title";
// import PostUrls from "../../utils/PostUrls";
import { Specialties } from "./Specialties";
import MaleTeacher from "../../assets/Male Teacher.svg";
import Budeget from "../../assets/Budget.svg";
import Student from "../../assets/Student Question.svg";
import Lesson from "../../assets/Energy equivalency.svg";
// import { Recommendations } from "./Recommendations";
import { HomeHeader } from "./HomeHeader";
import { AlertMessage } from "../../utils/AlertMessage";
import axios from "axios";
import { BASE_URL } from "../../api";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import store from '../../redux/store';

const Home = () => {
  const WEB_URL =
    process.env.NODE_ENV === "production"
      ? "https://www.amitphysics.com/"
      : "http://localhost:3000/";

  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");

  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const theme = useTheme();
  const history = useHistory();
  // const postUrls = ["igal.v1%2Fposts%2F4800132556767492",
  //   "guy.naor.1%2Fposts%2F5245826112134272",
  //   "oren.goldberg.9%2Fposts%2F5160626993950689",
  //   "alon.spector%2Fposts%2F10224124449511712",
  //   "yuval.ganot%2Fposts%2F3376119525816902",
  //   "netta.berger.5%2Fposts%2F2738186769730496",
  //   "boriss846%2Fposts%2F10220837513440322",
  //   "josef.moti.3%2Fposts%2F2824579691108755",
  //   "may.buzaglo.3%2Fposts%2F10214842946181108",
  //   "litalii%2Fposts%2F4195933133754893",
  //   // "permalink.php%3Fstory_fbid%3D3856160907812886%26id%3D100002570439651",
  //   // "permalink.php%3Fstory_fbid%3D1092766201153738%26id%3D100012611056888",
  //   // "permalink.php%3Fstory_fbid%3D3335332013247052%26id%3D100003108582862",
  // ];

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://graph.facebook.com/v12.0/1490784831195846/ratings/?fields=%20open_graph_story&access_token=EAAQ5OCLM2zABAN7Oq9wC33r51kDGbSA1WQh6gMWMxvRecXCXmBHypbKvFiFYhaZBPKWKvlbdp7ZBNHLv1SsrikECRqgaBm4qnmFeMfwkYRcts3KM4ZAQVoo3bYMXEVv7iQbMxjjY6ZBc3sr36JBl7TZA4hrit6zE9ui5TBg8362YipbNhysZBZAVK9TJoCWdMotnZByO5y9M0gZDZD"
  //     )
  //     .then((res) => setPostData(res.data.data.map((item) => item.open_graph_story.id)));
  // }, []);

  const [academy, setAcademy] = useState([]);
  const [bagrut, setBagrut] = useState([]);
  const [pakal, setPakal] = useState([]);

  const [authCourses, setAuthCourses] = useState([]);
  const [status, setStatus] = useState('');

  const [isLoggedIn, setIsLoggedIn] = useState(store.getState().isLoggedIn);
  const [userDetails, setUserDetails] = useState(store.getState().userDetails);

  useEffect(() => {
    setTimeout(() => {
      if (store.getState().anchor) {
        const anchor = document.getElementById(`${store.getState().anchor}`);

        anchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        store.dispatch({ type: 'setAnchor', payload: null });
      }
    }, 800)
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/courses/getCoursesInfo/טכניון`).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      setAcademy(res.data);
    });

    axios.get(`${BASE_URL}/courses/getCoursesInfo/שאלון 036361`).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      setBagrut(res.data);
    });

    axios.get(`${BASE_URL}/courses/getCoursesInfo/פקל`).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      setPakal(res.data);
    });

    const unsubscribe = store.subscribe(() => {
      setIsLoggedIn(store.getState().isLoggedIn);
      setUserDetails(store.getState().userDetails);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (pakal.length > 0) {
      let auth = authCourses.slice();
      pakal.map(async (item, i) => {
        let isAuthForCourse = await getCourseData(item.courseId);

        if (isAuthForCourse) auth.push(item.courseId);

        setAuthCourses(auth);
        //leave this for pakal button render bug!
        setStatus(`updated course id ${item.courseId}`);
      })
      setAuthCourses(auth);
      //leave this for pakal button render bug!
      setStatus(`finished all course ids`);
    }
  }, [pakal]);

  function findEveryElements(arr1, arr2) {
    return arr1.every((item) => arr2.includes(item));
  }

  const getCourseData = async (courseID) => {
    let response = await axios.post(`${BASE_URL}/lessons/courseIds`, { number: courseID }).then((res) => {
      if (res.data.error) {
        setAlertMessage(res.data.message);
        setAlertStatus('error');
        setAlertIsOpen(true);
        return;
      }

      //if the user is logged in then check if he's auth for the course (for pakal usage)
      if (isLoggedIn && userDetails.auth.length > 0 && findEveryElements(res.data[0].ID, userDetails.auth)) {
        return true;
      }
      return false;
    });
    return response;
  }

  const title = 'בית הספר להוראת פיזיקה';
  const body = 'קורסים מקוונים מרמת בגרות ועד לרמה אקדמאית';
  const video = 'https://www.youtube.com/embed/T4wYeoS8u44';

  const specialities = [{ svg: MaleTeacher, title: 'איכות הוראה', bgColor: theme.palette.turquoise, text: 'למדו פיזיקה עם פיזיקאי, מורה משנת 2009' },
  { svg: Student, title: 'השתתפות פעילה', bgColor: theme.palette.coral, text: 'פורום לשאילת שאלות, שעת קבלה שבועית' },
  { svg: Lesson, title: 'קורס מקיף', bgColor: theme.palette.darkBlue, text: 'כולל תיאוריה, תרגול, בחנים ודפי נוסחאות' },
  { svg: Budeget, title: 'חסכון כלכלי', bgColor: theme.palette.lightBlue, text: 'חסכו אלפי שקלים על שיעורים פרטיים וקורסים חוזרים' }]

  const courseBoxStyle = {
    minHeight: { md: '50vh' },
    display: { md: 'flex' },
    flexDirection: { md: 'column' },
    justifyContent: { md: 'space-evenly' },
    alignItems: { md: 'center' },
    marginBottom: { xs: '3rem' },
    marginTop: { md: '3rem' }
  }

  return (
    <MainContainer>
      <HomeHeader title={title} body={body} video={video} />
      <Divider sx={{ margin: "3rem" }} />

      <Box sx={courseBoxStyle} id="בגרות">
        <Title color={theme.palette.lightBlue}>בגרות</Title>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 12, sm: 7, md: 12 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}>
          {bagrut.map((item, i) => (
            <Grid item key={i} xs={10} sm={3} md={3}>
              <CourseCard id={item.courseId}
                courseTitle={item.courseTitle}
                popUpMainTitle='צור קשר'
                popUpSubTitle={'בחרת בקורס מסוג'}
                isShowForm={true}
                color={theme.palette.lightBlue}
                bullets={item.bullets}
                price={item.price} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={courseBoxStyle} id="אקדמיה">
        <Title color={theme.palette.coral}>אקדמיה</Title>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 12, sm: 7, md: 12 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}>
          {academy.map((item, i) => (
            <Grid item key={i} xs={10} sm={3} md={3}>
              <CourseCard id={item.courseId}
                courseTitle={item.courseTitle}
                popUpMainTitle='צור קשר'
                popUpSubTitle={'בחרת בקורס מסוג'}
                isShowForm={true}
                color={theme.palette.coral}
                bullets={item.bullets}
                price={item.price} />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={courseBoxStyle} id="פק״ל">
        <Title color={theme.palette.turquoise} >קורסים קבוצתיים</Title>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: theme.fontSize.p,
            fontFamily: theme.fontFamily,
            margin: "0 0 1rem 0",
            textAlign: 'center'
          }}>
          ההשתתפות בקורסים הקבוצתיים מותנית בהרשמה. אתם מוזמנים ליצור איתי קשר ולהשתתף גם בהם.
        </Typography>
        <Grid
          container
          justifyContent="center"
          columns={{ xs: 12, sm: 7, md: 12 }}
          spacing={{ xs: 2, sm: 2, md: 4 }}>
          {pakal.map((item, i) => (
            <Grid item key={i} xs={10} sm={3} md={3}>
              <CourseCard
                id={isLoggedIn && authCourses.includes(item.courseId) ? item.courseId : 'private_lesson'}
                courseTitle={item.courseTitle}
                popUpMainTitle='הקורס נעול'
                popUpSubTitle={'אין לך גישה לקורס הקבוצתי'}
                isShowForm={false}
                key={i}
                color={theme.palette.turquoise} bullets={item.bullets} price={item.price}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider sx={{ margin: "3rem" }} />

      <Grid sx={{ height: { md: '50vh' }, display: { md: 'flex' }, justifyContent: { md: 'space-evenly' }, alignItems: { md: 'center' } }}
        container
        justifyContent="center"
        columns={{ xs: 1, sm: 6, md: 12 }}>
        {specialities.map((speciality, i) => (
          <Grid item xs={12} sm={3} md={3} key={`${speciality}_${i}`}>
            <Specialties
              svg={speciality.svg}
              title={speciality.title}
              bgColor={speciality.bgColor}
              text={speciality.text} />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ margin: "3rem" }} />

      <Grid
        container
        justifyContent="center"
        sx={{ margin: '1rem auto', display: 'flex', flexDirection: 'column' }} id="המלצות">
        <Title color={theme.palette.coral}>תלמידים ממליצים</Title>
        {/* <Carousel sx={{ width: "100%", marginTop: '1rem' }}
          NextIcon={<ChevronRightIcon />}
          PrevIcon={<ChevronLeftIcon />}>
          {PostUrls.map((post, i) => {
            return <Recommendations src={post} key={i} />
          })}
        </Carousel> */}

        {/* <iframe src={`${WEB_URL}recommendations`} width="540" height="450"></iframe> */}

        <IframeStyle
          referrerPolicy="no-referrer"
          src={`${WEB_URL}recommendations`}
          // width="100%"
          style={{ border: "none", overflow: "hidden" }}
          scrolling="no" frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></IframeStyle>
      </Grid>

      <Divider />

      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
    </MainContainer>
  );
};

const IframeStyle = styled("iframe")(({ theme }) => ({
  margin: "1rem auto",
  border: "none",
  width: "95%"
}));

export default Home;