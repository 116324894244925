import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Divider, Typography, useTheme, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { ButtonComponent } from "../utils/ButtonComponent";

import { ContactForm } from '../components/Contact/ContactForm';

export default function Popup({ open, handleClose, svg, title, mainTitle, subtitle, isShowForm }) {
    const theme = useTheme();

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                sx={{ direction: 'rtl', textAlign: 'right' }}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        left: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Typography id="alert-dialog-title" sx={{ color: theme.palette.black, fontSize: theme.typography.h5, fontWeight: theme.typography.fontWeightBold, margin: '10px 20px', textAlign: 'right' }}>
                    {mainTitle}
                </Typography>

                <Divider variant="fullWidth" sx={{ margin: " 0.5rem 0" }} />
                <DialogContent>
                    <Grid container spacing={{ xs: 1 }} justifyContent='center'>
                        <Grid item xs={12} md={6}>
                            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'right' }}>
                                {subtitle}
                            </DialogContentText>

                            <DialogContentText id="alert-dialog-description" sx={{ fontWeight: theme.typography.fontWeightBold, textAlign: 'right' }}>
                                {title}
                            </DialogContentText>

                            {isShowForm && <ContactForm returnToPrev={false} handleClosePopup={handleClose} />}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img src={svg} alt="" height={"200px"} width={"207px"} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}