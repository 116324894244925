import React from 'react';

import Iframe from 'react-iframe';

require('../css/styles.css');



const Title = (props) => {
    if (props.url === undefined) {
                return (
                    <div>
                        <div className="openning">

                        <h1>{props.title}</h1>
                        <h2>{props.subtitle1}</h2>
                        <h2>{props.subtitle2}</h2>
                        <br></br>
                        <center>
                        <img className = {props.style1} src={props.img} alt = {props.alt}></img>
                        </center>
                        </div>
                        {/* <br></br>
                        <br></br> */}
                    </div>
                );
    }else{
        return (
            <div>
                    <div className="openning">

                        <h1>{props.title}</h1>
                        <h2>{props.subtitle1}</h2>
                        <h2>{props.subtitle2}</h2>
                        <br></br>
                        <center>
                            <Iframe style="width:560px; height:315px; max-width:80%;" src= {props.url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen" allowFullScreen ></Iframe>
                        </center>
                        </div>
                        <br></br>
                        
                        <br></br>
                    </div>

        );
    }
}

export default Title;
