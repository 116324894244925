import React, { useState } from "react";
import axios from "axios";

// import Sivug1 from '../files/TestSheet_1Sivug.pdf';
// import physics1 from '../files/TestSheet1.pdf';
// import physics1m from '../files/TestSheet1m.pdf';
// import BenGurion1a from '../files/TestSheet_1aBenGurion.pdf';
// import BenGurion1b from '../files/TestSheet_1bBenGurion.pdf';
// import Afeka1 from '../files/TestSheet_1Afeka.pdf';
// import TelAviv1 from '../files/TestSheet_1TelAviv.pdf';
import EmailCheck from "./EmailCheck";

require("../css/styles.css");

const Sheet = (props) => {
  // console.log(props.page);
  //   let cheatsheet = null;
  //   if (props.page === "Sivug1") {
  //     cheatsheet = Sivug1;
  //   } else if (props.page === "1") {
  //     cheatsheet = physics1;
  //   } else if (props.page === "1m") {
  //     cheatsheet = physics1m;
  //   } else if (props.page === "BenGurion1a") {
  //     cheatsheet = BenGurion1a;
  //   } else if (props.page === "BenGurion1b") {
  //     cheatsheet = BenGurion1b;
  //   } else if (props.page === "TelAviv1") {
  //     cheatsheet = TelAviv1;
  //   } else if (props.page === "Afeka1") {
  //     cheatsheet = Afeka1;
  //   }

  const [show, setShow] = useState(null);

  axios.get("./../api/current_user2").then(function (res) {
    // console.log(res.data.mechanicuniversities);
    // console.log ("check", res.data);
    if (res.data === "activeFalse") {
      setShow("emailCheck");
    } else if (res.data.auth === undefined) {
      setShow("no");
    } else {
      setShow("yes");
    }
  });

  if (show === null) {
    return (
      <div>
        <center>
          <br></br>
          <h4>טוען...</h4>
        </center>
      </div>
    );
  } else if (show === "no") {
    return (
      <div>
        <center>
          <br></br>
          <h4>הירשמו בחינם ותוכלו להוריד את דפי הנוסחאות</h4>
        </center>
      </div>
    );
  } else if (show === "yes") {
    return (
      <div>
        <center>
          <br></br>
          {/* <a href={cheatsheet} download> */}
          <h4 className="link">לחץ כדי להוריד את דף הנוסחאות</h4>
          {/* </a> */}
        </center>
      </div>
    );
  } else if (show === "emailCheck") {
    return <EmailCheck></EmailCheck>;
  }
};

export default Sheet;
