import { useTheme, Box, Typography } from "@mui/material";
import React from "react";

export const TabPanel = (props) => {
  const { children, value, index, padding, ...other } = props;
  const theme = useTheme();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: padding }}>
          <Typography sx={{ fontFamily: theme.fontFamily }} component={"span"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};
