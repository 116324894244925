import * as React from "react";
import { MainContainer } from "../Containers";
import Box from '@mui/material/Box';
import { useTheme, Grid } from "@mui/material";

import { HomeHeader } from "../Home/HomeHeader";

import { CourseCard } from '../../utils/Card';
import { FAQs } from "./FAQs";
import { Contact } from "./Contact";

const Lessons = () => {
    const theme = useTheme();

    const headerTitle = `שיעורים פרטיים פנים מול פנים או בזום`;
    const body = `מאפס ידע ועד הכנה למבחן, אחד על אחד או קבוצתי`;
    const video = 'https://www.youtube.com/embed/z3i9Q0V0UIc';

    const details = [{ title: 'רמה אקדמאית', text: '', bullets: ['מכניקה (פיסיקה 1, 1מ, 1ל, 1ר)', 'חשמל (פיסיקה 2, 2ממ, 2ל, 2ר)'] },
    { title: 'מכינות קדם אקדמאיות וסיווגים', text: '', bullets: ['מבחן סיווג במכניקה', 'מבחן סיווג בחשמל'] },
    { title: 'רמת תיכון ומבחני כניסה למכינה', text: '', bullets: ['מכניקה', 'חשמל ומגנטיות', 'קרינה וחומר', 'מעבדה'] }]

    const questions = [{ title: 'עם מה עלי להגיע לשיעור?', answer: 'סטודנטים בטכניון אינם צריכים להגיע עם משהו, מלבד סילבוס מעודכן. תלמידי מכינה ואוניברסיטאות צריכים להגיע עם סילבוס, ומבחנים לדוגמא. באם יש, גם ספרי קורס וש.ב. תלמידי תיכון צריכים להגיע עם ספרי הלימוד, ובנוסף לכך מבחנים אחרונים שנבחנו בהם.' },
    { title: 'האם אני צריך לארגן שאלות לשיעור?', answer: 'באם יש לכם שאלות מוכנות זה מצויין. באם לא, אין צורך.' },
    { title: 'כמה זמן אורך שיעור?', answer: 'המטרה היא שתלמיד יצא משיעור עם הבנה מספיק עמוקה על החומר כך שיוכל לחזור הבייתה עם ביטחון והמשך עבודה עצמאית באותו הנושא. זמן אופייני בשיעור של אחד על אחד הינו שעתיים אקדמאיות (שעה וארבעים דקות). בתום השיעור, אני מוסיף כמה דקות שבהם אנחנו מסכמים את החומר, בונים עבודה עצמאית לבית, ולו"ז עתידי באם מגיעים קבוצה, יכול להיות שיהיה צורך להוסיף זמן בכדי שכולם יוכלו לקבל מענה על שאלות והבנה מספקת. הזמנים כמובן יכולים להשתנות לפי ראות התלמיד.ה ומטרותיהם' },
    { title: 'כמה שיעורים צריך בכדי להיות מוכנים למבחן?', answer: 'לאחר הפגישה הראשונה, יהיה לנו מדד ראשוני לגבי רמתכם, וכך נוכל באם תרצו לבנות לו"ז מסודר של פגישות עד לבחינה עם סדר גודל של שיעורים שנצטרך כמות השיעורים כמובן משתנה מסטודנט.ית לסטודנט.ית, תלוי ברמה ובמטרה' },
    { title: 'האם ניתן לפתוח קבוצה ולהגיע עבור קורס מקיף לכלל החומר?', answer: 'בהחלט, במקרה זה חשוב ליצור קשר מוקדם ככל האפשר בכדי שנוכל לארגן זאת בצורה מסודרת כדי שתגיעו כמה שיותר מוכנים למבחן.' },]

    return (
        <MainContainer>
            <Box>
                <HomeHeader title={headerTitle} body={body} video={video} />
                {/* <LessonDetails title={title} details={details} /> */}
                <Grid container spacing={{ xs: 2, md: 5 }} justifyContent="center" sx={{ margin: '0px auto', padding: '0px 30px' }}>
                    {details.map((item, i) => (
                        <Grid item key={i} xs={10} sm={6} md={4}>
                            <CourseCard id={'private_lesson'}
                                courseTitle={item.title}
                                bullets={item.bullets}
                                popUpMainTitle='צור קשר'
                                popUpSubTitle={'בחרת בקורס מסוג'}
                                isShowForm={true}
                                color={theme.palette.coral} />
                        </Grid>
                    ))}
                </Grid>
                <FAQs questions={questions} />
                <Contact />
            </Box>
        </MainContainer>
    );
};

export default Lessons;