import React from "react";
import { styled, useTheme, Typography, Grid } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails'
import AddIcon from '@mui/icons-material/Add';

export const FAQs = ({ questions }) => {
    const theme = useTheme();

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container justifyContent="center">
            <Grid item sx={{ margin: '50px 0px 20px 0px' }} xs={10} md={7}>
                <Typography sx={{
                    fontSize: theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                    fontFamily: theme.fontFamily,
                    color: theme.palette.black
                }}>
                    שאלות נפוצות
                </Typography>
            </Grid>

            <Grid item xs={10} md={7}>
                {questions.map((question, i) => {
                    return (
                        <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography sx={{
                                    fontSize: '18px',
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: theme.fontFamily,
                                }}>
                                    {question.title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {question.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Grid>
        </Grid>
    );
};

const IframeStyle = styled("iframe")(({ theme }) => ({
    height: "210px",
    margin: "1rem auto",
    border: "none",
    [theme.breakpoints.down("md")]: {
        height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
        height: "210px",
    },
}));