import React from 'react';
import ReactGA from 'react-ga';
import Iframe from 'react-iframe';

require('../css/styles.css');



const Payment4 = (props) => {
    // console.log(props);
    ReactGA.event({
        category: 'Payment4',
        action: "z-credit",
    });


    return(
        <div>   
            <br></br>
            <center>
                <Iframe src={props.url} ></Iframe>
            </center>
            <br></br>
        </div>
    );

}


export default Payment4;
