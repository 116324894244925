import { BASE_URL } from "../api";
import axios from "axios";
import store from '../redux/store';

export default function getCommentsAndReplies(lessonId) {
    axios
        .post(`${BASE_URL}/comments/commentsAndReplies`, { lessonId: lessonId })
        .then((res) => {
            store.dispatch({ type: 'setComments', payload: res.data });
        });
}