import React from 'react';
import Title from './Title';

require('../css/styles.css');

const Success = () => {
    return (
        <div>
            <Title
                title = "הרשמה מוצלחת!"

            ></Title>

            <div className="services">
            <br></br>
            <br></br>
                <center>
                    <h2>כעת ניתן להוריד דפי נוסחאות</h2>
                    <h2>ולצפות בסרטונים נוספים</h2>
                    <br></br>
                    <br></br>
                    <h2>לכל שאלה הקיימת לכם</h2>
                    <h2>תרגישו חופשי ליצור עימי קשר</h2>
                </center>
                
            </div>
        </div>                     
    );
}

export default Success;