import React from "react";
import { useLocation } from "react-router-dom";

export const Routing = {
    useQuery: function () {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
}

export default Routing;