import React from "react";

import { Container, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

export const Specialties = ({ svg, bgColor, text, title }) => {
  const theme = useTheme();
  return (
    <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 0 1rem 0",
          backgroundColor: bgColor,
          borderRadius: "100%",
          padding: "1.5rem",
          maxHeight: "fit-content",
          maxWidth: "fit-content",
        }}>
        <img src={svg} alt="" height={"80px"} width={"80px"} />
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          color: bgColor,
          fontSize: theme.fontSize.h4,
          fontWeight: theme.typography.fontWeightBold,
          fontFamily: theme.fontFamily,
        }}>
        {title}
      </Typography>

      <Box sx={{ width: "90%", marginBottom: "2rem" }} md={{width: "100%"}}>
        <Typography sx={{ fontFamily: theme.fontFamily, textAlign: "center", fontSize: theme.fontSize.p}}>{text}</Typography>
      </Box>
    </Container>
  );
};
