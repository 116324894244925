import React from "react";
import { Box, Divider, styled } from "@mui/material";
export const Title = ({ children, color }) => {
  return (
    <Box sx={{ margin: "0 0 1rem 0" }}>
      <TitleBox>
        <div>
          {children}
          <Divider variant="middle" sx={{ backgroundColor: color }} />
        </div>
      </TitleBox>
    </Box>
  );
};

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  fontSize: "2rem",
  fontWeight: 200,
});
