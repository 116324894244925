import * as React from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

import { MainContainer } from "../Containers";
import { SubHeader } from '../../utils/subHeader';
import { MaterialList } from './MaterialList';
import { AlertMessage } from "../../utils/AlertMessage";
import { BASE_URL } from "../../api";
import axios from "axios";

const Material = () => {
    // -- Snackbar start --
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const handleClose = () => {
        setAlertIsOpen(false);
    };

    const [alertMessage, setAlertMessage] = useState("");

    const [alertStatus, setAlertStatus] = useState("");
    // -- Snackbar end  --

    const theme = useTheme();
    const [files, setFiles] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/files/getFiles`).then((res) => {
            if (res.data.error) {
                setAlertMessage(res.data.message);
                setAlertStatus('error');
                setAlertIsOpen(true);
                return;
            }

            setFiles(res.data);
        });
    }, []);
    
    return (
        <MainContainer>
            <SubHeader title='חומרי עזר ומאמרים' />
            {files.some(file => file.type === 3) && <MaterialList backgroundColor='#F5F5F5' title='מאמרים' files={files.filter(file => file.type === 3)} />}
            {files.some(file => file.type === 2) && <MaterialList backgroundColor='' title='קבצים כלליים' files={files.filter(file => file.type === 2)} />}
            {files.some(file => file.type === 1) && <MaterialList backgroundColor='#F5F5F5' title='פתרון בגרויות' files={files.filter(file => file.type === 1)} />}
            <AlertMessage
                message={alertMessage}
                open={alertIsOpen}
                onClose={handleClose}
                status={alertStatus}
            /></MainContainer>
    );
};

export default Material;