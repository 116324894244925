import React from "react";

import { useTheme } from "@emotion/react";
import { Alert, Snackbar, styled } from "@mui/material";

export const AlertMessage = ({ onClose, open, message, status }) => {
  const theme = useTheme();
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
      open={open}
      key={"s"}
      autoHideDuration={3000}>
      <AlertStyle severity={status} sx={{ width: "100%", fontFamily: theme.fontFamily }}>
        {message}
      </AlertStyle>
    </Snackbar>
  );
};

const AlertStyle = styled(Alert)({
  "& .MuiAlert-icon": {
    margin: "0 0 0 0.5rem",
  },
});
