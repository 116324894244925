import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { AlertMessage } from "../../utils/AlertMessage";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  useTheme,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";
import { axiosInstance } from "../../api";
import { Lesson } from "./Lesson";

import Routing from '../../hooks/Routing';

export const Chapters = ({ chapters, handleVideo }) => {
  // -- Snackbar start --
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const handleClose = () => {
    setAlertIsOpen(false);
  };

  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  // -- Snackbar end  --

  const [expanded, setExpanded] = useState(false);
  const [panelVal, setPanelVal] = useState(null);
  const [lessons, setLessons] = useState([]);

  const theme = useTheme();
  const params = useParams();

  let query = Routing.useQuery();
  const courseID = parseInt(query.get("courseid"));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded === true && panelVal !== panel) {
      setPanelVal(panel);
      setLessons(null);
      axiosInstance
        .post(`/lessons/getLessons`, { chapterID: panel, courseID: courseID })
        .then((res) => {
          setLessons(res.data);
        });
    }
  };

  return (
    <Box>
      <AlertMessage
        message={alertMessage}
        open={alertIsOpen}
        onClose={handleClose}
        status={alertStatus}
      />
      {chapters?.map((chapter, i) => {
        return (
          <AccordionStyle
            key={i}
            expanded={expanded === chapter.chapter[0].chapterId}
            onChange={handleChange(chapter.chapter[0].chapterId)}>
            <AccordionSummaryStyle>
              <Typography sx={{ fontFamily: theme.fontFamily, fontWeight: "bold" }}>
                פרק {i + 1}: {chapter.chapter[0].chapterTitle}
              </Typography>
            </AccordionSummaryStyle>
            {lessons?.map((lesson, i) => (
              <AccordionDetailsStyle key={i}>
                <Lesson lesson={lesson} handleVideo={handleVideo} setAlertMessage={setAlertMessage} setAlertIsOpen={setAlertIsOpen} setAlertStatus={setAlertStatus} />
              </AccordionDetailsStyle>
            ))}
          </AccordionStyle>
        );
      })}
    </Box>
  );
};

const AccordionStyle = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    maxWidth: "19rem",
    minWidth: "19rem",
  },

  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyle = styled((props) => {
  return <AccordionSummary expandIcon={<ExpandMore sx={{ fontSize: "0.9rem" }} />} {...props} />;
})(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",

  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: "0.5rem 1rem",
  cursor: "pointer",
  position: "relative",
  zIndex: "0",
}));
