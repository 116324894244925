import React from "react";
import Bullets from "./Bullets";
import { Link } from "react-router-dom";

import Iframe from "react-iframe";

import { Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

require("../css/styles.css");

const List = (props) => {
  if (props.type === "writing") {
    return (
      <div>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <h4>{props.title}</h4>
            <h4>{props.subTitle}</h4>
          </Col>
          <Col xs={12} md={8} lg={9}>
            <p>{props.explenation}</p>
            {props.bullet.map((el) => (
              <Bullets {...el} key={el.id} />
            ))}
          </Col>
        </Row>
        <hr className="p-2"></hr>
      </div>
    );
  } else if (props.type === "landing") {
    return (
      <div>
        <div className="container ">
          <br></br>
          <div className="flex justify-center">
            <div className="w-4/5"></div>
            <hr className="dot w-1/5"></hr>
            <div className="w-4/5"></div>
          </div>
          <br></br>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <center>
                <Link to={props.to}>
                  <h2 className="link">{props.title}</h2>
                </Link>
                <br></br>
              </center>
              <p>{props.explenation}</p>
              {/* {props.bullet.map(Bullets)}       */}
              {props.bullet.map((el) => (
                <Bullets {...el} key={el.id} />
              ))}
            </Col>
            <Col xs={12} md={6} lg={6}>
              {/* <center>
                            <Link to = {props.to}><img className = {props.style1} src={props.img} alt = {props.alt}></img></Link> */}
              <center>
                <Iframe
                  style="width:560px; height:315px; max-width:80%;"
                  src={props.url}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allow="fullscreen"
                  allowFullScreen></Iframe>
              </center>
              {/* </center> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  } else if (props.type === "rec") {
    return (
      <div>
        <div className=" ">
          <br></br>
          <Row>
            <Col xs={12} md={6} lg={6}>
              <center>
                <a href={props.to}>
                  <img className={props.style1} src={props.img} alt={props.alt}></img>
                </a>
                <br></br>
                <hr className="dot p-2"></hr>
              </center>
            </Col>

            <Col xs={12} md={6} lg={6}>
              <center>
                <a href={props.to}>
                  <img className={props.style1} src={props.img2} alt={props.alt}></img>
                </a>
                <hr className="dot p-2"></hr>
              </center>
            </Col>
          </Row>
        </div>
      </div>
    );
  } else if (props.type === "cards") {
    return (
      <div>
        <Row>
          <Col xs={12} md={3} lg={3}>
            <h4>{props.title}</h4>
            <br></br>
          </Col>

          <Col xs={12} md={9} lg={8}>
            {/* {props.bullet.map(Bullets)}    */}
            {props.bullet.map((el) => (
              <Bullets {...el} key={el.id} />
            ))}
          </Col>
        </Row>
        <hr></hr>
      </div>
    );
  }
};

export default List;
